export default function makeCompleteXeroOauth2Flow({ store }) {
  return async function completeXeroOauth2Flow(nextState, replace, callback) {
    const { state, code } = nextState.location.query
    const securityTokenValidation =
      await store.bookkeepingPlatformConnectionStore.checkXeroSecurityToken(
        state
      )
    if (securityTokenValidation.isSuccess) {
      const jobId = securityTokenValidation.state.get('id')

      store.createBookkeepingPlatformConnectionStore.setConnectionParams(
        jobId,
        { code, platform: 'Xero' }
      )

      replace({
        pathname: `project/${jobId}`,
      })
    }
    callback()
  }
}
