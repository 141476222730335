import cx from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import { CallPrompt } from 'messaging/calling/views/CallPrompt/index.'
import styles from './CallPromptsHost.m.sass'
import { AudioRenderer } from '../MediaRenderer/index'

/**
 * The CallPromptsHost
 */
export default observer(function CallPromptsHost({ callRootVM }) {
  return (
    <div className={cx(styles.root)}>
      {callRootVM.incomingCalls.map((callVM) => (
        <div className={styles.item} key={callVM.call.id}>
          <CallPrompt call={callVM.call}></CallPrompt>
        </div>
      ))}

      {/*  Hosts the active call's audio tracks */}
      {callRootVM.activeCall?.participants.map((p) => (
        <AudioRenderer key={p.participant.userId} callParticipantVM={p} />
      ))}
    </div>
  )
})
