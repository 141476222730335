import { action, observable, computed } from 'mobx'

/**
 * State for the Job Name Editor.
 */
export default class JobNameEditor {
  @observable
  isOpen = false

  constructor(wizard) {
    this.wizard = wizard
  }

  @action.bound
  toggleEditor() {
    this.isOpen = !this.isOpen
    this.isOpen && this.wizard.setJobName('') // reset the title when modal is open
    !this.isOpen && this.wizard.setJobName(this.wizard.job.name) // set the title to the job's currently saved title on modal close
  }

  @computed
  get isValid() {
    const trimmedName = this.wizard.name.trim()
    const wordcount = trimmedName.split(' ').length
    return wordcount <= 10
  }
}
