import { action, computed, observable, reaction } from 'mobx'

/**
 * Reactively maintains a list of view models that are based on observable models.
 */
export class ViewModelList {
  /**
   * Maintains a map from a model and a view model.
   *
   * @private
   */
  @observable _modelToViewModelMap = new Map()

  /**
   * Maintains a map from a view model to a model.
   *
   * @private
   */
  @observable _viewModelToModelMap = new Map()

  /**
   * The underlying items. We use `.ref` because we will be replacing the array when we sync.
   *
   * @private
   */
  @observable.ref _items = []

  /**
   * Constructor.
   *
   * @param opts
   */
  constructor(opts) {
    this.opts = opts
  }

  /**
   * The items.
   */
  @computed
  get items() {
    return this._items
  }

  /**
   * Whether the list contains the given model instance.
   *
   * @param model
   */
  contains(model) {
    return this._modelToViewModelMap.has(model)
  }

  /**
   * Gets the associated view model for the given model.
   *
   * @param model
   */
  getByModel(model) {
    return this._modelToViewModelMap.get(model)
  }

  /**
   * Activates the list and syncs the models.
   */
  @action.bound
  activate() {
    this._disposeSync?.()
    this._disposeSync = reaction(this.opts.models, this._syncItems, {
      fireImmediately: true,
    })
  }

  /**
   * Deactivates the list and stops syncing the models. Also
   * deactivates the view models.
   */
  @action.bound
  deactivate() {
    this._disposeSync?.()
    if (this.opts.deactivate) {
      for (const item of this._items) {
        this.opts.deactivate?.(item)
      }
    }

    this._items = []
    this._modelToViewModelMap.clear()
    this._viewModelToModelMap.clear()
  }

  /**
   * Syncs the items.
   *
   * @param models
   * @private
   */
  @action.bound
  _syncItems(models) {
    // We know the size of the new collection
    const len = models.length
    const modelsSet = new Set(models)
    const newItems = new Array(len)

    // Add new and existing items
    for (let i = 0; i < len; i++) {
      const model = models[i]
      const existing = this._modelToViewModelMap.get(model)
      if (existing) {
        newItems[i] = existing
        continue
      }

      newItems[i] = this._createAndActivate(model)
    }

    // Deactivate items that are no longer present
    for (let i = 0; i < this._items.length; i++) {
      const oldViewModel = this._items[i]
      // We know the model will be present
      const oldModel = this._viewModelToModelMap.get(oldViewModel)
      if (modelsSet.has(oldModel)) {
        continue
      }

      // The view model can be disposed of
      this.opts.deactivate?.(oldViewModel)
      this._modelToViewModelMap.delete(oldModel)
      this._viewModelToModelMap.delete(oldViewModel)
    }

    this._items = newItems
  }

  /**
   * Creates and activates a view model.
   *
   * @param model
   * @private
   */
  _createAndActivate(model) {
    const viewModel = this.opts.create(model)
    this._modelToViewModelMap.set(model, viewModel)
    this._viewModelToModelMap.set(viewModel, model)
    this.opts.activate?.(viewModel)
    return viewModel
  }
}
