import { Store } from 'libx'
import { observable, action } from 'mobx'
import { task } from 'mobx-task'
import { extractMessageFromError } from 'utils/errorUtil'

export default class CreateBookkeepingPlatformConnectionStore extends Store {
  @observable
  connectionParams = {}

  @action
  setConnectionParams(jobId, params) {
    this.connectionParams[jobId] = params
  }

  @task
  async activate(job) {
    const params = this.connectionParams[job.id]

    if (params) {
      let connection = null

      switch (params.platform) {
        case 'QuickBooks':
          try {
            connection =
              await this.rootStore.bookkeepingPlatformConnectionStore.createQuickBooksPlatformConnectionFromOAuth2Code(
                params.code,
                params.realmId,
                this.rootStore.bookkeepingPlatformConnectionStore
                  .quickBooksRedirectUri,
                job.workspaceId
              )

            await this.rootStore.bookkeepingPlatformConnectionStore.providePlatformConnection(
              job.id,
              { connectionId: connection.connectionId, platform: 'QuickBooks' }
            )

            this.rootStore.bookkeepingConfirmAccessStore.show(params.platform)
          } catch (e) {
            console.error(extractMessageFromError(e))
          }
          break
        case 'Xero':
          try {
            connection =
              await this.rootStore.bookkeepingPlatformConnectionStore.createXeroPlatformConnectionFromOAuth2Code(
                params.code,
                this.rootStore.bookkeepingPlatformConnectionStore
                  .xeroRedirectUri,
                job.workspaceId
              )

            this.rootStore.bookkeepingConfirmAccessStore.show(params.platform)
          } catch (e) {
            console.error(extractMessageFromError(e))
          }
          break
        default:
          this.rootStore.flashMessageStore
            .create({
              type: 'error',
              message: `Unknown platform ${params.platform}`,
            })
            .autoDismiss()
      }

      if (connection) {
        try {
          await this.rootStore.bookkeepingPlatformConnectionStore.providePlatformConnection(
            job.id,
            { connectionId: connection.connectionId, platform: params.platform }
          )
        } catch (e) {
          console.error(extractMessageFromError(e))
        }
      }

      delete this.connectionParams[job.id]
    }
  }
}
