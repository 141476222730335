import { action, computed } from 'mobx'

/**
 * @typedef {Object} WherebyCallConnectionDescriptorDto
 * @property {'Whereby'} type
 * @property {string} room_url
 *
 * @typedef {import('../CallViewModel').CallViewModel} CallViewModelType
 */

/**
 * Whereby call source.
 */
export class WherebyCallSource {
  /**
   * @param {CallViewModelType} callVM
   * @param {WherebyCallConnectionDescriptorDto} connectionDescriptor
   */
  constructor(callVM, connectionDescriptor) {
    this.callVM = callVM
    this.connectionDescriptor = connectionDescriptor
  }

  /**
   * The current user participant.
   */
  @computed
  get currentUserParticipant() {
    return (
      this.roomParticipants.find(
        (rp) => rp.callParticipant === this.callVM.currentUserParticipant
      ) ?? null
    )
  }

  /**
   * Returns the media for the specified participant.
   *
   * @param {CallParticipantViewModel} callParticipantVM
   * @returns {CallSourceMedia|null}
   */
  media(_) {
    return null
  }

  _micDeviceOptsFrom(_) {
    return {}
  }

  /**
   * Connect to Whereby room
   * @returns {Promise<void>}
   */
  async connect() {
    return window
      .open(
        `${this.connectionDescriptor.room_url}?externalId=${this.callVM.currentUserParticipant?.participant.member.userPublicId}`
      )
      ?.focus()
  }

  async disconnect() {
    // Nothing to see here
  }

  @action.bound
  receiveParticipant(_) {
    // Nothing to see here
  }

  @action.bound
  handleParticipantDisconnected(_) {
    // Nothing to see here
  }

  async turnOffMic() {}

  async turnOnMic(_ = null) {
    // Nothing to see here
  }

  async turnOnCamera(_ = null) {
    // Nothing to see here
  }

  async turnOffCamera() {
    // Nothing to see here
  }

  dispose() {
    // Nothing to see here
  }
}
