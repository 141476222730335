import SignupPage from 'components/SignupPage'
import PropTypes from 'prop-types'
import React from 'react'

export default class SignupScreen extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  render() {
    const signupEnabled =
      this.props.rootStore.signupScreenStore.isNewSignupEnabled

    // Let's block other workspaces from accessing this page.
    // We can modify this later if we want to brand this page.
    const workspace =
      window.__workspaceDomain && window.__workspaceDomain.workspace
    if (!signupEnabled || workspace?.id !== '1') {
      this.context.router.replace('/login')
    }

    return <SignupPage rootStore={this.props.rootStore} workspace={workspace} />
  }
}
