import { observable, action } from 'mobx'
import { defer } from '@taxfyle/web-commons/lib/utils/promiseUtil'

export default class SelectBillingMethodState {
  @observable
  showing = false

  @observable
  selectedMethod

  constructor(billingStore) {
    this.billingStore = billingStore
  }

  selectBillingMethod = action(async (method, team) => {
    await this.billingStore.changeDefaultBillingMethod(method, team)
    this.selectedMethod = method
  })

  close = action(() => {
    this._resolve(false)
    this.showing = false
  })

  finish = () => {
    this._resolve(this.selectedMethod)
  }

  _resolve = action((value) => {
    if (!this.billingMethodSelection) return

    this.showing = false
    this.billingMethodSelection.deferred.resolve(value)
  })

  select = action((method) => {
    this.billingMethodSelection = {
      deferred: defer(),
    }
    this.showing = true
    this.selectedMethod = method
    return this.billingMethodSelection.deferred.promise
  })
}
