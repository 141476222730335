export default function makeAuthenticate({ authStore }) {
  return async function authenticate(nextState, replace, callback) {
    const { show, email, connection } = nextState.location.query
    const isAuthenticated = await authStore.checkAuthentication()
    if (!isAuthenticated) {
      if (show === 'login' || show === 'signup') {
        return authStore.login(show, { login_hint: email, connection })
      } else {
        replace({
          pathname: '/login',
          state: { nextPathname: nextState.location.pathname },
        })
      }
    }

    callback()
  }
}
