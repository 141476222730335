import { Store } from 'libx'
import { BookkeepingProgressionInfo } from '../domain/Bookkeeping'
import { RealtimeEvent } from '@taxfyle/api-internal/internal/realtime_pb'
import { getFeatureToggleClient } from 'misc/featureToggles'

export default class BookkeepingStore extends Store {
  bookkeepingProgressions = this.collection({
    model: BookkeepingProgressionInfo,
  })

  constructor({ rootStore }) {
    super({ rootStore })
    this.api = rootStore.api
    this.api.bookkeeping.events$.subscribe(this._onRemoteEvent.bind(this))
  }

  async fetchBookkeepingProgressionInfo(jobId) {
    return this.rootStore.api.bookkeeping
      .getBookkeepingProgressionInfo(jobId)
      .then((i) =>
        this.bookkeepingProgressions.set(i.bookkeepingProgressionInfo)
      )
  }

  /**
   * Called when the client receives an event from the server.
   *
   * @param event
   * @private
   */
  async _onRemoteEvent(event) {
    switch (event.getTypeCase()) {
      case RealtimeEvent.TypeCase.BOOKKEEPING_UPDATED: {
        return this._onRemoteItemUpdated(
          event.getBookkeepingUpdated().toObject()
        )
      }
    }
  }

  /**
   * Handles a bookkeeping progression update from the server.
   * @private
   */
  async _onRemoteItemUpdated(event) {
    const bookkeepingEnabled = getFeatureToggleClient().variation(
      'Portals.BookkeepingEnabled',
      false
    )

    if (!bookkeepingEnabled) {
      return
    }

    this.bookkeepingProgressions.set(event.progressionInfo)
  }
}
