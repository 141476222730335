import { computed } from 'mobx'

/**
 * Call participant view model.
 */
export default class CallParticipantViewModel {
  /**
   * @typedef {import('./CallViewModel').CallViewModel} CallViewModel
   * @param {CallViewModel} callVM
   * @param {*} participant
   */
  constructor(callVM, participant) {
    this.callVM = callVM
    this.participant = participant
  }

  /**
   * A value between 0 and 256 representing
   * the media's loudness if any
   */
  @computed
  get speechLoudness() {
    if (!this.media || this.media.muted) {
      return 0
    }

    return this.media.getAudioLoudness()
  }

  /**
   * Whether this participant is broadcasting media
   * but is muted
   */
  @computed
  get isMuted() {
    return this.media?.muted ?? false
  }

  /**
   * Whether this participant is currently speaking.
   * We determine if someone is speaking by
   * checking whether their speech loudness is over half
   * the possible range and we adjust + some sensitiivity
   */
  @computed
  get speaking() {
    const sensitiivity = 16
    return this.speechLoudness > 128 + sensitiivity
  }

  /**
   * The media for this participant.
   *
   * @returns {import('./twilio/TwilioCallSource').CallSourceMedia | null}
   */
  @computed
  get media() {
    return this.callVM.callSource?.media(this) ?? null
  }
}
