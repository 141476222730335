import env from 'misc/env'

const region = env.DEPLOYMENT_REGION

const links = {
  taxfyle: {
    us: {
      appStore:
        'https://itunes.apple.com/us/app/taxfyle-personal-and-business-tax-return-filing/id1058033104',
      playStore:
        'https://play.google.com/store/apps/details?gl=us&id=com.taxfyle.TaxfyleMobile',
    },
  },
  worklayer: {
    au: {
      appStore: 'https://itunes.apple.com/au/app/worklayer/id1412721896',
      playStore:
        'https://play.google.com/store/apps/details?gl=au&id=com.worklayer.WorklayerMobile',
    },
    eu: {
      appStore: 'https://itunes.apple.com/eu/app/worklayer/id1412721896',
      playStore:
        'https://play.google.com/store/apps/details?gl=eu&id=com.worklayer.WorklayerMobile',
    },
    us: {
      appStore: 'https://itunes.apple.com/us/app/worklayer/id1412721896',
      playStore:
        'https://play.google.com/store/apps/details?gl=us&id=com.worklayer.WorklayerMobile',
    },
  },
}

export const dynamicLinks = {
  taxfyle: 'https://taxfyle.page.link/app',
  worklayer: 'https://worklayer.page.link/app',
}

export default function appLinks() {
  if (region === 'australia-southeast1') {
    return {
      taxfyle: {
        appStore: links.taxfyle.us.appStore,
        playStore: links.taxfyle.us.playStore,
      },
      worklayer: {
        appStore: links.worklayer.au.appStore,
        playStore: links.worklayer.au.playStore,
      },
    }
  } else if (region === 'europe-west3') {
    return {
      taxfyle: {
        appStore: links.taxfyle.us.appStore,
        playStore: links.taxfyle.us.playStore,
      },
      worklayer: {
        appStore: links.worklayer.eu.appStore,
        playStore: links.worklayer.eu.playStore,
      },
    }
  } else {
    return {
      taxfyle: {
        appStore: links.taxfyle.us.appStore,
        playStore: links.taxfyle.us.playStore,
      },
      worklayer: {
        appStore: links.worklayer.us.appStore,
        playStore: links.worklayer.us.playStore,
      },
    }
  }
}
