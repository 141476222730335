import { BadRequest } from 'fejl'

import {
  MilestoneTask,
  Resolution,
  Status,
  WaitingOn,
  MemberType,
  MemberRoleType,
} from '@taxfyle/api-internal/internal/job_pb'
import { timestampToISO, decimalToNumber } from 'utils/grpcUtil'

/**
 * Maps a given value to a decimal number when it is not null
 */
function maybeDecimalToNumber(value) {
  return value ? decimalToNumber(value) : 0
}

/**
 * Maps a SearchableJob Proto to a Job DTO.
 *
 * @param {SearchableJob} payload - The gRPC SearchableJob.
 * @returns {JobDTO} The job DTO.
 */
export function mapGrpcSearchableJobToJobDTO(payload, legendSetting) {
  return {
    id: payload.id,
    short_id: payload.shortId,
    name: payload.title || payload.description || payload.legendName,
    workspace_id: payload.workspaceId,
    owner_team_id: payload.clientTeamId,
    legend_name: payload.legendName,
    legend_id: payload.legendId,
    legend_version: payload.legendVersion,
    legend: legendSetting,
    members: payload.membersList.map((m) => ({
      role: mapGrpcJobMemberRole(m.role),
      type: mapGrpcJobMemberType(m.type),
      user: {
        display_name: m.displayName,
        picture: m.picture,
        user_public_id: m.id,
      },
    })),
    layerConversation: payload.conversationId?.value,
    status: mapGrpcJobStatus(payload.status),
    resolution: mapGrpcJobResolution(payload.resolution),
    total: payload.total ? decimalToNumber(payload.total) : undefined,
    milestones: payload.milestonesList.map((m) => ({
      id: m.id,
      title: m.title,
      completed: m.completed,
      tasks: m.tasksList.map((t) => ({
        id: t.id,
        designee: mapGrpcJobTaskDesignee(t.designee),
        completed: t.completed,
        date_completed: timestampToISO(t.dateCompleted),
        completed_by_public_id: t.completedByPublicId?.value,
      })),
    })),
    job_specs: payload.jobSpecsList,
    provider_cut_amount: maybeDecimalToNumber(payload.providerCutAmount),
    date_created: timestampToISO(payload.dateCreated),
    date_modified: timestampToISO(payload.dateModified),
    date_closed: timestampToISO(payload.dateCompleted),
    date_accepted: timestampToISO(payload.dateClaimed),
    date_deadline: timestampToISO(payload.dateDeadline),
    date_transmitted: timestampToISO(payload.dateSubmitted),
    waiting_on: mapGrpcJobWaitingOn(payload.waitingOn),
  }
}

function mapGrpcJobMemberType(type) {
  switch (type) {
    case MemberType.MEMBER_TYPE_CLIENT:
      return 'CLIENT'
    case MemberType.MEMBER_TYPE_PROVIDER:
      return 'PROVIDER'
    default:
      throw new BadRequest('Job member type unknown')
  }
}

function mapGrpcJobMemberRole(role) {
  switch (role) {
    case MemberRoleType.CHAMPION:
      return 'CHAMPION'
    case MemberRoleType.SUPPORTING:
      return 'SUPPORTING'
    default:
      throw new BadRequest('Job member role unknown')
  }
}

export function mapGrpcJobStatus(status) {
  switch (status) {
    case Status.CLAIMED:
      return 'CLAIMED'
    case Status.CLOSED:
      return 'CLOSED'
    case Status.IDLE:
      return 'IDLE'
    case Status.ON_HOLD:
      return 'ON_HOLD'
    case Status.UNCLAIMED:
      return 'UNCLAIMED'
    case Status.INFO_GATHERING:
      return 'INFO_GATHERING'
    case Status.UNDER_CONSTRUCTION:
      return 'UNDER_CONSTRUCTION'
    default:
      throw new BadRequest('Job is of an undefined status')
  }
}

function mapGrpcJobResolution(resolution) {
  switch (resolution) {
    case Resolution.RESOLUTION_UNSPECIFIED:
      return null
    case Resolution.DONE:
      return 'DONE'
    case Resolution.CANCELLED:
      return 'CANCELLED'
    case Resolution.TEST:
      return 'TEST'
    default:
      throw new BadRequest('Job resolution unknown')
  }
}

function mapGrpcJobWaitingOn(waiting) {
  switch (waiting) {
    case WaitingOn.WAITING_ON_CLIENT:
      return 'Client'
    case WaitingOn.WAITING_ON_PRO:
      return 'Provider'
    case WaitingOn.WAITING_ON_UNSPECIFIED:
      return null
    default:
      throw new BadRequest('Job waiting unknown')
  }
}

function mapGrpcJobTaskDesignee(designee) {
  switch (designee) {
    case MilestoneTask.TaskDesignee.CLIENT:
      return 'Client'
    case MilestoneTask.TaskDesignee.PROVIDER:
      return 'Provider'
    case MilestoneTask.TaskDesignee.TASK_DESIGNEE_UNSPECIFIED:
      return null
    default:
      throw new BadRequest('Job task designee unknown')
  }
}
