import { Store } from 'libx'
import { observable, computed, action } from 'mobx'
import { task } from 'mobx-task'

export default class CouponsScreenStore extends Store {
  @observable
  couponCode = ''

  @observable
  processing = false

  @observable
  errorMessage = null

  @computed
  get coupons() {
    return this.rootStore.userCouponStore
      .forUserByPublicId(this.rootStore.sessionStore.user.public_id)
      .filter((x) => x.redeemed === false)
  }

  @action
  setCouponCode(code) {
    this.couponCode = code
  }

  @action
  toggleProcessing(processing) {
    this.processing = processing
  }

  @action
  setError(message) {
    this.errorMessage = message
  }

  @task
  async activate() {
    const userPublicId = this.rootStore.sessionStore.user.public_id
    await this.rootStore.userCouponStore.fetchUserCouponsByPublicId(
      userPublicId
    )
  }

  async applyCoupon() {
    if (!this.couponCode) {
      return
    }
    this.toggleProcessing(true)
    this.setError(null)
    try {
      this.setCouponCode(this.couponCode.toUpperCase())
      const result = await this.rootStore.userCouponStore.addCouponToUser(
        this.couponCode
      )
      if (!result) {
        this.setError('That coupon does not exist.')
      }
    } catch (err) {
      this.setError(err.message)
    } finally {
      this.toggleProcessing(false)
    }
  }
}
