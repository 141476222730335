import { Store } from 'libx'
import { observable, action, computed } from 'mobx'
import { validationContext, required, pattern, func } from 'validx'
import { phone } from 'phone'
import { getFeatureToggleClient } from 'misc/featureToggles'
import env from 'misc/env'
import { task } from 'mobx-task'

export default class SignupScreenStore extends Store {
  @observable
  firstName = ''

  @observable
  lastName = ''

  @observable
  email = ''

  @observable
  phone = ''

  @observable
  password = ''

  constructor({ rootStore }) {
    super({ rootStore })
  }

  @computed
  get isNewSignupEnabled() {
    return getFeatureToggleClient().variation(
      'CustomerPortal.NewSignupFlow',
      false
    )
  }

  @computed
  get canSubmit() {
    return (
      this.phone &&
      this.firstName &&
      this.lastName &&
      this.email &&
      this.password
    )
  }

  validation = validationContext(this, {
    firstName: [
      required('First name is required'),
      func(({ value }) => {
        return (
          value.length <= 40 || "First name can't be more than 40 characters"
        )
      }),
    ],
    lastName: [
      required('Last name is required'),
      func(({ value }) => {
        return (
          value.length <= 40 || "Last name can't be more than 40 characters"
        )
      }),
    ],
    email: [
      required('Email is required'),
      pattern({ pattern: 'email', msg: 'Not a valid email' }),
      func(({ value }) => {
        return value.length <= 320 || "Email can't be more than 320 characters"
      }),
    ],
    phone: [
      required('Phone is required'),
      func(({ value }) => {
        return (
          phone(value, { strictDetection: true }).isValid ||
          'Not a valid phone number'
        )
      }),
    ],
    password: [required('Password is required')],
  })

  @task.resolved
  async signup() {
    if (!this.validation.reset().validate().isValid && !this.isBusy) {
      return
    }

    try {
      await this.rootStore.iamAPI.auth.signUpUser({
        email: this.email,
        password: this.password,
        given_name: this.firstName,
        family_name: this.lastName,
        phone: this.phone,
      })

      await this.rootStore.authStore.loginWithUsernamePassword({
        username: this.email,
        password: this.password,
        realm: 'Username-Password-Authentication',
        audience: env.AUTH0_AUDIENCE_URL,
      })

      window.location.replace('/')
      this.clear()
    } catch (err) {
      this.handleAuthError(err)
    }
  }

  @action
  handleAuthError(error) {
    console.error('Signup failed', error)
    this.rootStore.flashMessageStore.showForError(error)
  }

  @action
  clear() {
    this.phone = null
    this.email = null
    this.password = null
    this.lastName = null
    this.firstName = null
  }
}
