import { Store } from 'libx'
import { observable, computed, action } from 'mobx'
import { task } from 'mobx-task'
import { validationContext, required, pattern } from 'validx'

export default class ChangeEmailDialogStore extends Store {
  @observable
  showing = false

  @observable
  email = false

  validation = validationContext()

  rules = {
    email: [
      required('New email is required'),
      pattern({
        pattern: 'email',
        msg: 'Not a valid email address',
      }),
    ],
  }

  constructor() {
    super(...arguments)
    this.save = this.save.bind(this)
  }

  @computed
  get user() {
    return this.rootStore.sessionStore.member
  }

  @action.bound
  show() {
    this.email = this.user.email
    this.showing = true
  }

  @action.bound
  close() {
    this.showing = false
  }

  @task.resolved
  save() {
    if (!this.validation.reset().validate(this, this.rules).isValid) {
      return
    }

    return this.rootStore.memberStore
      .update(
        {
          user_id: this.rootStore.sessionStore.user.public_id,
          workspace_id: this.rootStore.sessionStore.member.workspaceId,
        },
        {
          email: this.email,
        }
      )
      .then(this.emailChanged)
  }

  @action.bound
  emailChanged() {
    this.close()
    this.rootStore.flashMessageStore
      .create('E-mail address successfully changed.')
      .done()
      .autoDismiss(4000)
  }
}
