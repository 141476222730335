import { action, computed, observable } from 'mobx'
import { DocumentSupportingItemInput } from '../../../domain/info-gathering/RequestedSupportingItem'
import { DocumentSupportingItemInputViewModel } from './DocumentSupportingItemInputViewModel'

/**
 * View model for a requested supporting item.
 */
export class RequestedSupportingItemViewModel {
  /**
   * The underlying item.
   *
   * @type {import('domain/info-gathering/RequestedSupportingItem').RequestedSupportingItem}
   */
  @observable item

  constructor({
    item,
    isSelected,
    selectItem,
    showingInfoGatheringStage,
    documentsAdapter,
    flashMessageStore,
  }) {
    this.item = item
    this._isSelected = isSelected
    this._selectItem = selectItem
    this.showingInfoGatheringStage = showingInfoGatheringStage
    this.documentsAdapter = documentsAdapter
    this.flashMessageStore = flashMessageStore
    this.input = this._createInput()
  }

  /**
   * Whether the item is currently selected.
   */
  @computed
  get selected() {
    return this._isSelected(this)
  }

  /**
   * Whether the VM is performing an operation right now.
   *
   * @returns {boolean}
   */
  @computed
  get busy() {
    return Boolean(this.input?.busy)
  }

  /**
   * Activates the view model.
   */
  activate() {
    this.input?.activate?.()
  }

  /**
   * Deactivates the view model.
   */
  deactivate() {
    this.input?.deactivate?.()
  }

  /**
   * Selects the current item.
   */
  @action.bound
  select() {
    this._selectItem(this)
  }

  /**
   * Creates the input view model based on the input type.
   *
   * @private
   */
  _createInput() {
    if (this.item.input instanceof DocumentSupportingItemInput) {
      return new DocumentSupportingItemInputViewModel({
        item: this.item,
        showingInfoGatheringStage: this.showingInfoGatheringStage,
        documentsAdapter: this.documentsAdapter,
        flashMessageStore: this.flashMessageStore,
      })
    }

    return null
  }
}
