import Promise from 'bluebird'

export function debouncePromise(fn) {
  let promise, nextArgs
  function debounced() {
    nextArgs = Array.from(arguments)
    if (promise) {
      return promise
    }

    const args = nextArgs
    promise = Promise.try(() => fn.apply(this, args))
      .then((result) => {
        promise = null
        if (args !== nextArgs) {
          return debounced.apply(this, nextArgs)
        }
        return result
      })
      .catch((err) => {
        promise = null
        nextArgs = null
        throw err
      })
    return promise
  }

  return debounced
}
