import { observable, action } from 'mobx'
import { Store } from 'libx'
import { task } from 'mobx-task'
import { ConfirmDialogState } from 'components/ConfirmDialog'

export default class BookkeepingConfirmAccessDialogStore extends Store {
  @observable
  showing = false

  @observable
  accountName = ''

  @observable
  platform = ''

  /*
   * The confirm dialog state.
   */
  confirmDialogState = new ConfirmDialogState()

  /**
   * Shows the dialog.
   */
  @action
  show(platform, accountName) {
    this.platform = platform
    this.accountName = accountName
    this.showing = true
  }

  /**
   * Closes the dialog.
   */
  @action
  close() {
    this.showing = false
  }

  /**
   * Opens the bookkeeping platform connections dialog.
   */
  @action
  back() {
    this.rootStore.bookkeepingPlatformConnectionsStore.show(this.platform)
    this.close()
  }

  /**
   * Marks the access for the bookkeeping tracker as granted.
   */
  @task.resolved
  async provideAccess() {
    // by design we don't want to show the dialog behind the confirmation
    this.close()

    return this.confirmDialogState
      .show()
      .then(async (yes) =>
        yes
          ? await this._markAccessAsGranted()
          : this.show(this.platform, this.accountName)
      )
  }

  /*
   * Opens the platform website.
   */
  openPlatform() {
    switch (this.platform) {
      case 'QuickBooks':
        window.open('https://accounts.intuit.com/app/sign-in', '_blank')
        break

      case 'Xero':
        window.open('https://login.xero.com/identity/user/login', '_blank')
        break

      default:
        this.rootStore.flashMessageStore.create({
          type: 'error',
          message: `${this.platform} is not supported.`,
        })
    }
  }

  async _markAccessAsGranted() {
    const store = this.rootStore.bookkeepingPlatformConnectionStore
    const bookkeepingStore = this.rootStore.bookkeepingStore
    const jobId = this.rootStore.projectDetailsStore.project.id

    await store.markAccessAsGranted(jobId)

    this.rootStore.flashMessageStore
      .create({
        type: 'success',
        message: 'You confirmed access was granted.',
      })
      .autoDismiss()

    await bookkeepingStore.fetchBookkeepingProgressionInfo(jobId)
  }
}
