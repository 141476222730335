import { Model } from 'libx'
import { computed, observable } from 'mobx'

export default class ScopeChangeRequestBreakdownItem extends Model {
  @observable inventoryItemVersionId = null
  @observable name = null
  @observable description = null
  @observable previousQuantity = 0
  @observable newQuantity = 0
  @observable previousPrice = 0
  @observable newPrice = 0

  /**
   * Returns formatted difference between previous and new quantities.
   */
  @computed
  get formattedQuantity() {
    const quantity = this.newQuantity - this.previousQuantity
    return (quantity > 0 ? '+' : '') + quantity.toString()
  }

  /**
   * Whether new price represents a price increase or not.
   */
  @computed
  get isCostIncrease() {
    return this.newPrice - this.previousPrice > 0
  }

  /**
   * Returns formatted difference between previous and new prices.
   */
  @computed
  get formattedCost() {
    const price = this.newPrice - this.previousPrice
    return (price < 0 ? '-' : '') + '$' + Math.abs(price).toFixed(2)
  }

  /**
   * Parses the DTO to model properties.
   */
  parse(attrs) {
    return {
      inventoryItemVersionId: attrs.inventoryItemVersionId,
      name: attrs.name,
      description: attrs.description,
      previousQuantity: attrs.previousQuantity,
      newQuantity: attrs.newQuantity,
      previousPrice: attrs.previousPrice,
      newPrice: attrs.newPrice,
    }
  }
}
