// eslint-disable-next-line no-unused-vars
import { Observable, EMPTY, interval } from 'rxjs'
import { switchMap, map, finalize } from 'rxjs/operators'

/**
 * @typedef {{loudness: number}} AudioAnalysisItem
 *
 * @param {AudioContext} audioContext
 * @param {Observable<MediaStream|null>} mediaStream$
 * @param {number} intervalMs
 * @returns {Observable<AudioAnalysisItem>}
 */

export function AudioAnalysis(audioContext, mediaStream$, intervalMs) {
  return mediaStream$.pipe(
    switchMap((mediaStream) => (mediaStream ? analyze(mediaStream) : EMPTY))
  )

  /**
   * @param {MediaStream} mediaStream
   * @returns {Observable<AudioAnalysisItem>}
   */
  function analyze(mediaStream) {
    const source = audioContext.createMediaStreamSource(mediaStream)
    const analyser = audioContext.createAnalyser()
    analyser.fftSize = 256
    source.connect(analyser)

    return interval(intervalMs).pipe(
      map(() => {
        const bufferLength = analyser.frequencyBinCount
        const dataArray = new Uint8Array(bufferLength)
        analyser.getByteFrequencyData(dataArray)

        // We determine loudness by taking the highest value
        // from the frequency bins
        const highestBucket = Math.max(...dataArray)
        return { loudness: highestBucket }
      }),
      finalize(() => {
        analyser.disconnect()
        source.disconnect()
      })
    )
  }
}
