export default (couponCode) => {
  const code = (couponCode || '').trim()

  if (!code || code.length === 0) {
    throw new Error('Coupon code must contain letters and/or numbers.')
  }

  if (!code.match(/^[a-z0-9\s]+[-|_]{0,1}[a-z0-9\s]+$/i) || code.length > 30) {
    throw new Error('That coupon code is invalid.')
  }
}
