import {
  AccessStatus,
  BookkeepingStage,
} from '@taxfyle/api-internal/internal/bookkeeping_pb'
import { Model } from 'libx'
import { computed, observable } from 'mobx'
import { timestampToISO } from 'utils/grpcUtil'
import { maybeParseDate } from 'utils/dateUtil'

export const BookkeepingStageDto = {
  [BookkeepingStage.PENDING]: 'Pending',
  [BookkeepingStage.PROVISIONING_ACCESS]: 'ProvisioningAccess',
  [BookkeepingStage.ASSESSING_BOOKS]: 'AssessingBooks',
  [BookkeepingStage.READY_TO_START]: 'ReadyToStart',
  [BookkeepingStage.CYCLE_IN_PROGRESS]: 'CycleInProgress',
  [BookkeepingStage.COMPLETED]: 'Completed',
  [BookkeepingStage.BOOKKEEPING_STAGE_UNSPECIFIED]: undefined,
}

export const AccessStatusDto = {
  [AccessStatus.NOT_REQUESTED]: 'NotRequested',
  [AccessStatus.MANAGED_ACCESS_CLIENT_CONNECTION_REQUESTED]:
    'ManagedAccessClientConnectionRequested',
  [AccessStatus.MANAGED_ACCESS_CLIENT_CONFIRMATION_REQUESTED]:
    'ManagedAccessClientConfirmationRequested',
  [AccessStatus.MANAGED_ACCESS_ADMIN_CONFIRMATION_REQUESTED]:
    'ManagedAccessAdminConfirmationRequested',
  [AccessStatus.MANAGED_ACCESS_CONFIRMED]: 'ManagedAccessConfirmed',
  [AccessStatus.UNMANAGED_ACCESS_PROVIDER_CONFIRMATION_REQUESTED]:
    'UnmanagedAccessProviderConfirmationRequested',
  [AccessStatus.UNMANAGED_ACCESS_CONFIRMED]: 'UnmanagedAccessConfirmed',
  [AccessStatus.ACCESS_STATUS_UNSPECIFIED]: undefined,
}

/**
 * Statuses in the bookkeeping progression flow.
 */
const bookkeepingProgressionStatus = {
  SETTING_UP_JOB: 'SettingUpJob',
  PENDING_ACCESS: 'PendingAccess',
  ADMIN_CONFIRMATION: 'AdminConfirmation',
  READY_TO_START: 'ReadyToStart',
  ASSESSING_BOOKS: 'AssessingBooks',
  IN_PROGRESS: 'InProgress',
  CYCLE_COMPLETE: 'CycleComplete',
  BOOKKEEPING_COMPLETE: 'BookkeepingComplete',
  AWAITING_ACCESS_CONFIRMATION: 'AwaitingAccessConfirmation',
}

export class BookkeepingProgressionInfo extends Model {
  @observable id = null
  @observable stage = null
  @observable accessStatus = null
  @observable connection = null
  @observable cycles = []
  @observable workItems = []

  /**
   * The current step status.
   */
  @computed
  get currentStepStatus() {
    return this.determineStep(this)
  }

  parse(info) {
    return {
      id: info.id,
      stage: BookkeepingStageDto[info.stage],
      accessStatus: AccessStatusDto[info.accessStatus],
      isApplicable: info.isApplicable,
      connection: this.toPlatformConnectionDTO(info.platformConnection),
      cycles: info.cyclesList?.map(this.toCycleDTO) ?? [],
      workItems: info.workItemsList?.map(this.toWorkItemDTO) ?? [],
    }
  }

  /**
   * Maps a connection to a DTO.
   *
   * @param {*} connection
   * @returns
   */
  toPlatformConnectionDTO(connection) {
    if (connection?.quickBooks) {
      const quickBooks = connection.quickBooks
      return {
        platform: 'QuickBooks',
        connectionId: quickBooks.connectionId,
        accountId: quickBooks.companyId,
        accountName: quickBooks.companyName,
      }
    }

    if (connection?.xero) {
      const xero = connection.xero
      return {
        platform: 'Xero',
        connectionId: xero.connectionId,
        accountId: xero.tenantId,
        accountName: xero.tenantName,
      }
    }

    return { type: 'NONE' }
  }

  /**
   * Maps a cycle to a DTO.
   *
   * @param {*} cycle
   * @returns
   */
  toCycleDTO(cycle) {
    const baseProperties = {
      if: cycle.id,
      number: cycle.number,
      startedAt: maybeParseDate(timestampToISO(cycle.startedAt)),
    }

    if (cycle.status?.inProgress) {
      return {
        ...baseProperties,
        status: 'IN_PROGRESS',
      }
    }

    if (cycle.status?.completed) {
      const completed = cycle.status.completed
      return {
        ...baseProperties,
        status: 'COMPLETED',
        completedById: completed.completedById,
        completedAt: maybeParseDate(timestampToISO(completed.completedAt)),
        items: completed?.itemsList?.map((item) => {
          return {
            id: item.id,
            name: item.name,
            amount: item.amount,
          }
        }),
      }
    }

    if (cycle.status?.canceled) {
      const canceled = cycle.status.canceled
      return {
        ...baseProperties,
        status: 'CANCELLED',
        canceledAt: maybeParseDate(timestampToISO(canceled.canceledAt)),
      }
    }

    return { status: 'UNKNOWN' }
  }

  /**
   * Maps a work item to a DTO.
   *
   * @param {*} workItem
   * @returns
   */
  toWorkItemDTO(workItem) {
    return {
      workItemId: workItem.workItemId,
      inventoryItemId: workItem.inventoryItemId,
      inventoryItemName: workItem.inventoryItemName,
      quantity: workItem.quantity,
      completedCount: workItem.completedCount,
    }
  }

  /**
   * Determines the current step based on the bookkeeping progression info.
   *
   * @param {*} info
   * @returns
   */
  determineStep(info) {
    if (info.stage === 'Pending') {
      return bookkeepingProgressionStatus.SETTING_UP_JOB
    }

    if (info.stage === 'ProvisioningAccess') {
      switch (info.accessStatus) {
        case 'NotRequested':
        case 'ManagedAccessClientConnectionRequested':
        case 'ManagedAccessClientConfirmationRequested':
          return bookkeepingProgressionStatus.PENDING_ACCESS
        case 'ManagedAccessAdminConfirmationRequested':
          return bookkeepingProgressionStatus.ADMIN_CONFIRMATION
        case 'UnmanagedAccessProviderConfirmationRequested':
          return bookkeepingProgressionStatus.AWAITING_ACCESS_CONFIRMATION
        default:
          return bookkeepingProgressionStatus.READY_TO_START
      }
    }

    switch (info.stage) {
      case 'AssessingBooks':
        return bookkeepingProgressionStatus.ASSESSING_BOOKS
      case 'CycleInProgress':
        if (info.cycles[info.cycles.length - 1]?.status === 'COMPLETED') {
          return bookkeepingProgressionStatus.CYCLE_COMPLETE
        } else {
          return bookkeepingProgressionStatus.IN_PROGRESS
        }
      case 'Completed':
        return bookkeepingProgressionStatus.BOOKKEEPING_COMPLETE
      default:
        return bookkeepingProgressionStatus.READY_TO_START
    }
  }
}
