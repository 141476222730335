import { action, observable, computed } from 'mobx'
import { task } from 'mobx-task'
import { AudioContext } from 'standardized-audio-context'
import { AudioAnalysis } from 'src/client/utils/AudioAnalysis'
import { toStream, fromStream } from 'utils/mobxUtil'
import { map, share } from 'rxjs/operators'
import { shareLatest } from 'utils/rxUtil'
import { Subject } from 'rxjs'

const storageKeyPrefix = 'CP.PreferredMediaDevice'
const StorageKeys = {
  audioIn: `${storageKeyPrefix}.audioIn`,
  audioOut: `${storageKeyPrefix}.audioOut`,
  videoIn: `${storageKeyPrefix}.videoIn`,
}

/**
 * @typedef DevicePreferences
 * @property {'audioIn' | 'audioOut' | 'videoIn'} kind
 * @property {string} deviceId
 */

export class MediaSettingsStore {
  /**
   * @type {MediaDeviceInfo[]}
   */
  @observable availableDevices = []

  @observable prefAudioInputDeviceId = null
  @observable prefVideoInputDeviceId = null
  @observable prefAudioOutputDeviceId = null

  /**
   * @type {Subject<DevicePreferences}
   */
  devicePreferenceSubject = new Subject()

  constructor(localStorage) {
    this.localStorage = localStorage
    this.loadPersisted()
    this.devicePreferencesChanges$ = this.devicePreferenceSubject.pipe(share())
  }

  async requestDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices()
    this.setAvailableDevices(devices)
  }

  @computed
  get audioInputDevices() {
    return this.availableDevices.filter(
      (device) => device.kind === 'audioinput'
    )
  }

  @computed
  get videoDevices() {
    return this.availableDevices.filter(
      (device) => device.kind === 'videoinput'
    )
  }

  @computed
  get audioOutputDevices() {
    return this.availableDevices.filter(
      (device) => device.kind === 'audiooutput'
    )
  }

  @action.bound
  setAvailableDevices(devices) {
    this.availableDevices = devices
  }

  getById(deviceId) {
    return this.availableDevices.find((d) => d.deviceId === deviceId) ?? null
  }

  @computed
  get preferredAudioInputDevice() {
    if (this.prefAudioInputDeviceId) {
      const byId = this.getById(this.prefAudioInputDeviceId)
      if (byId) {
        return byId
      }
    }

    if (this.audioInputDevices.length > 0) {
      return this.audioInputDevices[0]
    }
    return null
  }

  @computed
  get preferredVideoDevice() {
    if (this.prefVideoInputDeviceId) {
      const byId = this.getById(this.prefVideoInputDeviceId)
      if (byId) {
        return byId
      }
    }

    if (this.videoDevices.length > 0) {
      return this.videoDevices[0]
    }
    return null
  }

  @computed
  get preferredAudioOutDevice() {
    if (this.prefAudioOutputDeviceId) {
      const byId = this.getById(this.prefAudioOutputDeviceId)
      if (byId) {
        return byId
      }
    }

    if (this.audioOutputDevices.length > 0) {
      return this.audioOutputDevices[0]
    }

    return null
  }

  @action.bound
  setPreferredAudioInputDevice(deviceId) {
    this.prefAudioInputDeviceId = deviceId
    this.devicePreferenceSubject.next({
      deviceId: deviceId,
      kind: 'audioIn',
    })
    this.persist()
  }

  @action.bound
  setPreferredVideoInputDevice(deviceId) {
    this.prefVideoInputDeviceId = deviceId
    this.devicePreferenceSubject.next({
      deviceId: deviceId,
      kind: 'videoIn',
    })
    this.persist()
  }

  @action.bound
  setPreferredAudioOutputDevice(deviceId) {
    this.prefAudioOutputDeviceId = deviceId
    this.devicePreferenceSubject.next({
      deviceId: deviceId,
      kind: 'audioOut',
    })
    this.persist()
  }

  @action.bound
  loadPersisted() {
    const audioInDevId = this.localStorage.getItem(StorageKeys.audioIn)
    const videoInDevId = this.localStorage.getItem(StorageKeys.videoIn)
    const audioOutDevId = this.localStorage.getItem(StorageKeys.audioOut)
    if (audioInDevId) {
      this.prefAudioInputDeviceId = audioInDevId
    }
    if (videoInDevId) {
      this.prefVideoInputDeviceId = videoInDevId
    }
    if (audioOutDevId) {
      this.prefAudioOutputDeviceId = audioOutDevId
    }
  }

  persist() {
    if (this.prefAudioInputDeviceId) {
      this.localStorage.setItem(
        StorageKeys.audioIn,
        this.prefAudioInputDeviceId
      )
    }
    if (this.prefVideoInputDeviceId) {
      this.localStorage.setItem(
        StorageKeys.videoIn,
        this.prefVideoInputDeviceId
      )
    }

    if (this.prefAudioOutputDeviceId) {
      this.localStorage.setItem(
        StorageKeys.audioOut,
        this.prefAudioOutputDeviceId
      )
    }
  }
}

export class MediaSettingsDialogViewModel {
  @observable isOpened = false

  /**
   * @type {MediaStream | null}
   */
  @observable microphoneAudioStream = null

  /**
   * @type {MediaStream | null}
   */
  @observable cameraMediaStream = null

  @observable playingTestAudio = false

  /**
   *
   * @param {MediaSettingsStore} mediaSettingsStore
   */
  constructor(mediaSettingsStore, sessionStore) {
    this.mediaSettingsStore = mediaSettingsStore
    this.sessionStore = sessionStore
  }

  /**
   * Shortcut to the current member.
   */
  @computed
  get currentMember() {
    return this.sessionStore.member
  }

  @computed
  get canHaveOutgoingVideo() {
    return Boolean(this.currentMember.hasPermission('CALL_ENABLE_CAMERA'))
  }

  activate = task(async () => {
    this.audioCtx = new AudioContext()

    /**
     * @returns {Observable<import('src/client/utils/AudioAnalysis').AudioAnalysisItem>}
     */
    this.audioAnalysis$ = AudioAnalysis(
      this.audioCtx,
      toStream(() => this.selectedMicrophoneMediaStream, true),
      100
    ).pipe(shareLatest())

    this.selectedMicLoudness = fromStream(
      0,
      this.audioAnalysis$.pipe(map((item) => item.loudness))
    )

    await this.mediaSettingsStore.requestDevices()
    await this.getUserMicrophoneMedia()
    if (this.canHaveOutgoingVideo) {
      await this.getUserCameraMedia()
    }
  })

  @action.bound
  async getUserMicrophoneMedia() {
    const micMediaStream = await navigator.mediaDevices.getUserMedia(
      this._selectedMicrophoneMediaStreamConstraints
    )

    this._setSelectedMicMediaStream(micMediaStream)
  }

  @action.bound
  async getUserCameraMedia() {
    const camMediaStream = await navigator.mediaDevices.getUserMedia(
      this._selectedCameraMediaStreamConstraints
    )

    this._setSelectedCamMediaStream(camMediaStream)
  }

  @action.bound
  testSpeaker() {
    const ringAudio = {
      mp3: require('sound/sounds/bell_ring.mp3'),
      ogg: require('sound/sounds/bell_ring.ogg'),
    }

    this._setPlayingTestAudio(true)

    const audio = new window.Audio()
    const type = audio.canPlayType('audio/mpeg') ? 'mp3' : 'ogg'
    audio.volume = 0.6
    audio.src = ringAudio[type]
    audio.onended = () => {
      this._setPlayingTestAudio(false)
    }

    audio
      .setSinkId(this.selectedSpeaker.deviceId)
      .then(() => audio.play())
      .catch((err) => {
        console.error('error playing test sound', err)
        this._setPlayingTestAudio(false)
      })
  }

  @action
  _setPlayingTestAudio(playing) {
    this.playingTestAudio = playing
  }

  @computed
  get devices() {
    return this.mediaSettingsStore.availableDevices
  }

  @computed
  get microphones() {
    return this.mediaSettingsStore.audioInputDevices
  }

  @computed
  get cameras() {
    return this.mediaSettingsStore.videoDevices
  }

  @computed
  get speakers() {
    return this.mediaSettingsStore.audioOutputDevices
  }

  @computed
  get selectedMicrophone() {
    return this.mediaSettingsStore.preferredAudioInputDevice
  }

  @computed
  get selectedCamera() {
    return this.mediaSettingsStore.preferredVideoDevice
  }

  @computed
  get selectedSpeaker() {
    return this.mediaSettingsStore.preferredAudioOutDevice
  }

  @computed
  get selectedMicLevels() {
    return this.selectedMicLoudness()
  }

  /**
   * @returns {MediaStreamConstraints}
   */
  @computed
  get _selectedMicrophoneMediaStreamConstraints() {
    return { audio: { deviceId: this.selectedMicrophone.deviceId } }
  }

  /**
   * @returns {MediaStreamConstraints}
   */
  @computed
  get _selectedCameraMediaStreamConstraints() {
    return { video: { deviceId: this.selectedCamera.deviceId } }
  }

  @computed
  get selectedMicrophoneMediaStream() {
    return this.microphoneAudioStream
  }

  @computed
  get selectedCameraMediaStream() {
    return this.cameraMediaStream
  }

  @action.bound
  setSelectedMicrophoneDeviceId(deviceId) {
    this.mediaSettingsStore.setPreferredAudioInputDevice(deviceId)
    this.getUserMicrophoneMedia()
  }

  @action.bound
  setSelectedCameraDeviceId(deviceId) {
    this.mediaSettingsStore.setPreferredVideoInputDevice(deviceId)
    this.getUserCameraMedia()
  }

  @action.bound
  setSelectedSpeakerDeviceId(deviceId) {
    this.mediaSettingsStore.setPreferredAudioOutputDevice(deviceId)
  }

  @action.bound
  show() {
    this.isOpened = true
    this.activate()
  }

  @action.bound
  close() {
    this.selectedMicLoudness.dispose()
    this.microphoneAudioStream.getTracks().forEach((t) => t.stop())
    if (this.canHaveOutgoingVideo) {
      this.cameraMediaStream.getTracks().forEach((t) => t.stop())
    }
    this._setSelectedMicMediaStream(null)
    this._setSelectedCamMediaStream(null)
    this.audioCtx = null
    this.isOpened = false
  }

  /**
   *
   * @param {MediaStream} mediaStream
   */
  @action.bound
  _setSelectedCamMediaStream(mediaStream) {
    this.cameraMediaStream = mediaStream
  }

  /**
   *
   * @param {MediaStream} mediaStream
   */
  @action.bound
  _setSelectedMicMediaStream(mediaStream) {
    this.microphoneAudioStream = mediaStream
  }
}
