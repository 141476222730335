import { observable, computed, action, toJS, runInAction } from 'mobx'
import last from 'lodash/last'
import debounce from 'lodash/debounce'
import { task } from 'mobx-task'

export default class QuestionsState {
  stepId = 'Questions'
  trackingId = 'Onboarding - Swipable Questions'

  @observable
  currentQuestionId = null

  constructor({ wizard }) {
    this.wizard = wizard
    this._saveAnswers = debounce(this._saveAnswers.bind(this), 500)
  }

  @computed
  get isProcessing() {
    return this.getQuestions.pending
  }

  @computed
  get title() {
    return 'Tell us about it'
  }

  @computed
  get legendId() {
    return (
      this.wizard.getStep('ProjectType').legend?.id || this.wizard.job?.legendId
    )
  }

  @computed
  get questions() {
    if (!this.wizard.job) {
      return []
    }

    return this.wizard.rootStore.questionStore.questionsForJob(
      this.wizard.job.id
    )
  }

  @computed
  get answers() {
    return this.questions.filter((question) => question.isAnswered)
  }

  @computed
  get hasAnsweredAllQuestions() {
    return this.questions.every((question) => question.isAnswered)
  }

  @computed
  get nextEnabled() {
    if (this.currentQuestion?.isBreaker) {
      // undefined = hide button
      return undefined
    }

    return true
  }

  @computed
  get currentQuestionIndex() {
    return this.questions.indexOf(this.currentQuestion)
  }

  @computed
  get currentQuestion() {
    return this.questions.find((x) => x.id === this.currentQuestionId)
  }

  @computed
  get isCurrentQuestionSkippable() {
    return Boolean(
      this.currentQuestion?.type !== 'BREAKER' &&
        this.currentQuestion?.type !== 'DOCUMENTS'
    )
  }

  @action
  breaker() {
    return this.wizard.setStep(this.wizard.getStep('ProjectType'))
  }

  @action
  setQuestion(question) {
    if (!question) {
      this.currentQuestionId = null
      return
    }
    this.currentQuestionId = question.id
  }

  @task
  async activate() {
    // Need to sync the job so we are sure to fetch the right questions.
    if (!this.wizard.job) {
      await this.wizard.syncJob()
    }
    if (
      this._lastFetchedLegendId &&
      this.legendId &&
      this._lastFetchedLegendId !== this.legendId
    ) {
      // Means we are switching legends
      await this._clearAnswers()
    }

    await this.loadQuestions()
    this._lastFetchedLegendId = this.legendId
  }

  @task.resolved
  async giveAnswer(question, answer) {
    if (this.wizard.currentStep !== this) {
      return
    }

    this.setAnswer(question, answer)

    // For tracking...
    this.wizard.rootStore.trackingStore.track('Onboarding Question', {
      job_id: this.wizard.job?.id,
      workspace_id: this.wizard.job ? this.wizard.job.workspaceId : null,
      legend_id: this.legendId,
      question: question.id,
      answer: answer,
    })

    const startTime = performance.now()
    this.wizard
      .next()
      .then(() =>
        this.trackAutoContinueAndRecordLatency(question, answer, startTime)
      )
  }

  @task.resolved
  async getQuestions() {
    if (!this.wizard.job) {
      return
    }

    await this.wizard.rootStore.questionStore.fetchQuestions(
      this.wizard.job.id,
      this.questions
    )
  }

  @action
  setAnswer(question, answer) {
    question.answer = answer

    this._saveAnswers()
    this.trackQuestionsAndAnswers(question, answer)
  }

  @action
  async next() {
    await this.getQuestions()

    if (this.currentQuestionIndex === this.questions.length - 1) {
      const unanswered = this.questions.filter((q) => !q.isAnswered)
      this.wizard.setNewlyCreatedJob(false)

      if (unanswered.length === 0 || unanswered.every((q) => q.isDocument)) {
        return true
      } else {
        this.setQuestion(unanswered[0])
        return false
      }
    }
    this.setQuestion(this.questions[this.currentQuestionIndex + 1])
  }

  @action
  back(previousStep) {
    if (this.currentQuestionIndex === 0) {
      previousStep()
    } else {
      this.setQuestion(this.questions[this.currentQuestionIndex - 1])
    }
  }

  @task.resolved
  async loadQuestions() {
    await this.getQuestions()

    if (!this.currentQuestionId) {
      this.setQuestion(this.questions[0])
    }
  }

  trackQuestionsAndAnswers(question, answer) {
    this.wizard.rootStore.trackingStore.trackRudderAnalytics('onboarding', {
      ev_onboarding_question_id: question?.id,
      ev_onboarding_question: question?.question,
      ev_onboarding_question_answer: answer,
      ev_onboarding_question_options: question?.options?.map((option) => ({
        id: option?.id,
        text: option?.text,
      })),
      ev_onboarding_job_id: question?.jobId,
      ev_onboarding_legend_id: this.legendId,
      ev_onboarding_question_type: question?.type,
      ev_onboarding_question_type_multi: question?.multi,
      ev_onboarding_question_collect_time: question?.collect_time,
      ev_onboarding_question_disallow_future: question?.disallow_future,
      ev_onboarding_question_disallow_past: question?.disallow_past,
    })
  }

  trackAutoContinueAndRecordLatency(question, answer, startDateTime) {
    const latency = performance.now() - startDateTime
    this.wizard.rootStore.trackingStore.trackRudderAnalytics(
      'respond to question - auto continue',
      {
        question_id: question?.id,
        answer: answer,
        job_id: question?.jobId,
        latency_ms: latency,
      }
    )
  }

  async hydrate() {
    await this.getQuestions()

    const question =
      this.questions.find((q) => !q.isAnswered) || last(this.questions)

    if (question) {
      this.setQuestion(question)
    }
  }

  serializeToJob() {
    // - Called when creating a new job
    // - Called when exiting the wizard step
    let answers = []
    if (this.wizard.job) {
      answers = toJS(
        this.answers.map((answer) => ({ id: answer.id, answer: answer.answer }))
      )
    }

    return {
      answers: answers,
    }
  }

  async _saveAnswers() {
    await this.wizard.rootStore.draftStore.replaceQuestionAnswers(
      this.wizard.job.id,
      this.questions
    )
  }

  async _clearAnswers() {
    if (!this.wizard.job) {
      return
    }

    this.questions.forEach((question) => question.clear())

    await this.wizard.rootStore.draftStore.replaceQuestionAnswers(
      this.wizard.job.id,
      []
    )

    runInAction(() => {
      this.currentQuestionId = null
    })
  }
}
