import cx from 'classnames'
import { BodyClass } from 'components/ClassName'
import { observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import styles from './PasswordResetPage.m.sass'
import Button from 'components/Button'
import FlashMessages from 'flash-messages/components/FlashMessages'

import { Input } from 'components/Form'

export default observer(function PasswordResetPage({
  store,
  flashMessageStore,
  showPassword,
  title,
  description,
  onSubmit,
  submitButtonText,
}) {
  const workspace = (window.__workspaceDomain &&
    window.__workspaceDomain.workspace) || {
    branding: { logos: {}, colors: {}, landing_page: {}, corporate: {} },
    config: { signup: {} },
  }
  const branded = {
    id: workspace.id || undefined,
    name: workspace.name || 'Worklayer',
    corporate: workspace.branding.corporate.enabled || false,
    logo:
      workspace.branding.logos.primary ||
      require('./img/logo-worklayer-blue.png'),
    image:
      workspace.branding.landing_page.image ||
      'https://storage.googleapis.com/taxfyle-assets-public/taxfyle-workspace-assets/signup-graphic.svg',
  }

  return (
    <div className={cx(styles.root)}>
      <Helmet title={branded.name} />
      <FlashMessages store={flashMessageStore} position="bottomLeft" />
      <BodyClass className={styles.body} />
      <CardView>
        <div className={styles.cardContent}>
          <div className={styles.cardItem}>
            <img alt="Worklayer" src={branded.logo} />
          </div>

          <div className={cx(styles.cardItem, styles.center)}>
            <div className={styles.title}>{title}</div>
            <div className={styles.description}>{description}</div>
            <div>
              <div className={styles.label}>Email</div>
              <div>
                <Input
                  autoFocus
                  className={cx(styles.input)}
                  model={store}
                  field="email"
                  disabled={showPassword}
                />
              </div>
            </div>
            {showPassword && (
              <div className={styles.spaceBetween}>
                <div className={styles.label}>Password</div>
                <div>
                  <Input
                    type="password"
                    className={cx(styles.input)}
                    model={store}
                    field="password"
                  />
                </div>
                <div className={styles.columns}>
                  <ul className={styles.list}>
                    <li
                      className={cx(
                        store.lowerCaseChar ? styles.checked : styles.fail
                      )}
                    >
                      One lowercase character
                    </li>
                    <li
                      className={cx(
                        store.upperCaseChar ? styles.checked : styles.fail
                      )}
                    >
                      One uppercase character
                    </li>
                    <li
                      className={cx(
                        store.numberChar ? styles.checked : styles.fail
                      )}
                    >
                      One number
                    </li>
                  </ul>
                  <ul className={styles.list}>
                    <li
                      className={cx(
                        store.specialChar ? styles.checked : styles.fail
                      )}
                    >
                      One special character
                    </li>
                    <li
                      className={cx(
                        store.minimumLength ? styles.checked : styles.fail
                      )}
                    >
                      8 characters minimum
                    </li>
                    <li
                      className={cx(
                        store.maximumLength ? styles.checked : styles.fail
                      )}
                    >
                      50 characters maximum
                    </li>
                  </ul>
                </div>
              </div>
            )}
            <Button
              onClick={onSubmit}
              className={styles.submit}
              data-test="SubmitButton"
              disabled={
                (showPassword && !store.passwordValid) ||
                store.email.length === 0 ||
                store.sendEmail.pending ||
                store.resetPassword.pending
              }
            >
              {submitButtonText}
            </Button>
            {store.errorText && (
              <p className={styles.error}>{store.errorText}</p>
            )}
          </div>

          <div className={cx(styles.cardItem, styles.footer, styles.center)}>
            {!branded.corporate && (
              <div>
                Copyright &copy; Tickmark, Inc {new Date().getFullYear()}
              </div>
            )}
          </div>
        </div>
      </CardView>
      <ImageView image={branded.image} />
    </div>
  )
})

const CardView = ({ children }) => (
  <div className={styles.cardView}>{children}</div>
)

const ImageView = ({ image }) => (
  <div
    className={styles.imageView}
    style={{
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
    }}
  ></div>
)
