import { observable, action, computed } from 'mobx'
import { Store } from 'libx'
import { task } from 'mobx-task'

export default class BookkeepingPlatformConnectionsDialogStore extends Store {
  @observable
  showing = false

  @observable
  success = false

  @observable
  platform = ''

  @observable
  selectedConnectionId = null

  /**
   * Accounts for the selected platform
   */
  @computed
  get accounts() {
    return this.platform === 'QuickBooks'
      ? this.rootStore.bookkeepingPlatformConnectionStore.quickBooksAccounts
      : this.rootStore.bookkeepingPlatformConnectionStore.xeroAccounts
  }

  /**
   * Scroll provider for the selected platform
   */
  @computed
  get scrollProvider() {
    return this.rootStore.bookkeepingPlatformConnectionStore.getScrollProvider(
      this.platform,
      (params) => this.fetchItems(params)
    )
  }

  @action
  show(platform) {
    this.platform = platform
    this.showing = true
  }

  @action
  close() {
    this.showing = false
    this.success = false
  }

  /**
   * Fetches items for the selected platform
   *
   * @param {*} params
   */
  async fetchItems(params) {
    const store = this.rootStore.bookkeepingPlatformConnectionStore

    return this.platform === 'QuickBooks'
      ? store.fetchQuickBooksPlatformConnections(params)
      : store.fetchXeroPlatformConnections(params)
  }

  /**
   * Opens the add manual connection dialog.
   *
   * */
  addManualConnection() {
    this.rootStore.bookkeepingAddManualConnectionStore.show()
    this.close()
  }

  /**
   * Provides the platform connection and opens the confirm access dialog.
   *
   * @param {string} connectionId
   * @param {string} accountName
   */
  @task.resolved
  async selectAccount(connectionId, accountName) {
    const store = this.rootStore.bookkeepingPlatformConnectionStore
    const jobId = this.rootStore.projectDetailsStore.project.id

    await store.providePlatformConnection(jobId, {
      connectionId,
      platform: this.platform,
    })

    this.rootStore.bookkeepingConfirmAccessStore.show(
      this.platform,
      accountName
    )
    this.close()
  }

  /*
   * Back to platform connection.
   *
   */
  back() {
    this.close()
    this.rootStore.bookkeepingPlatformSelectionStore.show()
  }

  /**
   * Redirect to platform oauth2 page
   */
  connectOAuth2() {
    const store = this.rootStore.bookkeepingPlatformConnectionStore
    const job = this.rootStore.projectDetailsStore.project

    switch (this.platform) {
      case 'QuickBooks':
        return store.redirectToQuickBooksOauth2Page(job.id)
      case 'Xero':
        return store.redirectToXeroOauth2Page(job.id)
      default:
        return this.rootStore.flashMessageStore
          .create({
            type: 'error',
            message: `Unknown platform ${this.platform}`,
          })
          .autoDismiss()
    }
  }
}
