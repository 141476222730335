import { Store } from 'libx'
import { observable, computed, action, runInAction, reaction } from 'mobx'
import { task } from 'mobx-task'
import InfiniteScrollProvider, {
  usingSkipLimit,
} from '@taxfyle/web-commons/lib/data/InfiniteScrollProvider'
import { makeMomentSorter } from '@taxfyle/web-commons/lib/utils/arrayUtil'
import { browserHistory } from 'react-router'
import links from 'misc/links'
import { ConfirmDialogState } from 'components/ConfirmDialog'
import { canCreateJobs } from 'utils/permissionUtil'
import { getFeatureToggleClient } from 'misc/featureToggles'

export default class ProjectScreenStore extends Store {
  /**
   * Can be `INDIVIDUAL` or `TEAM` or 'ALL. If `TEAM`, also has a `team` property.  If 'INDIVIDUAL', only jobs that you are champion
   */
  @observable ownerFilter = null

  /**
   * Can be a status, or `ALL` or 'CURRENT'.
   */
  @observable statusFilter = 'CURRENT'

  /**
   * Has the user already been redirected?
   */
  @observable redirectedFlag = false

  /**
   * Can be 'LIST', 'GRID', or 'KANBAN'.
   */
  @observable displayOption = 'GRID'

  /**
   * Can be anything you want. Used for implementing job filtering.
   */
  @observable searchText = ''

  /**
   * Scroll providers map.
   */
  jobsScroller = new InfiniteScrollProvider({
    limit: 20,
    fetchItems: (params) => {
      const useV3SearchJobs = getFeatureToggleClient().variation(
        'CustomerPortal.UseV3SearchJobs',
        false
      )

      return useV3SearchJobs
        ? this._fetchJobs(params)
        : usingSkipLimit((another) => this._fetchJobs(another))(params)
    },
  })

  @observable
  jobToRemove = null

  @observable
  removeJobDialog = null

  /**
   * Array of possible statuses.
   */
  availableStatuses = [
    'CURRENT',
    'ALL',
    'UNDER_CONSTRUCTION',
    // 'INFO_GATHERING',
    'UNCLAIMED',
    'CLAIMED',
    'ON_HOLD',
    'IDLE',
    'CLOSED',
  ]

  /**
   * Constructor.
   */
  constructor() {
    super(...arguments)
    this.removeJobDialog = new ConfirmDialogState()
    this.ensureTermsAcceptance = this.ensureTermsAcceptance.bind(this)
    this.termsDialogVM = this.rootStore.termsDialogVM
    this.handoffDialogVM = this.rootStore.handoffDialogVM
    if (typeof Storage !== 'undefined') {
      if (localStorage && localStorage.getItem('displayOption')) {
        this.displayOption = localStorage.getItem('displayOption') || 'GRID'
      }
    }
    reaction(
      () => [this.searchText, this.ownerFilter, this.statusFilter],
      this.jobsScroller.fetch
    )
  }

  /**
   * Whether the user has submitted a job.
   */
  @computed
  get firstJob() {
    return this.rootStore.projectStore.projects.length === 0
  }

  @computed
  get showTaxfyleBox() {
    const workspace = this.rootStore.sessionStore.workspace
    if (!workspace || workspace.id !== '1') {
      return false
    }
    return true
  }

  @computed
  get showSmallBusinessBox() {
    const workspace = this.rootStore.sessionStore.workspace
    if (!workspace || workspace.id !== '1') {
      return false
    }
    // for DEV-8516
    return true

    // if (!this.firstJob) {
    //   return true
    // }
    // return false
  }

  /**
   * Whether to show navigation and job search.
   */
  @computed
  get hideWelcomeMessage() {
    return (
      this.rootStore.projectStore.projects.length > 3 ||
      (this.rootStore.projectStore.projects.length > 0 && this.isReferEnabled)
    )
  }

  @computed
  get shouldPromptForTerms() {
    const tos = this.rootStore.sessionStore.workspace.features.tos
    const newTermsEnabled = getFeatureToggleClient().variation(
      'Portals.UseNewTerms',
      false
    )
    return (
      (newTermsEnabled ? !tos.hideOnCustomerPortal : tos.enabled) &&
      tos.showOnLogin
    )
  }

  /**
   * Shortcut to the current member.
   */
  @computed
  get currentMember() {
    return this.rootStore.sessionStore.member
  }

  @computed
  get currentWorkspace() {
    return this.rootStore.sessionStore.workspace
  }

  @computed
  get isReferEnabled() {
    return !!this.rootStore.sessionStore.workspace.features.billing
      .couponsReferral.enabled
  }

  @computed
  get isCorporateEnabled() {
    return this.rootStore.sessionStore.workspace.corporate
  }

  /**
   * The available teams to view jobs for.
   */
  @computed
  get availableTeams() {
    return this.rootStore.projectStore.currentUserTeams
  }

  @computed
  get canCreateJobs() {
    const member = this.rootStore.sessionStore.member
    const teamMembers = this.rootStore.sessionStore.teamMembers

    return canCreateJobs(member, teamMembers)
  }

  /**
   * Filtered, sorted jobs.
   */
  @computed
  get jobs() {
    const jobs = this.jobsScroller.items
    const { ownerFilter } = this

    if (!ownerFilter) {
      return []
    }

    const sortByTransmitted = makeMomentSorter(
      'asc',
      (x) => x.dateTransmitted,
      'last'
    )
    const sortByDeadline = makeMomentSorter(
      'asc',
      (x) => x.dateDeadline,
      'last'
    )
    const sortFn = (left, right) => {
      if (left.dateDeadline === right.dateDeadline) {
        return sortByTransmitted(left, right)
      }

      const byDeadline = sortByDeadline(left, right)
      return byDeadline
    }
    return (
      jobs
        .map((x) => x)
        // TODO: change if they modify the sort order on the server.
        .sort(sortFn)
    )
  }

  @computed
  get customTermsOfUseEnabled() {
    return this.rootStore.sessionStore.workspace.features.tos.customTermsEnabled
  }

  @task
  async activate() {
    await this.jobsScroller.fetch()

    if (this.shouldPromptForTerms) {
      await this.termsDialogVM.activate(false)
      await this.ensureTermsAcceptance()
    }

    if (
      this.rootStore.sessionStore.workspace.id === '1' &&
      this.rootStore.jobStore.jobs.length === 0 &&
      this.redirectedFlag === false
    ) {
      this.redirectedFlag = true
      browserHistory.push(links.newProject())
    }

    // Don't mess with the filter if the user already picked one.
    if (this.ownerFilter) {
      return
    }

    runInAction(() => {
      this.setOwnerFilter({ type: 'ALL' })
    })
  }

  @action.bound
  deleteJob(job) {
    this.jobToRemove = job
    return this.removeJobDialog.show().then((yes) => {
      if (yes) {
        this.jobsScroller.removeItem(this.jobToRemove)
        return this.rootStore.projectStore.deleteJob(this.jobToRemove.id)
      }
    })
  }

  @task
  async ensureTermsAcceptance() {
    // If terms check isn't on in workspace don't need to check
    if (!this.shouldPromptForTerms) {
      return
    }

    // If custom terms is not enabled for the workspace accept global terms.
    const workspaceId = this.customTermsOfUseEnabled
      ? this.rootStore.sessionStore.workspace.id
      : null

    let latestTermsOfUseAccepted =
      await this.rootStore.memberStore.checkTermsOfUseAcceptance(workspaceId)
    if (!latestTermsOfUseAccepted) {
      this.handoffDialogVM.tryToShowDialog()
      latestTermsOfUseAccepted = await this.termsDialogVM.ensureAccepted()
    }

    if (!latestTermsOfUseAccepted) {
      browserHistory.push(links.projects())
    }
  }

  @action.bound
  setOwnerFilter(filter) {
    this.ownerFilter = filter
  }

  @action.bound
  setStatusFilter(filter) {
    this.statusFilter = filter
  }

  @action.bound
  setDisplayOption(option) {
    this.displayOption = option
    if (typeof Storage !== 'undefined') {
      localStorage && localStorage.setItem('displayOption', option)
    }
  }

  /**
   * Fetches jobs.
   */
  async _fetchJobs(params) {
    const { ownerFilter, statusFilter } = this
    const query =
      ownerFilter && ownerFilter.type === 'INDIVIDUAL'
        ? {
            role: 'CHAMPION',
            ...params,
          }
        : ownerFilter && ownerFilter.type === 'TEAM'
        ? {
            teamId: ownerFilter.team.id,
            ...params,
          }
        : {
            ...params,
          }

    return this.rootStore.jobStore.fetchJobs({
      after: params?.after,
      searchText: this.searchText,
      type: 'CLIENT',
      status: statusFilter === 'ALL' ? null : statusFilter,
      ...query,
    })
  }
}
