import { observable, action, computed } from 'mobx'
import { Store } from 'libx'
import { task } from 'mobx-task'
import { validationContext, required, func } from 'validx'

export default class BookkeepingAddManualConnectionDialogStore extends Store {
  @observable
  showing = false

  @observable
  success = false

  @observable
  platform = ''

  @observable
  companyName = ''

  @observable
  companyId = ''

  validation = validationContext(this, {
    companyName: [
      required('Company name is required'),
      func(({ value }) => {
        return (
          value.length >= 4 || "Company name can't be less than 4 characters"
        )
      }),
    ],
    companyId: [
      required('Company ID is required'),
      func(({ value }) => {
        return value.length >= 7 || "company ID can't be less than 7 characters"
      }),
    ],
  })

  constructor() {
    super(...arguments)
    this.submit = this.submit.bind(this)
  }

  @computed
  get workspaceId() {
    return this.rootStore.sessionStore.workspace.id
  }

  @action
  show() {
    // We currently only allow manual connections for QuickBooks
    this.platform = 'QuickBooks'
    this.showing = true
  }

  @task.resolved
  async submit() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    const jobId = this.rootStore.projectDetailsStore.project.id
    const connection =
      await this.rootStore.bookkeepingPlatformConnectionStore.createQuickBooksPlatformConnection(
        this.companyId,
        this.companyName,
        this.workspaceId
      )

    await this.rootStore.bookkeepingPlatformConnectionStore.providePlatformConnection(
      jobId,
      {
        connectionId: connection.connectionId,
        platform: this.platform,
      }
    )

    this.rootStore.bookkeepingConfirmAccessStore.show(
      this.platform,
      this.companyName
    )
    this.close()
  }

  /*
   * Opens the platform website.
   */
  openPlatform() {
    window.open('https://accounts.intuit.com/app/sign-in', '_blank')
  }

  @action
  back() {
    this.rootStore.bookkeepingPlatformConnectionsStore.show(this.platform)
    this.close()
  }

  @action
  close() {
    this.showing = false
    this.success = true
    this.companyName = ''
    this.companyId = ''
    this.platform = ''
  }
}
