import { observable, action } from 'mobx'
import defer from 'promise-defer'
import { validationContext, func } from 'validx'
import { phone } from 'phone'
import { task } from 'mobx-task'
import { Store } from 'libx'

export default class ConfirmPhoneNumberDialogStore extends Store {
  @observable
  showing = false

  @observable
  phoneNumber = ''

  @observable
  failedPhoneValidation = false

  onAccept = null

  validation = validationContext()

  rules = {
    phoneNumber: [
      func(({ value }) => phone(value).isValid, 'Not a valid phone number.'),
    ],
  }

  @action.bound
  setFailedPhoneValidation(value) {
    this.failedPhoneValidation = value
  }

  @action
  async show(onAccept = null) {
    this.onAccept = onAccept
    if (this.deferredRequest) {
      this.deferredRequest.resolve(false)
    }

    this.showing = true
    this.validation.reset()
    this.deferredRequest = defer()
    this.phoneNumber = this.rootStore.sessionStore?.member?.phone
    this.failedPhoneValidation = false

    return this.deferredRequest.promise
  }

  @action
  close() {
    const closingRules = {
      phoneNumber: [
        func(({ value }) => {
          if (phone(value).isValid && this.failedPhoneValidation) {
            return !phone(value).isValid || 'Please confirm your phone number.'
          }

          return phone(value).isValid || 'Not a valid phone number.'
        }),
      ],
    }

    if (
      !this.validation.reset().validate(this, closingRules).isValid ||
      this.failedPhoneValidation
    ) {
      this.failedPhoneValidation = true
      return
    }

    this._resolve()
    this.showing = false
  }

  @task.resolved
  async submit() {
    if (!this.validation.reset().validate(this, this.rules).isValid) {
      return
    }

    await this.rootStore.memberStore
      .updateByPublicId(
        {
          workspace_id: this.rootStore.sessionStore.workspace.id,
          user_id: this.rootStore.sessionStore.member.userPublicId,
        },
        { phone: this.phoneNumber }
      )
      .then(() => {
        this.setFailedPhoneValidation(false)
        this.close()
      })
      .then(this.onAccept)
  }

  _resolve = action(() => {
    if (!this.deferredRequest) return
    this.deferredRequest.resolve(true)
  })
}
