import { observable, action } from 'mobx'
import { Store } from 'libx'

export default class BookkeepingPlatformSelectionDialogStore extends Store {
  @observable
  showing = false

  @observable
  platform = ''

  @action
  show() {
    this.showing = true
  }

  @action
  close() {
    this.showing = false
  }

  @action
  submit() {
    if (!this.platform) {
      return
    }

    const dialogStore =
      this.platform === 'Other'
        ? this.rootStore.bookkeepingOtherConnectionStore
        : this.rootStore.bookkeepingPlatformConnectionsStore

    dialogStore.show(this.platform)
    this.close()
  }

  @action
  selectPlatform = (platform) => {
    this.platform = platform
  }
}
