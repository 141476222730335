import { Store } from 'libx'
import { observable, action } from 'mobx'

export default class JobProgressionTimelineDialogStore extends Store {
  @observable
  showing = false

  constructor() {
    super(...arguments)
    this.jobProgressionStore = this.rootStore.jobProgressionStore
  }

  @action.bound
  async show() {
    this.showing = true
  }

  @action.bound
  close() {
    this.showing = false
  }
}
