import { action, computed, observable } from 'mobx'
import { map } from 'rxjs/operators'
import { asAtom, toStream } from 'utils/mobxUtil'
import { shareLatest } from 'utils/rxUtil'
import { AudioContext } from 'standardized-audio-context'
import { AudioAnalysis } from 'src/client/utils/AudioAnalysis'

/**
 * @typedef {import('twilio-video').default} Twilio
 *
 * @typedef {Object} TwilioCallConnectionDescriptorDto
 * @property {'Twilio'} type
 * @property {string} token
 * @property {string} room_name
 *
 * @typedef {import('../CallViewModel').CallViewModel} CallViewModelType
 */

/**
 * Used by the view to render video and play the audio.
 * @typedef {Object} CallSourceMedia
 * @property {boolean} muted - Whether the audio source is muted.
 * @property {boolean} camera - Is the camera turned on?
 * @property {function(): number} getAudioLoudness
 * A number somewhere between 0 and 256.
 *
 * We represent loudness by taking the
 * highest value of the audio frequency bins
 * at the current time from the
 * active audio track. Recomputed every 500ms
 * when there's an audio track.
 *
 */

/**
 * Twilio call source.
 */
export class TwilioCallSource {
  /**
   * Twilio room participants.
   *
   * @type {TwilioParticipantMedia[]}
   * @private
   * @readonly
   */
  @observable roomParticipants = []

  /**
   * @param {CallViewModelType} callVM
   * @param {Twilio} twilio
   * @param {TwilioCallConnectionDescriptorDto} connectionDescriptor
   */
  constructor(callVM, twilio, connectionDescriptor) {
    this.callVM = callVM
    this.twilio = twilio
    this.connectionDescriptor = connectionDescriptor

    /**
     * @typedef {Twilio.Room} TwilioRoom
     *
     * The Twilio Video room.
     * @readonly
     * @type {?TwilioRoom}
     */
    this.room = null
  }

  /**
   * The current user participant.
   */
  @computed
  get currentUserParticipant() {
    return (
      this.roomParticipants.find(
        (rp) => rp.callParticipant === this.callVM.currentUserParticipant
      ) ?? null
    )
  }

  /**
   * Returns the media for the specified participant.
   *
   * @param {CallParticipantViewModel} callParticipantVM
   * @returns {CallSourceMedia|null}
   */
  media(callParticipantVM) {
    return (
      this.roomParticipants.find(
        (p) => p.callParticipant === callParticipantVM
      ) ?? null
    )
  }

  _micDeviceOptsFrom(deviceId) {
    return deviceId ? { deviceId: deviceId } : {}
  }

  /**
   * Connect to twilio room
   * @param {CancellationToken} _ct
   * @returns {Promise<void>}
   */
  async connect(_ct, microphoneDeviceId = null) {
    this.room = await this.twilio.connect(this.connectionDescriptor.token, {
      name: this.connectionDescriptor.room_name,
      video: false,
      audio: this._micDeviceOptsFrom(microphoneDeviceId),
    })

    this.receiveParticipant(this.room.localParticipant)
    this.room.participants.forEach((p) => this.receiveParticipant(p))
    this.room.on('participantConnected', (p) => this.receiveParticipant(p))
    this.room.on('participantDisconnected', (p) =>
      this.handleParticipantDisconnected(p)
    )
    this.room.once('disconnected', () => {
      this.roomParticipants.forEach((p) =>
        this.handleParticipantDisconnected(p.tp)
      )
    })
  }

  async disconnect() {
    if (!this.room) {
      console.warn('Attempted to disconnect but there was no room!')
      return
    }

    await this.turnOffMic()
    await this.turnOffCamera()
    await this.room.disconnect()
    this.room.removeAllListeners()
    return Promise.resolve(undefined)
  }

  /**
   *
   * @param {Twilio.Participant} participant
   */
  @action.bound
  receiveParticipant(participant) {
    if (this.roomParticipants.some((p) => p.tp === participant)) {
      return
    }

    this.roomParticipants.push(
      new TwilioParticipantMedia(participant, this.room, this.callVM)
    )
  }

  /**
   *
   * @param {Twilio.Participant} participant
   */
  @action.bound
  handleParticipantDisconnected(participant) {
    const idx = this.roomParticipants.findIndex((p) => p.tp === participant)
    if (idx > -1) {
      this.roomParticipants.splice(idx, 1)
    }
  }

  async turnOffMic() {
    // this.currentUserParticipant?.mute()
    if (this.currentUserParticipant?.muted) {
      return
    }

    const publication = this.currentUserParticipant?.audioTrack?.publication

    if (publication) {
      publication.track.stop()
      const up = publication.unpublish()
      this.currentUserParticipant?.removeLocalTrackPublication(up)
    }
  }

  async turnOnMic(micDeviceId = null) {
    // this.currentUserParticipant?.unmute()
    const room = this.room
    if (!room) {
      return
    }

    const localAudioTrack = await this.twilio.createLocalAudioTrack(
      this._micDeviceOptsFrom(micDeviceId)
    )
    const localAudioPublication = await room.localParticipant.publishTrack(
      localAudioTrack
    )
    this.currentUserParticipant?.addLocalTrackPublication(localAudioPublication)
  }

  async turnOnCamera(cameraDevicedId = null) {
    const room = this.room
    if (!room) {
      return
    }

    let videoTrackOpts = {}
    if (cameraDevicedId) {
      videoTrackOpts = { deviceId: cameraDevicedId }
    }

    const localVideoTrack = await this.twilio.createLocalVideoTrack(
      videoTrackOpts
    )
    const localVideoPublication = await room.localParticipant.publishTrack(
      localVideoTrack
    )
    this.currentUserParticipant?.addLocalTrackPublication(localVideoPublication)
  }

  async turnOffCamera() {
    if (!this.currentUserParticipant?.camera) {
      return
    }

    const videoTrack = this.currentUserParticipant.videoTrack
    // Get the track publication which is local since it's the current user's
    if (videoTrack) {
      const publication = videoTrack.publication
      publication.track.stop()
      publication.unpublish()
      this.currentUserParticipant.removeLocalTrackPublication(publication)
    }
  }

  dispose() {
    this.turnOffMic()
    this.turnOffCamera()
    this.room = null
  }
}

export class TwilioParticipantMedia {
  /**
   * @type {TwilioTrack|null}
   */
  @observable videoTrack = null
  /**
   * @type {TwilioTrack|null}
   */
  @observable audioTrack = null

  @observable isLocal = false

  /**
   *
   * @param {Twilio.Participant} tp
   * @param {Twilio.Room} tr
   * @param {CallViewModelType} callVM
   */
  constructor(tp, tr, callVM) {
    this.tp = tp
    this.tr = tr
    this.callVM = callVM

    this.isLocal = this.tr.localParticipant === this.tp

    this.audioCtx = new AudioContext()
    this.audioAnalysis$ = AudioAnalysis(
      this.audioCtx,
      toStream(() => this.audioMediaStream, true),
      500
    ).pipe(shareLatest())

    this.$ = {
      analysedLoudness: asAtom(
        this.audioAnalysis$.pipe(map((item) => item.loudness)),
        0
      ),
    }

    if (this.isLocal) {
      // Setup existing from existing published local tracks
      tp.tracks.forEach((tp) => this.receiveLocalPublication(tp))
    } else {
      // Setup remote tracks
      tp.tracks.forEach((tp) => this.receiveRemotePublication(tp))
      tp.on('trackPublished', this.receiveRemotePublication)
      tp.on('trackUnpublished', this.removeTrackPublication)
    }
  }

  @computed
  get audioLoudness() {
    return this.$.analysedLoudness()
  }

  /**
   * A number somewhere between 0 and 256
   * We represent loudness by taking the
   * highest value of the audio frequency bins
   * at the current time from the
   * active audio track. Recomputed every 500ms
   * when there's an audio track.
   */
  getAudioLoudness() {
    return this.audioLoudness
  }

  @computed
  get speaking() {
    const gain = 16
    return this.audioLoudness > 128 + gain
  }

  @computed
  get audioMediaStream() {
    if (!this.audioTrack || !this.audioTrack.track) {
      return null
    }

    const audioTrack = this.audioTrack.track
    const mediaStreamTrack = audioTrack.mediaStreamTrack
    const mediaStream = new MediaStream([mediaStreamTrack])
    return mediaStream
  }

  @computed
  get muted() {
    return this.audioTrack === null
  }

  @computed
  get camera() {
    return this.videoTrack !== null
  }

  @computed
  get callParticipant() {
    const p =
      this.callVM.participants.find(
        (p) => p.participant.member.userPublicId === this.tp.identity
      ) ?? null
    return p
  }

  /**
   *
   * @param {Twilio.LocalTrackPublication} publication
   */
  @action.bound
  addLocalTrackPublication(publication) {
    if (!this.isLocal) {
      console.error(
        'Tried to add local track publication to non local user',
        publication
      )

      return
    }
    this.receiveLocalPublication(publication)
  }

  /**
   *
   * @param {Twilio.LocalTrackPublication} publication
   */
  @action.bound
  removeLocalTrackPublication(publication) {
    if (!this.isLocal) {
      return
    }
    this.removeTrackPublication(publication)
  }

  /**
   *
   * @param {Twilio.LocalTrackPublication} publication
   */
  @action.bound
  receiveLocalPublication(publication) {
    if (!this.isLocal) {
      return
    }

    if (
      publication.kind === 'video' &&
      this.videoTrack?.publication !== publication
    ) {
      this.videoTrack = new TwilioTrack(publication)
    }

    if (
      publication.kind === 'audio' &&
      this.audioTrack?.publication !== publication
    ) {
      this.audioTrack = new TwilioTrack(publication)
    }
  }

  /**
   *
   * @param {Twilio.RemoteTrackPublication} publication
   */
  @action.bound
  receiveRemotePublication(publication) {
    if (
      publication.kind === 'video' &&
      this.videoTrack?.publication !== publication
    ) {
      this.videoTrack = new TwilioTrack(publication)
    }

    if (
      publication.kind === 'audio' &&
      this.audioTrack?.publication !== publication
    ) {
      this.audioTrack = new TwilioTrack(publication)
    }
  }

  /**
   *
   * @param {Twilio.RemoteTrackPublication|Twilio.LocalTrackPublication} publication
   */
  @action.bound
  removeTrackPublication(publication) {
    if (publication.kind === 'video') {
      this.videoTrack = null
    }

    if (publication.kind === 'audio') {
      this.audioTrack = null
    }
  }
}

class TwilioTrack {
  /**
   * @type {Twilio.Track|null}
   */
  @observable.ref track = null

  /**
   *
   * @param {Twilio.LocalTrackPublication|Twilio.RemoteTrackPublication} publication
   */
  constructor(publication) {
    this.publication = publication
    this.setTrack(publication.track)
    publication.on('subscribed', (track) => this.setTrack(track))
    publication.on('unsubscribed', (track) => {
      if (this.track === track) {
        this.setTrack(null)
      }
    })
  }

  /**
   * @param {Twilio.Track|null} track
   */
  @action.bound
  setTrack(track) {
    this.track = track
  }
}
