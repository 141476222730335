import { observable, action } from 'mobx'

export default class HandoffDialogViewModel {
  @observable
  showing = false

  constructor({ rootStore }) {
    this.rootStore = rootStore
  }

  @action
  tryToShowDialog() {
    // Only show the dialog if magic links are enabled in the workspace.
    const workspaceConfig = this.rootStore.sessionStore?.workspace.config || {}
    const magicLinkEnabled =
      workspaceConfig.public_api_config?.signed_url_enabled &&
      workspaceConfig.external_customers_config?.enabled
    if (!magicLinkEnabled) {
      return
    }

    this.showing = true
  }

  @action
  close() {
    this.showing = false
  }
}
