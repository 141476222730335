import { Store } from 'libx'
import { computed } from 'mobx'
import identity from 'lodash/identity'

const STEP_COUNT = 3

export default class GettingStartedStore extends Store {
  @computed
  get progressMax() {
    return STEP_COUNT
  }

  @computed
  get progress() {
    const job = this.job
    if (!job) {
      return 1
    }

    if (!job.hasAnsweredAllQuestions) {
      // Each answer counts towards visible progress
      return 1 + job.answers.length / job.questions.length
    }

    if (job.states.length === 0) {
      return 2
    }

    return 3
  }

  @computed
  get job() {
    return this.rootStore.projectStore.projects.find(
      (x) => x.status === 'UNDER_CONSTRUCTION'
    )
  }

  @computed
  get hasAnsweredQuestions() {
    const job = this.job
    if (!job) {
      return false
    }

    return [
      job.years.length > 0,
      job.states.length > 0,
      job.hasAnsweredAllQuestions,
    ].every(identity)
  }

  @computed
  get accountEmail() {
    return this.rootStore.authStore.user.email
  }

  @computed
  get buttonText() {
    const progress = this.progress
    if (progress === 1) {
      return 'Get Started'
    }

    if (!this.hasAnsweredQuestions) {
      return 'Continue'
    }

    return 'Go to Checkout'
  }

  @computed
  get shouldShow() {
    const { projectStore, authStore } = this.rootStore
    if (authStore.user.impersonated) {
      return false
    }

    if (projectStore.projects.length === 0) {
      return true
    }

    // if (projectStore.projects.length === 1) {
    //   const firstProject = projectStore.projects.map(x => x)[0]
    //   return firstProject.status === 'UNDER_CONSTRUCTION'
    // }

    return false
  }
}
