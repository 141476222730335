import MobileDetect from 'mobile-detect'

const md = new MobileDetect(window.navigator.userAgent)

export function isIPhoneSafari() {
  return md.is('iOS') && md.is('iPhone')
}

export function isAndroid() {
  return md.is('AndroidOS')
}

export function isMobile() {
  return isIPhoneSafari() || isAndroid()
}
