import { action, observable, computed } from 'mobx'
import memoize from 'memoizee'
import { asapScheduler, NEVER, Subject } from 'rxjs'
import { task } from 'mobx-task'
import {
  skip,
  take,
  share,
  switchMap,
  observeOn,
  takeUntil,
  filter,
} from 'rxjs/operators'
import { shareLatest, fromClock } from 'utils/rxUtil'
import { toStream, asAtom } from 'utils/mobxUtil'
import { CallStatus } from 'messaging/calling/Call'
import { CallParticipantStatus } from 'messaging/calling/CallParticipant'
import { ViewMode, CallOperatorState } from 'messaging/calling/constants'
import { orderBy } from 'lodash'
import CallParticipantViewModel from './CallParticipantViewModel'

export class CallViewModel {
  /**
   * Operator state.
   */
  @observable state = CallOperatorState.Inactive

  /**
   * The call view mode.
   */
  @observable viewMode = ViewMode.Hidden

  /**
   * The call source, if any.
   */
  @observable callSource = null

  /**
   * Whether the call is embedded in the app.
   */
  @observable isEmbedded = false

  /**
   * Memoized mapper for participants.
   */
  participantViewModelFor = memoize(
    (callParticipant) => new CallParticipantViewModel(this, callParticipant)
  )

  /**
   * Dispose backing subject.
   * @private
   */
  _disposed = new Subject()

  constructor(call, adapter, sounds, delegates) {
    this.call = call
    this.adapter = adapter
    this.sounds = sounds
    this.delegates = delegates

    this.disposed$ = this._disposed.pipe(
      shareLatest(),
      take(1),
      // Schedule emissions on the microtask queue.
      // Otherwise, if a subscriber calls `dispose`
      // from a subscription of a stream that uses
      // takeUntil(disposed$), the other subscribers will
      // not get notified.
      observeOn(asapScheduler)
    )

    /**
     * State as a stream.
     */
    this.state$ = toStream(() => this.state, true).pipe(
      takeUntil(this.disposed$),
      share()
    )

    /**
     * Like `state$` but only emits when it changes.
     */
    this.stateChange$ = this.state$.pipe(skip(1), share())

    /**
     * Emits when the call is completed
     */
    this.completed$ = this.call.completed$.pipe(
      takeUntil(this.disposed$),
      share()
    )

    /**
     * Streams as observables/atoms.
     *
     * @private
     */
    this.streams$ = {
      now: asAtom(
        fromClock(this.adapter.getNow).pipe(takeUntil(this.disposed$)),
        this.adapter.getNow()
      ),
    }

    // If we are not in Inactive state, when the call is completed, try to disconnect.
    this.state$
      .pipe(
        switchMap((state) =>
          state === CallOperatorState.Inactive ? NEVER : this.completed$
        ),
        takeUntil(this.disposed$)
      )
      .subscribe(this.tryDisconnect)

    this.adapter.hangupSignal$
      .pipe(
        filter(
          () =>
            this.state === CallOperatorState.Connecting ||
            this.state === CallOperatorState.Connected
        )
      )
      .subscribe(this.hangup)

    this.adapter.mediaSettingsPorts.devicePreferencesChanges$
      .pipe(
        filter(
          () =>
            this.state === CallOperatorState.Connecting ||
            this.state === CallOperatorState.Connected
        )
      )
      .subscribe(this.switchDevice)

    this.adapter.mediaSettingsPorts.requestDevices()
  }

  /**
   * Participant view models.
   */
  @computed
  get participants() {
    return this.call.participants.map(this.participantViewModelFor)
  }

  /**
   * Participant view model for the current user participant, if any.
   */
  @computed
  get currentUserParticipant() {
    return this.call.currentUserParticipant
      ? this.participantViewModelFor(this.call.currentUserParticipant)
      : null
  }

  /**
   * Participants that are connected, excluding the current user.
   */
  @computed
  get otherUserParticipants() {
    return this.call.participants
      .filter((p) => p !== this.call.currentUserParticipant)
      .map(this.participantViewModelFor)
  }

  @computed
  get connectedParticipants() {
    return this.otherUserParticipants.filter(
      (p) => p.participant.status === CallParticipantStatus.Connected
    )
  }

  @computed
  get participantsWithAudio() {
    return this.connectedParticipants.filter((p) => !p.media?.muted)
  }

  @computed
  get participantsWithVideo() {
    return this.connectedParticipants.filter((p) => p.media?.camera)
  }

  /**
   * The VM for the participant that is the center of attention.
   * 
   * From the connected participants (including the current user)
   * - If one participant => use self
   * - If two => use the other participant
   * - If 3+ => 
      - If there's people with audio => use the loudest speaker
   *  - If there's people with video => use the first person with video
      - If no audio and no video => the first participant 
   *    
   */
  @computed
  get centerOfAttention() {
    // Obama's elf
    if (
      this.call.currentUserParticipant &&
      this.connectedParticipants.length === 0
    ) {
      return this.participantViewModelFor(this.call.currentUserParticipant)
    }

    // 1 on 1 call
    if (this.connectedParticipants.length === 1) {
      return this.connectedParticipants[0]
    }

    // 3+ call
    // Use loudest
    if (this.participantsWithAudio.length > 0) {
      const byLoudness = orderBy(this.participantsWithAudio, [
        (p) => p.media?.getAudioLoudness(),
      ])

      return byLoudness[0]
    }

    // // Or whoever has video
    if (this.participantsWithVideo.length > 0) {
      return this.participantsWithVideo[0]
    }

    // Or just anyone
    return this.connectedParticipants[0]
  }

  /**
   * Duration in milliseconds.
   */
  @computed
  get duration() {
    const relative = this.call.dateCompleted ?? this.streams$.now()
    const absolute = relative.valueOf() - this.call.dateCreated.valueOf()
    // Round to the nearest second.
    return absolute - (absolute % 1000)
  }

  @computed
  get canEnableCamera() {
    return this.adapter.canHaveOutgoingVideo()
  }

  /**
   * Navigates the user to the call context (e.g. the Job
   * for the containing conversation)
   */
  @action.bound
  async revealCallContext() {
    this.adapter.showCallContext(this.call)
    this.show()
  }

  /**
   * Fetches the call info.
   */
  fetch = task(async () => {
    await this.adapter.fetch(this.call)
  })

  /**
   * Shows the call details.
   */
  @action.bound
  show() {
    if (this.isEmbedded) {
      this.viewMode = ViewMode.Visible
    }
  }

  /**
   * Hides the call details.
   */
  @action.bound
  hide() {
    this.viewMode = ViewMode.Hidden
  }

  /**
   * Join the call.
   */
  join = task.resolved(async () => {
    const descriptor = await this.adapter.join(this.call)
    this.revealCallContext()
    return this.connect(descriptor)
  })

  /**
   * Connects using the specified descriptor.
   * This will call the delegate which later calls `connectToSource`.
   *
   * @param {callDescriptor} CallDescriptor
   */
  connect = task.resolved(async (callDescriptor) => {
    this.setOperatorState(CallOperatorState.Connecting)
    return this.delegates.connect(callDescriptor)
  })

  /**
   * Decline the call.
   */
  decline = task.resolved(async () => {
    this.call.maybeMarkCompleted()
    this.setOperatorState(CallOperatorState.Inactive)
    return this.adapter.decline(this.call)
  })

  /**
   * Hang up.
   */
  hangup = task.resolved(async () => {
    await this.tryDisconnect()
    this.setOperatorState(CallOperatorState.Inactive)
    return this.adapter.hangup(this.call)
  })

  /**
   * Connect to the call source. This is called by the
   * parent of this VM.
   *
   * @param {CallConnectionDescriptorDto} connectionDescriptor
   * @param {CancellationToken} ct
   */
  connectToSource = task.resolved(async (connectionDescriptor, ct) => {
    const callSource = await this.adapter.acquireCallSource(
      this,
      connectionDescriptor,
      ct
    )

    const micDeviceId = this.preferredMicrophone?.deviceId

    this.setCallSource(callSource)
    await callSource.connect(ct, micDeviceId)
    this.setOperatorState(CallOperatorState.Connected)
  })

  /**
   * Disconnects from the call source.
   */
  disconnect = task.resolved(async () => {
    if (this.state === CallOperatorState.Inactive) {
      return
    }

    console.log('Disconnect!')
    await this.callSource?.disconnect()
    this.clearCallSource()
    this.setOperatorState(CallOperatorState.Inactive)
  })

  @computed
  get preferredCamera() {
    return this.adapter.mediaSettingsPorts.preferredVideoDevice()
  }

  @computed
  get preferredMicrophone() {
    return this.adapter.mediaSettingsPorts.preferredAudioInputDevice()
  }

  @action.bound
  async switchDevice(newDevice) {
    if (newDevice.kind === 'videoIn') {
      await this.toggleCamera()
      await this.toggleCamera()
    }

    if (newDevice.kind === 'audioIn') {
      await this.toggleMicrophone()
      await this.toggleMicrophone()
    }
  }

  @action.bound
  openMediaSettings() {
    this.adapter.mediaSettingsDialogVM.show()
  }

  /**
   * Toggles the microphone.
   */
  toggleCamera = task.resolved(async () => {
    const callSource = this.callSource
    const currentUserParticipantMedia = this.currentUserParticipant?.media
    if (!currentUserParticipantMedia || !callSource) {
      return
    }

    if (!currentUserParticipantMedia.camera) {
      await callSource.turnOnCamera(this.preferredCamera?.deviceId)
    } else {
      await callSource.turnOffCamera()
    }
  })

  /**
   * Toggles the camera.
   */
  toggleMicrophone = task.resolved(async () => {
    const callSource = this.callSource
    const currentUserParticipantMedia = this.currentUserParticipant?.media
    if (!currentUserParticipantMedia || !callSource) {
      return
    }

    if (!currentUserParticipantMedia.muted) {
      await callSource.turnOffMic()
    } else {
      await callSource.turnOnMic(this.preferredMicrophone?.deviceId)
    }
  })

  /**
   * Called (no pun intended) when the call is being connected to.
   * Do not call from the UI.
   */
  @action.bound
  reportConnecting() {
    console.log('Connecting', this.call)
    this.setOperatorState(CallOperatorState.Connecting)
  }

  /**
   * Called (no pun intended) when the call is incoming.
   * Do not call from the UI.
   */
  @action.bound
  reportIncoming() {
    this.setOperatorState(CallOperatorState.Incoming)
  }

  /**
   * Called when the VM is disposed and not to be used again.
   */
  dispose() {
    this._disposed.next()
  }

  /**
   * Tries to disconnect. Called reactively when the call is ended.
   */
  tryDisconnect = async () => {
    if (this.state === CallOperatorState.Connected) {
      return this.disconnect()
    }

    this.setOperatorState(CallOperatorState.Inactive)
  }

  /**
   * Sets the operator state.
   *
   * @private
   * @param {CallOperatorState} state
   */
  @action.bound
  setOperatorState(state) {
    if (this.call.callStatus === CallStatus.Completed) {
      // We cannot set the operator state to something indicating an active call
      // when the call has ended.
      this.state = CallOperatorState.Inactive
    } else {
      this.state = state
    }
  }

  /**
   * Sets the call source.
   *
   * @param callSource
   * @private
   */
  @action.bound
  setCallSource(callSource) {
    if (callSource.type === 'Twilio') {
      this.isEmbedded = true
    }
    this.callSource = callSource
  }

  /**
   * Clears the call source.
   *
   * @private
   */
  @action.bound
  clearCallSource() {
    if (!this.callSource) {
      return
    }
    this.callSource.dispose()
    this.callSource = null
  }
}
