import { Store } from 'libx'
import { computed } from 'mobx'
import { task } from 'mobx-task'

export default class DocumentsScreenStore extends Store {
  @computed
  get documents() {
    return this.rootStore.documentStore.forUser()
  }

  @task
  async activate() {
    await this.rootStore.documentStore.userDocumentsScrollProvider.fetch()
  }

  @task
  upload(files) {
    return this.rootStore.documentStore.newDocument(Array.from(files), {
      workspaceId: this.rootStore.sessionStore.workspace.id,
    })
  }
}
