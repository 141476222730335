import TeamBillingViewState from '@taxfyle/web-commons/lib/iam/TeamBillingViewState'
import sample from 'lodash/sample'
import get from 'lodash/get'
import { action, computed, observable, reaction, runInAction } from 'mobx'
import { task } from 'mobx-task'
import { extractMessageFromError } from 'utils/errorUtil'

import CouponPickerState from './Boxes/CouponPicker/CouponPickerState'
import JobNameEditor from './Boxes/JobNameEditor/JobNameEditorState'

import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import { getFeatureToggleClient } from 'misc/featureToggles'

const testimonials = [
  {
    quote:
      'Kurt F. was great to work with and we will be using Taxfyle again for our 2017 taxes.',
    username: 'Matthew S',
  },
  {
    quote: 'Fantastic service and he saved me $1000! Thanks so much. ',
    username: 'Mayuka K',
  },
  {
    quote:
      "The experience was quick and easy!  I'll do it again next year! John was great!",
    username: 'Terri M',
  },
  {
    quote:
      "Freddie was fantastic! He was extremely responsive. He thoroughly answered all of my many questions. This process was made so much easier. Thank you Freddie and the taxfyle team for putting my mind at ease! I no longer have to worry if I'm doing my taxes correctly. What an amazing app.",
    username: 'Haley A',
  },
  {
    quote:
      'Gilbert was super helpful and did a great job of explaining everything clearly, thank you!',
    username: 'Jennifer A',
  },
  {
    quote:
      'To the point advice, and directions. Very responsive and courteous. Definitely would recommend him.',
    username: 'Ruben A',
  },
  {
    quote:
      "Joe knows what he's doing, very efficient service and overall experience! I will be coming back for future returns.",
    username: 'Jason T',
  },
  { quote: 'Thank you for the great turnaround time!', username: 'Vastal P' },
  {
    quote:
      'This was my first time filing for a business.  Stewart was excellent with knowledge and wonderful with patience as he helped guide me through.  Thank you very much Taxfyle.',
    username: 'Gordon R',
  },
]

export default class FeeBreakdownState {
  stepId = 'FeeBreakdown'
  title = T('Web.Onboarding.Review.Header', 'Review and confirm your request')

  trackingId = 'Onboarding - Fee Breakdown'

  @observable
  view = 'breakdown'

  @observable
  selectedCouponId = null

  @observable
  showingCouponField = true

  @observable
  hasAcceptedTerms = false

  @observable
  latestTermsOfUseAccepted = false

  @observable
  discountAmount = 0

  @observable
  currentTestimonial = testimonials[0]

  @observable
  primaryAmount = 0

  @observable
  amountDueNow = 0

  @observable
  orderTotal = 0

  @observable
  jobScopeItemNames = []

  constructor({ wizard }) {
    this.wizard = wizard
    this.termsDialogVM = wizard.rootStore.termsDialogVM
    this.couponPicker = new CouponPickerState(wizard.rootStore.userCouponStore)
    this.jobNameEditor = new JobNameEditor(wizard)
    this.authStore = wizard.rootStore.authStore
    this.userCouponStore = wizard.rootStore.userCouponStore
    this.projectStore = wizard.rootStore.projectStore
    this.billingStore = wizard.rootStore.billingStore
    this.billingStore = wizard.rootStore.billingStore
    this.sessionStore = wizard.rootStore.sessionStore
    this.memberStore = wizard.rootStore.memberStore
    this.billingViewState = new TeamBillingViewState({
      billingStore: wizard.rootStore.billingStore,
      team: undefined,
      allowEdit: true,
      flashMessageStore: wizard.rootStore.flashMessageStore,
    })
  }

  // TODO: Once all wizard steps are migrated fully to new grcp endpoints, this can be
  // removed and refactored in the ProjectWizardStore.js flow.
  get skipSyncJob() {
    return true
  }

  @computed
  get skipBilling() {
    return !this.config.billingEnabled || !!this.wizard.job.legend.skipBilling
  }

  @computed
  get isNothingDueNow() {
    return this.amountDueNow === 0
  }

  @computed
  get shouldAskForPaymentInfo() {
    if (this.skipBilling) {
      return false
    }

    return !this.wizard.job.legend.billing_config?.defer_payment_info_collection
  }

  @computed
  get busy() {
    return (
      // TODO: This `syncJob` can be removed after all job saving actions have been
      // migrated to dotnet endpoints.
      this.wizard.syncJob.pending ||
      this.selectPaymentMethod.pending ||
      this.calculatePrice.pending ||
      this.submitJob.pending ||
      this.billingStore.busy ||
      this.applyCoupon.pending
    )
  }

  @computed
  get skipQuote() {
    return (
      (this.skipBilling && this.wizard.job.legend.skipBilling) ||
      !this.canViewPricing
    )
  }

  @computed
  get amountDueNowEqualsTotal() {
    return this.amountDueNow === this.orderTotal
  }

  @computed
  get config() {
    const workspaceConfig = this.sessionStore.workspace.config
    const billingConfig = workspaceConfig.billing || { enabled: false }
    const chiliPiperConciergeConfig =
      workspaceConfig.chili_piper_concierge_config || {
        enabled_at_checkout: false,
        checkout_router_id: '',
        checkout_form_descriptor: '',
      }

    return {
      billingEnabled: billingConfig.enabled,
      couponsEnabled: billingConfig['coupons.enabled'],
      chiliPiperScheduleACallEnabled:
        chiliPiperConciergeConfig.enabled_at_checkout,
      chiliPiperRouterId: chiliPiperConciergeConfig.checkout_router_id,
      chiliPiperFormDescriptor:
        chiliPiperConciergeConfig.checkout_form_descriptor,
    }
  }

  @computed
  get jobIsSubmitting() {
    return this.submitJob.pending
  }

  @computed
  get couponToUse() {
    return (
      (this.wizard.job &&
        this.wizard.job.couponIds.length > 0 &&
        this.wizard.job.couponIds[0]) ||
      null
    )
  }

  @computed
  get orderLines() {
    if (!this.legend) {
      return []
    }

    return [{ product: 'Service Amount', price: this.primaryAmount }].filter(
      (x) => x !== false
    )
  }

  @computed
  get questions() {
    const questionsStep = this.wizard.getStep('Questions')
    return questionsStep.questions
  }

  @computed
  get answers() {
    const questionsStep = this.wizard.getStep('Questions')
    return questionsStep.answers.slice()
  }

  @computed
  get breakdownTitle() {
    const typeStep = this.wizard.getStep('ProjectType')
    if (!typeStep.legend) {
      return 'Job Order'
    }

    return typeStep.legend.name
  }

  @computed
  get showAvailabilityCount() {
    const step = this.wizard.getStep('SelectPro')
    if (step && (step.selectedPro === 'any' || step.selectedPro === '')) {
      return this.wizard.job.legend.showAvailabilityCount
    }
    return false
  }

  @computed
  get orderLinesDisplay() {
    return [...this.orderLines].filter((x) => x !== false)
  }

  @computed
  get legend() {
    const typeStep = this.wizard.getStep('ProjectType')
    return this.wizard.job.legend || (typeStep ? typeStep.legend : null)
  }

  @computed
  get canViewPricing() {
    const job = this.wizard.job
    if (!job) return false
    const team = job.ownerTeam
    if (!team) {
      return this.wizard.rootStore.sessionStore.member.hasPermission(
        'VIEW_PRICING'
      )
    } else {
      const teamMember = this.wizard.rootStore.teamMemberStore.forTeamAndUser(
        team.id,
        this.wizard.rootStore.sessionStore.member.userId
      )
      if (!teamMember) return false
      return teamMember.hasPermission('VIEW_PRICING')
    }
  }

  @computed
  get canManageBilling() {
    const job = this.wizard.job
    if (!job) return false
    const team = job.ownerTeam
    if (!team) return true // Non-team job, user can add own billing method.

    const teamMember = this.wizard.rootStore.teamMemberStore.forTeamAndUser(
      team.id,
      this.wizard.rootStore.sessionStore.member.userId
    )
    if (!teamMember) return false
    return teamMember.hasPermission('MANAGE_TEAM_BILLING')
  }

  @action
  setView(view) {
    this.view = view
  }

  showFeeBreakdown() {
    this.setView('breakdown')
  }

  showPaymentMethods() {
    this.setView('payment')
  }

  serializeToJob() {
    return {
      coupons_used: [
        this.couponPicker.selectedCoupon && this.couponPicker.selectedCoupon.id,
      ].filter(Boolean),
    }
  }

  @action
  toggleCouponField() {
    this.showingCouponField = !this.showingCouponField
  }

  @computed
  get customTermsOfUseEnabled() {
    return this.wizard.rootStore.sessionStore.workspace.features.tos
      .customTermsEnabled
  }

  @computed
  get shouldPromptForTerms() {
    const tos = this.wizard.rootStore.sessionStore.workspace.config.tos
    const newTermsEnabled = getFeatureToggleClient().variation(
      'Portals.UseNewTerms',
      false
    )
    return newTermsEnabled ? !tos?.hideOnCustomerPortal : tos?.enabled
  }

  @action
  async activate() {
    const startTime = performance.now()
    this.discountAmount = 0
    this.primaryAmount = 0
    this.orderTotal = 0
    this.amountDueNow = 0
    this.jobScopeItemNames = []
    this.fetchBillingMethods()
    await this.couponPicker.initialize(this.couponToUse)
    // TODO: Remove once fully migrate to v3 endpoints
    await this._syncJob()

    // We need to calculate price amounts the first time to know if we should show
    // UI signaling we'll only collect payment info but not charge anything right now
    await this.calculatePrice()

    await this.termsDialogVM.activate()

    const reactions = [
      reaction(
        () => [this.legend, this.couponToUse],
        this.calculatePrice.bind(this)
      ),
      reaction(
        () => this.couponPicker.selectedCoupon,
        this.applyCoupon.bind(this)
      ),
    ]

    this.destroyReactions = () => reactions.forEach((x) => x())

    this.nextTestimonial()
    this.testimonialTimer = setInterval(this.nextTestimonial.bind(this), 7000)

    const latency = performance.now() - startTime
    this.recordFeeBreakdownLatency(latency)
  }

  @action
  nextTestimonial() {
    this.currentTestimonial = sample(testimonials)
  }

  @action
  deactivate() {
    if (this.destroyReactions) {
      this.destroyReactions()
      delete this.destroyReactions
    }

    if (this.testimonialTimer) {
      clearInterval(this.testimonialTimer)
    }

    this.closeCheckout()
  }

  @task.resolved
  async applyCoupon(coupon) {
    const couponId = get(coupon, 'id', null)

    if (couponId === this.couponToUse) {
      return
    }

    if (couponId) {
      await this.wizard.rootStore.draftStore
        .applyCoupon(this.wizard.job.id, couponId)
        .catch(() => this.couponPicker.selectCoupon(null))
    } else {
      await this.wizard.rootStore.draftStore.removeCoupon(this.wizard.job.id)
    }

    await this.projectStore.fetch(this.wizard.job.id)
  }

  @task
  async calculatePrice() {
    if (!this.canViewPricing) {
      return
    }

    if (this.wizard.job.status !== 'UNDER_CONSTRUCTION') {
      return
    }

    const result = await this.projectStore.calculatePrice(this.wizard.job.id)

    if (!result) {
      return
    }

    runInAction(() => {
      this.discountAmount = result.coupon_amount
      this.primaryAmount = result.primary_amount
      this.orderTotal = result.total
      this.amountDueNow = result.amount_due_now
      this.jobScopeItemNames = result.job_scope_item_names
    })
  }

  async ensureTermsAccepted() {
    if (!this.shouldPromptForTerms) {
      return true
    }

    const workspaceId = this.customTermsOfUseEnabled
      ? this.sessionStore.workspace.id
      : null

    const latestTermsOfUseAccepted =
      await this.wizard.rootStore.memberStore.checkTermsOfUseAcceptance(
        workspaceId
      )

    if (latestTermsOfUseAccepted) {
      return true
    }

    return this.termsDialogVM.ensureAccepted()
  }

  @task
  async submit() {
    if (this.skipBilling || !this.shouldAskForPaymentInfo) {
      return this.submitJob()
    }
    const termsAccepted = await this.ensureTermsAccepted()
    if (!termsAccepted) {
      return
    }

    await this.fetchBillingMethods()
    const profile = this.wizard.job.ownerTeam
      ? this.billingStore.teamBillingProfile(this.wizard.job.ownerTeam.id)
      : this.billingStore.individualBillingProfile
    if (profile.billingMethods.length === 0) {
      if (this.canManageBilling) {
        const billingMethod = await this.addBillingMethod(this.billingViewState)
        if (!this.isNothingDueNow) {
          if (billingMethod) {
            await this.submitJob(billingMethod.id)
          }
        }
      } else {
        this.wizard.rootStore.flashMessageStore.create({
          type: 'error',
          message:
            'Billing has not been configured for this team, please contact your team administrator.',
        })
      }
      return
    } else if (this.canManageBilling) {
      return this.showPaymentMethods()
    }

    if (this.isNothingDueNow) {
      return this.submitJob()
    }

    const defaultMethod = profile.billingMethods.find((x) => x.default)
    this.submitJob(defaultMethod && defaultMethod.id)
  }

  @task
  async submitDefault() {
    const profile = this.wizard.job.ownerTeam
      ? this.billingStore.teamBillingProfile(this.wizard.job.ownerTeam.id)
      : this.billingStore.individualBillingProfile

    const defaultMethod = profile.billingMethods.find((x) => x.default)
    if (defaultMethod) {
      this.submitJob(defaultMethod && defaultMethod.id)
    } else {
      this.wizard.rootStore.flashMessageStore.create({
        type: 'error',
        message: 'Create or select a payment method.',
      })
    }
  }

  async addBillingMethod(billingViewState) {
    if (
      billingViewState &&
      billingViewState.team &&
      this.sessionStore.workspace.features.billing.teamsCredit.enabled
    ) {
      this.showPaymentMethods()
      billingViewState.showCreateBillingModal()
    } else {
      const config = {
        team: this.wizard.job.ownerTeam,
        ...(!this.isNothingDueNow && {
          currency: 'USD',
          name: this.sessionStore.workspace.name,
          description: this.wizard.job.name,
          email: this.wizard.rootStore.authStore.user.email,
          amount: (this.amountDueNow || this.orderTotal) * 100,
        }),
      }

      const paymentMethod = await this.billingStore.showStripeCheckout(config)

      if (this.isNothingDueNow && paymentMethod) {
        this.showPaymentMethods()
      }

      return paymentMethod
    }
  }

  closeCheckout() {
    this.billingStore.closeCheckout()
  }

  @task.resolved
  async selectPaymentMethod(billingMethod) {
    await this.wizard.rootStore.billingStore.changeDefaultBillingMethod(
      billingMethod,
      this.wizard.job.ownerTeam
    )
    // await this.submitJob(billingMethod.id)
  }

  @task
  fetchBillingMethods() {
    const billingStore = this.wizard.rootStore.billingStore
    this.billingViewState.team = this.wizard.job.ownerTeam
    this.billingViewState.creditViewState.team = this.wizard.job.ownerTeam
    return this.wizard.job.ownerTeam
      ? billingStore.fetchBillingProfileForTeam(this.wizard.job.ownerTeam.id)
      : billingStore.fetchBillingProfile()
  }

  @task.resolved
  async calculateAvailablePros() {
    if (
      !this.wizard.job ||
      this.wizard.job.legend.showAvailabilityCount === false
    ) {
      return 0
    }

    const result = await this.projectStore.calculateAvailablePros(
      this.wizard.job.id
    )
    return result.count === 25 ? '25+' : `${result.count}`
  }

  @task.resolved
  async submitJob(billingMethodId) {
    const job = this.wizard.job
    try {
      await this.wizard.rootStore.draftStore.submitDraft(
        job.id,
        billingMethodId
      )
      this.wizard.rootStore.projectStore.fetchProject.delete(this.wizard.job.id)
      const project = await this.wizard.rootStore.projectStore.fetchProject(
        this.wizard.job.id
      )

      this.wizard.rootStore.trackingStore.track('Job Submitted', {
        job_id: project.id,
        workspace_id: project.workspaceId,
        legend_id: project.legend.id,
        total: project.total,
        due_now: this.wizard.currentStep.amountDueNow,
        coupon_amount: project.couponAmount,
      })

      if (process.env.NODE_ENV === 'production') {
        try {
          window.fbq &&
            window.fbq('track', 'Purchase', {
              value: project.total,
              currency: 'USD',
              content_name: project.legend.id,
              content_ids: project.years.filter((x) => x),
              content_type: 'product',
            })
        } catch (err) {
          console.log(err)
        }
        try {
          window.ORIBI &&
            window.ORIBI.api('trackPurchase', {
              totalPrice: project.total,
              currency: 'USD',
              orderId: project.id,
            })
        } catch (err) {
          console.log(err)
        }
        this.wizard.rootStore.trackingStore.setDataLayer('jobSubmitted', {
          jobId: project.id,
          value: project.total,
          jobScopeItems: project.jobScopeItemsNames || [],
          currency: 'USD',
        })

        this.wizard.rootStore.trackingStore.trackRudderAnalytics('checkout', {
          job_id: project.id,
          value: project.total,
          due_now: this.wizard.currentStep.amountDueNow,
          coupon:
            project.couponCodes?.length > 0 ? project.couponCodes?.[0] : null,
          coupon_amount: project.couponAmount > 0 ? project.couponAmount : null,
          currency: 'USD',
          products: project.jobScopeItems || [],
        })
      }
      // reset the custom name edit
      this.wizard.nameUpdatedManually = false
      await this.wizard.next()
    } catch (err) {
      this.wizard.rootStore.flashMessageStore.create({
        message: `An error occurred while submitting your job: ${extractMessageFromError(
          err
        )}`,
        type: 'error',
      })
      throw err
    }
  }

  async _syncJob(force) {
    if (this.skipSyncJob) {
      return
    }

    await this.wizard.syncJob(force)
  }

  recordFeeBreakdownLatency(latency) {
    this.wizard.rootStore.trackingStore.trackRudderAnalytics('view quote', {
      quoted_total: this.orderTotal,
      job_id: this.wizard.job.id,
      latency_ms: latency,
    })
  }
}
