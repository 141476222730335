import { observable, computed, action } from 'mobx'

export default class TeamSelectState {
  stepId = 'TeamSelect'
  title = 'Who would you like to submit a work request for?'
  trackingId = 'Onboarding - Team Selection'

  @observable.ref team = null

  constructor({ wizard }) {
    this.wizard = wizard
    this.rootStore = wizard.rootStore
    this.hideBackButton = true
    this.select = this.select.bind(this)
  }

  @computed
  get relevant() {
    return (
      this.rootStore.teamMemberStore.teamMembersForWorkspaceMemberByPublicId()
        .length > 0 &&
      // We can't switch teams once the job is created.
      !this.wizard.job
    )
  }

  @action.bound
  select(team) {
    this.team = team
    this.wizard.next()
  }

  serializeToJob() {
    return {
      owner_team_id: this.team ? this.team.id : null,
    }
  }

  hydrate(job) {
    if (job.team_id) {
      // All teams should have been fetched at this state.
      this.team = this.rootStore.teamStore.teams.get(job.owner_team_id)
    }
  }
}
