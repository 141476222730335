import { computed, action, observable, reaction } from 'mobx'
import { task } from 'mobx-task'
import { validationContext, func } from 'validx'

/**
 * View Model for rating a job.
 */
export default class RatePlatformDialogViewModel {
  @observable
  showing = false

  @observable
  feedback = ''

  @observable
  rating = 0

  @observable
  thumbsRating = undefined

  @observable
  platformRatingStatus = 'UNKNOWN'

  constructor({
    projectDetailsStore,
    sessionStore,
    jobRatingStore,
    flashMessageStore,
  }) {
    this.projectDetailsStore = projectDetailsStore
    this.sessionStore = sessionStore
    this.jobRatingStore = jobRatingStore
    this.flashMessageStore = flashMessageStore
  }

  validation = validationContext(this, {
    feedback: [
      func(({ value }) => {
        return (
          value.length <= 280 || "Feedback can't be more than 280 characters"
        )
      }),
    ],
  })

  @computed
  get canRatePlatform() {
    return this.platformRatingStatus === 'PENDING' && !!this.proChampion
  }

  @computed
  get proChampion() {
    return this.projectDetailsStore.project?.provider ?? {}
  }

  @computed
  get validRating() {
    return this.rating >= 1 && this.rating <= 5
  }

  @computed
  get iconWhiteLogo() {
    return this.sessionStore.workspace.branding.logos.icon_white
  }

  @action.bound
  show() {
    if (!this.projectDetailsStore.canRatePlatform) {
      return
    }
    this.showing = true
  }

  @action.bound
  close() {
    this.showing = false
    this.reset()
  }

  @action.bound
  activate() {
    this.progressionStatusChangeDisposer = reaction(
      () => this.projectDetailsStore.isInOrPastDraftApproved,
      (_) => {
        if (!this.projectDetailsStore.jobProgressionFlowEnabled) {
          return
        }

        this.projectDetailsStore.fetchJobRatingStatus().then(() => {
          if (
            this.projectDetailsStore.canRatePro ||
            !this.projectDetailsStore.canRatePlatform
          ) {
            return
          }

          this.show()
        })
      },
      {
        fireImmediately: true,
      }
    )

    this.jobStatusChangeDisposer = reaction(
      () =>
        this.projectDetailsStore.project &&
        this.projectDetailsStore.project.status,
      (value) => {
        if (
          this.projectDetailsStore.jobProgressionFlowEnabled ||
          value !== 'CLOSED'
        ) {
          return
        }

        this.projectDetailsStore.fetchJobRatingStatus().then(() => {
          if (
            this.projectDetailsStore.canRatePro ||
            !this.projectDetailsStore.canRatePlatform
          ) {
            return
          }

          this.show()
        })
      },
      {
        fireImmediately: true,
      }
    )
  }

  @action.bound
  deactivate() {
    this.progressionStatusChangeDisposer?.()
    this.jobStatusChangeDisposer?.()
  }

  @action.bound
  setRating(value) {
    this.rating = value
  }

  @action.bound
  setThumbsRating(value) {
    this.thumbsRating = value
    this.rating = this.thumbsRating ? 4 : 2
  }

  @action.bound
  setPlatformRatingStatus(status) {
    this.platformRatingStatus = status
  }

  @action.bound
  reset() {
    this.rating = 0
    this.feedback = ''
    this.thumbsRating = undefined
    this.validation.reset()
  }

  submitRating = task.resolved(async () => {
    if (!this.validRating || !this.validation.reset().validate().isValid) {
      return
    }

    const msg = this.flashMessageStore.create({
      message: 'Submitting...',
      inProgress: true,
    })

    try {
      await this.jobRatingStore.submitPlatformRating(
        this.projectDetailsStore.project.id,
        this.rating,
        this.feedback,
        this.proChampion.userPublicId
      )
      msg.done('Thank you for rating the job!').autoDismiss(4000)
      this.close()
      this.setPlatformRatingStatus('RATED')
    } catch (error) {
      console.error('Error submitting work rating', error)
      msg.failed('Error submitting work rating. Please try again.')
    }

    this.close()
  })
}
