import React, { useEffect } from 'react'

import styles from './styles.m.sass'
import { observer } from 'mobx-react'
import EmailConfirmationScreen from '../components/EmailConfirmationScreen/EmailConfirmationScreen'

export default observer(function EmailConfirmationPage({ vm }) {
  const qs = new URLSearchParams(location.search)

  const token = qs.get('session_token') ?? ''

  useEffect(() => {
    vm.activate(token)

    return () => vm.deactivate()
  }, [vm])

  return (
    <EmailConfirmationScreen>
      <div className={styles.title}>
        {!vm.pageExpired ? (
          <>Check your inbox and verify your email address</>
        ) : (
          <>This page has expired</>
        )}
      </div>
      <div className={styles.description}>
        {!vm.pageExpired ? (
          <>
            We've sent a confirmation link to{' '}
            <b>{vm.email ?? 'EMAIL_NOT_SPECIFIED'}</b>.
          </>
        ) : (
          <>Please log in again.</>
        )}
      </div>
      {!vm.pageExpired && (
        <div className={styles.help}>
          <div className={styles.helpTitle}>Didn't receive the email?</div>
          <p>
            Check your spam folder or{' '}
            <b>
              <a
                className={styles.resendEmail}
                onClick={vm.resendVerificationEmail}
              >
                send the email again.
              </a>
            </b>
          </p>
        </div>
      )}
    </EmailConfirmationScreen>
  )
})
