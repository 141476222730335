import { Store } from 'libx'
import { observable, action, computed } from 'mobx'
import { task } from 'mobx-task'
import { validationContext, func } from 'validx'

export default class ScopeChangeRequestResponseConfirmationDialogStore extends Store {
  @observable showing = false
  @observable scopeChangeRequest = null
  @observable confirmationType = null
  @observable reason = ''
  @observable customReason = ''
  @observable validationText = ''

  validation = validationContext()

  maxTextLength = 280
  rules = {
    customReason: [
      func(
        (r) => r.value?.length > 0,
        'You must provide a reason for declining.'
      ),
      func(
        (r) => r.value?.length <= this.maxTextLength,
        `Your answer must be less than ${this.maxTextLength} characters in length.`
      ),
    ],
  }

  reasonOptions = [
    {
      id: 'expensive',
      label: "It's too expensive",
    },
    {
      id: 'alternative',
      label: 'I found an alternative',
    },
    {
      id: 'confusing',
      label: "I don't know why this is needed",
    },
    {
      id: 'other',
      label: 'Other',
    },
  ]

  constructor() {
    super(...arguments)
    this.decline = this.decline.bind(this)
    this.back = this.back.bind(this)
  }

  @computed
  get isValid() {
    const reasonOk = this.reason !== ''
    const validationOk = this.reason !== 'other' || this.validation.isValid

    return reasonOk && validationOk
  }

  @action.bound
  reset() {
    this.scopeChangeRequest = null
    this.confirmationType = null
    this.reason = ''
    this.customReason = ''
    this.validationText = ''
  }

  @action.bound
  show(scopeChangeRequest, confirmationType) {
    this.validation.reset()
    this.reset()
    this.setConfirmationType(confirmationType)
    this.scopeChangeRequest = scopeChangeRequest

    if (this.confirmationType != null) {
      this.validationText = this.validation.validate(
        this,
        this.rules
      ).errors?.customReason
      this.showing = true
    }
  }

  @action.bound
  setConfirmationType(confirmationType) {
    switch (confirmationType) {
      case 'accept':
        this.confirmationType = 'accept'
        break
      case 'decline':
        this.confirmationType = 'decline'
        break
      default:
        this.confirmationType = null
    }
  }

  @action.bound
  setReason(reason) {
    this.reason = reason
  }

  @action.bound
  setCustomReason(customReason) {
    this.validation.reset()
    this.customReason = customReason
    this.validationText = this.validation.validate(
      this,
      this.rules
    ).errors?.customReason
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @task.resolved
  async decline() {
    if (!this.isValid) {
      return
    }

    const reasonText =
      this.reason === 'other'
        ? this.customReason
        : this.reasonOptions.find((option) => option.id === this.reason)?.label

    await this.rootStore.scopeChangeRequestStore.rejectScopeChangeRequest(
      this.scopeChangeRequest.jobId,
      reasonText
    )
    this.hide()
  }

  back() {
    this.hide()
    this.rootStore.scopeChangeRequestResponseDialogStore.show(
      this.scopeChangeRequest
    )
  }
}
