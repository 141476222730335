import axios from 'axios'
import env from './../../misc/env'

/**
 * API for Email Confirmation.
 */
export class EmailConfirmationAPI {
  #client
  #token

  constructor(token) {
    this.#token = token
    this.#client = axios.create({
      baseURL: env.IAM_API.startsWith('/')
        ? `${window.location.origin}${env.IAM_API}`
        : env.IAM_API,
    })

    this.#client.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${this.#token}`
      return config
    })
  }

  /**
   * Queries for the verification status of a user.
   */
  getVerificationStatus() {
    return this.#client
      .get('/v1/email-verification/status')
      .then((res) => res.data.verified)
  }

  /**
   * Resends verification emails.
   */
  async resendEmail() {
    await this.#client.post('/v1/email-verification/resend')
  }
}
