import React from 'react'
import styles from './styles.m.sass'
import EmailConfirmationScreen from '../components/EmailConfirmationScreen/EmailConfirmationScreen'
import Button from 'components/Button'
import { browserHistory } from 'react-router'

export default function EmailConfirmedPage(p) {
  return (
    <EmailConfirmationScreen>
      <div className={styles.title}>You're all set!</div>
      <div className={styles.description}>
        Great news! You have confirmed your email and can now sign in to the
        platform.
      </div>
      <div className={styles.signInButton}>
        <Button
          size="cta"
          block
          type="primary"
          onClick={() => browserHistory.push('/login')}
        >
          Sign In
        </Button>
      </div>
    </EmailConfirmationScreen>
  )
}
