import { computed, observable } from 'mobx'

/**
 * View model for a section in the checklist.
 */
export class SectionViewModel {
  /**
   * The underlying section.
   *
   * @type {import("domain/info-gathering/Checklist").ChecklistSection}
   */
  @observable section

  constructor({ section, getItemViewModel }) {
    this.section = section
    this.getItemViewModel = getItemViewModel
  }

  /**
   * Gets the item view models for the current section.
   */
  @computed
  get items() {
    return this.section.requestedSupportingItems.map(this.getItemViewModel)
  }

  /**
   * Activate the view model.
   */
  activate() {}

  /**
   * Deactivate the view model.
   */
  deactivate() {}
}
