import React from 'react'
import FlashMessages from 'flash-messages/components/FlashMessages'
import ChangeEmailDialog from 'components/ChangeEmailDialog'
import { observer } from 'mobx-react'
import { HeaderRenderer } from 'components/Header'
import UpdateJobDialog from 'components/UpdateJobDialog'
import UpdateDeadlineDialog from 'components/UpdateDeadlineDialog'
import TermsDialog from 'components/TermsDialog'
import HandoffDialog from 'components/HandoffDialog'
import WorkspaceGateway from 'iam/containers/WorkspaceGateway'
import ConfirmDialog from 'components/ConfirmDialog'
import {
  CallingConversationContext,
  CallRootContext,
} from 'messaging/calling/CallingConversationViewModel/index'
import CallPromptsHost from 'screens/ProjectDetails/Calling/views/CallPromptsHost'
import MediaSettingsDialog from 'src/client/components/MediaSettingsDialog/index'

@observer
export default class App extends React.Component {
  render() {
    const { rootStore } = this.props

    return (
      <WorkspaceGateway rootStore={rootStore}>
        {() => (
          <CallRootContext.Provider value={rootStore.callRootViewModel}>
            <div id="root">
              <HeaderRenderer rootStore={rootStore} />
              {this.props.children}
              <FlashMessages
                store={rootStore.flashMessageStore}
                position="bottomLeft"
              />
              <ChangeEmailDialog store={rootStore.changeEmailDialogStore} />
              {/* <ConfirmDialog
              state={rootStore.billingStore.deleteBillingMethodDialog}
              danger
              yes="Delete Payment Method"
            >
              Are you sure you want to delete this payment method?
            </ConfirmDialog> */}
              <TermsDialog vm={rootStore.termsDialogVM} />
              <HandoffDialog
                vm={rootStore.handoffDialogVM}
                workspace={rootStore.sessionStore.workspace}
              />
              <UpdateJobDialog store={rootStore.updateJobDialogStore} />
              <UpdateDeadlineDialog
                store={rootStore.updateDeadlineDialogStore}
              />
              <MediaSettingsDialog
                vm={rootStore.mediaSettingsDialogViewModel}
              />
              <CallingConversationContext.Provider
                value={rootStore.callingConversationViewModel}
              >
                <CallPromptsHost callRootVM={rootStore.callRootViewModel} />
              </CallingConversationContext.Provider>
              <ConfirmDialog
                state={rootStore.deleteMessageConfirm}
                title="Delete Message"
                yes="Delete"
                danger
              >
                <p>
                  Are you sure you want to delete this message? This cannot be
                  undone.
                </p>
              </ConfirmDialog>
            </div>
          </CallRootContext.Provider>
        )}
      </WorkspaceGateway>
    )
  }
}
