import { Suspense } from 'react'
import * as React from 'react'
import { observer } from 'mobx-react'

const TwilioMediaRenderers = {
  Video: React.lazy(() =>
    import(/* webpackChunkName: 'twilio' */ '../../twilio/TwilioVideoRenderer')
  ),
  Audio: React.lazy(() =>
    import(/* webpackChunkName: 'twilio' */ '../../twilio/TwilioAudio')
  ),
}

/**
 " Audio Renderer
 */
export const AudioRenderer = observer(function AudioRenderer({
  callParticipantVM,
}) {
  if (!callParticipantVM.media) {
    return null
  }

  // Currently Twilio is all we have support
  return (
    <Suspense fallback={<></>}>
      <TwilioMediaRenderers.Audio
        twilioParticipantMedia={callParticipantVM.media}
      />
    </Suspense>
  )
})

/**
 " Video Renderer
 */
export const VideoRenderer = observer(function VideoRenderer({
  callParticipantVM,
}) {
  if (!callParticipantVM.media) {
    return null
  }

  // Currently Twilio is all we have support
  return (
    <Suspense fallback={<></>}>
      <TwilioMediaRenderers.Video
        twilioParticipantMedia={callParticipantVM.media}
      />
    </Suspense>
  )
})
