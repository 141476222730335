import { Store } from 'libx'
import env from 'misc/env'
import { extractMessageFromError, retryOnNetworkFailure } from 'utils/errorUtil'
import { ScopeChangeRequestApi } from 'jobs/API'
import ScopeChangeRequestBreakdown from 'domain/ScopeChangeRequestBreakdown'

export default class ScopeChangeRequestBreakdownStore extends Store {
  scopeChangeRequestBreakdowns = this.collection({
    model: ScopeChangeRequestBreakdown,
  })

  api = new ScopeChangeRequestApi({
    baseURL: env.WORK_DOTNET_API,
    token$: this.rootStore.authStore.token$,
  })

  /**
   * Gets price breakdown details for the specified scope change request.
   *
   * @param scopeChangeRequestId
   * @returns
   */
  async fetchScopeChangeRequestBreakdown(scopeChangeRequestId) {
    const response = await retryOnNetworkFailure(() =>
      this.api.getScopeChangeRequestBreakdown(scopeChangeRequestId)
    ).doneRetrying((e) =>
      this.rootStore.flashMessageStore.create({
        message: extractMessageFromError(e),
        type: 'error',
      })
    )
    this.scopeChangeRequestBreakdowns.set(response)
    return this.scopeChangeRequestBreakdowns.find(
      (breakdown) => breakdown.id === scopeChangeRequestId
    )
  }
}
