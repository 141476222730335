import { Store } from 'libx'
import JobEvent from '@taxfyle/web-commons/lib/jobs/JobEvent'
import { action } from 'mobx'
import { browserHistory } from 'react-router'
import { match } from 'utils/patternMatchUtil'
import links from 'misc/links'
import { getFeatureToggleClient } from 'misc/featureToggles'
import { timestampToISO } from '@taxfyle/web-commons/lib/utils/grpcUtil'
import { JobEvent as JobEventProto } from '@taxfyle/api-internal/internal/job_event_pb'
import { filter, tap } from 'rxjs/operators'

export default class EventStore extends Store {
  /**
   * Job Event from the realtime channel.
   */
  jobEvents$ = this.rootStore.api.jobEventsV3.events$.pipe(
    filter((e) => e !== null && e !== undefined),
    tap(this.onRemoteUpdate)
  )

  constructor() {
    super(...arguments)
    this.jobEvents = this.collection({
      model: JobEvent,
    })

    // prepare realtime events
    this.jobEvents$.subscribe()
  }

  @action.bound
  async onRemoteUpdate(realtimeEvent) {
    const jobEventProtoDto = realtimeEvent
      .getJobEventRecorded()
      ?.toObject()?.jobEvent

    if (!jobEventProtoDto) {
      return
    }

    const jobEvent = mapJobEventProtoToJobEventDto(jobEventProtoDto)
    await this._handleRemoteJobEventRecorded(jobEvent)
  }

  async _handleRemoteJobEventRecorded(data) {
    if (!this.rootStore.sessionStore.member) {
      return
    }

    const isNew = !this.jobEvents.get(data.id)
    const event = this.jobEvents.set(data)

    await this.rootStore.memberStore.fetchManyByPublicId(
      event.allMembers.map((x) => ({
        workspace_id: x.workspaceId,
        user_public_id: x.userPublicId,
      }))
    )

    const job = await this.rootStore.jobStore.getJob(data.job_id)
    const userIsClient = job?.members.find(
      (x) => x.user.userPublicId === this.rootStore.sessionStore.user?.public_id
    )

    if (isNew && userIsClient) {
      const opts = this._getNotificationOpts(event)
      if (opts) {
        this.rootStore.desktopNotificationStore.show(opts)
      }
    }
  }

  /**
   * Fetches job events.
   *
   * @param {string} jobId
   * The ID of the job.
   *
   * @param {number} limit
   * The page size for the request.
   *
   * @param {string} after
   * The page token to query after.
   */
  async fetchJobEvents(jobId, limit, after) {
    const useV3ListJobEvents = getFeatureToggleClient().variation(
      'Portals.UseV3ListJobEvents',
      false
    )

    return useV3ListJobEvents
      ? this.rootStore.api.jobEventsV3
          .listJobEvents({ jobId, limit, after })
          .then((result) => ({
            data: this.jobEvents.set(
              result.jobEventsList.map(mapJobEventProtoToJobEventDto)
            ),
            cursor: result.nextPageToken,
          }))
      : this.rootStore.api.jobEvents
          .find({ job: jobId })
          .then(action(this.jobEvents.set))
          .then((r) => ({
            data: r,
            cursor: null,
          }))
  }

  _getNotificationOpts(event) {
    const notifyOpts = {
      timeout: 20,
      onClick: () => browserHistory.push(links.projectDetails(event.jobId)),
    }
    const job = event.job
    // Don't show notifications for events the current user initiated.
    const userIsClient = job?.members.find(
      (x) => x.user.userPublicId === this.rootStore.sessionStore.user.public_id
    )
    if (userIsClient) {
      return null
    }

    return match(event.eventType, {
      UNCLAIMED: () => ({
        ...notifyOpts,
        title: `${job.name} transferred to pool`,
        body: `${event.triggeredBy.displayName} transferred the job to the pool.`,
        icon: event.triggeredBy.avatar,
      }),
      CLAIMED: () => ({
        ...notifyOpts,
        title: `${job.name} has been assigned`,
        body: `${job.name} assigned to ${job.provider.displayName}.`,
        icon: job.provider.avatar,
      }),
      UPDATED: () => ({
        ...notifyOpts,
        title: `${job.name} updated`,
        body: `${event.triggeredBy.displayName} updated the details of ${job.name}`,
        icon: event.triggeredBy.avatar,
      }),
      AMENDED: () => ({
        ...notifyOpts,
        title: `${job.name} amended`,
        body: `${event.triggeredBy.displayName} amended ${job.name}`,
        icon: event.triggeredBy.avatar,
      }),
      REOPENED: () => ({
        ...notifyOpts,
        title: `${job.name} reopened`,
        body: `${event.triggeredBy.displayName} reopened ${job.name}`,
        icon: event.triggeredBy.avatar,
      }),
      TRANSFERRED: () => ({
        ...notifyOpts,
        title: `${job.name} transferred`,
        body: event.newProvider
          ? `Transferred to ${event.newProvider.displayName}`
          : event.newClient
          ? `Transferred to ${event.newClient.displayName}`
          : 'job transferred',
        icon: event.newProvider
          ? event.newProvider.avatar
          : event.newClient
          ? event.newClient.avatar
          : '',
      }),
      FORFEITED: () => ({
        ...notifyOpts,
        title: `${job.name} released`,
        body: `${job.name} was released by ${event.previousProvider.displayName}`,
        icon: event.previousProvider.avatar,
      }),
      CLOSED: () => ({
        ...notifyOpts,
        title: `${job.name} was closed`,
        body: `${job.name} was closed by ${job.provider.displayName}.`,
        icon: job.provider.avatar,
      }),
      TASK_MARKED_COMPLETE: () => {
        const milestone = job.milestones.find((x) =>
          x.tasks.some((t) => t.id === event.taskId)
        )
        const task =
          milestone && milestone.tasks.find((x) => x.id === event.taskId)
        return {
          ...notifyOpts,
          title: 'Task completed.',
          body: `${event.triggeredBy.displayName} completed ${
            task ? `"${task.title}"` : 'a task'
          }.`,
        }
      },
      [match.DEFAULT]: () => null,
    })
  }
}

/**
 * Maps a job event proto type to the DTO type.
 */
function mapJobEventProtoToJobEventDto(jobEventProto) {
  return {
    id: jobEventProto.id,
    date_created: timestampToISO(jobEventProto.dateCreated),
    event_type: mapEventType(jobEventProto.eventType),
    event_visibility: mapEventVisibility(jobEventProto.eventVisibility),
    job_id: jobEventProto.jobId,
    description: jobEventProto.description,
    reason: jobEventProto.reason?.value,
    task_title: jobEventProto.taskTitle?.value,
    previous_provider: mapPreviousProvider(jobEventProto.previousProvider),
    new_team: mapNewTeam(jobEventProto.newTeam),
    job_resolution: mapJobResolution(jobEventProto.jobResolution),
    new_provider: mapNewProvider(jobEventProto.newProvider),
    new_client: mapNewClient(jobEventProto.newClient),
    provider: mapProvider(jobEventProto.provider),
    triggered_by: mapTriggeredBy(jobEventProto.triggeredBy),
  }

  function mapEventType(eventType) {
    // prettier-ignore
    return match(eventType, {
      [JobEventProto.EventType.UNCLAIMED]: () => 'UNCLAIMED',
      [JobEventProto.EventType.CLAIMED]: () => 'CLAIMED',
      [JobEventProto.EventType.UPDATED]: () => 'UPDATED',
      [JobEventProto.EventType.AMENDED]: () => 'AMENDED',
      [JobEventProto.EventType.REOPENED]: () => 'REOPENED',
      [JobEventProto.EventType.TRANSFERRED]: () => 'TRANSFERRED',
      [JobEventProto.EventType.FORFEITED]: () => 'FORFEITED',
      [JobEventProto.EventType.PROVIDER_ADDED]: () => 'PROVIDER_ADDED',
      [JobEventProto.EventType.PROVIDER_REMOVED]: () => 'PROVIDER_REMOVED',
      [JobEventProto.EventType.JOB_MEMBER_ADDED]: () => 'JOB_MEMBER_ADDED',
      [JobEventProto.EventType.JOB_MEMBER_REMOVED]: () => 'JOB_MEMBER_REMOVED',
      [JobEventProto.EventType.TASK_MARKED_COMPLETE]: () => 'TASK_MARKED_COMPLETE',
      [JobEventProto.EventType.TASK_MARKED_INCOMPLETE]: () => 'TASK_MARKED_INCOMPLETE',
      [JobEventProto.EventType.CLOSED]: () => 'CLOSED',
      [JobEventProto.EventType.RESUMED_JOB]: () => 'RESUMED_JOB',
      [JobEventProto.EventType.PUT_ON_HOLD]: () => 'PUT_ON_HOLD',
      [JobEventProto.EventType.BECAME_INACTIVE]: () => 'BECAME_INACTIVE',
      [JobEventProto.EventType.PAYMENT_INFO_REQUESTED]: () => 'PAYMENT_INFO_REQUESTED',
      [JobEventProto.EventType.PAYMENT_INFO_PROVIDED]: () => 'PAYMENT_INFO_PROVIDED',
      [JobEventProto.EventType.PAYMENT_SUCCESSFUL]: () => 'PAYMENT_SUCCESSFUL',
      [JobEventProto.EventType.PAYMENT_FAILED]: () => 'PAYMENT_FAILED',
      [JobEventProto.EventType.COUPON_ADDED]: () => 'COUPON_ADDED',
      [JobEventProto.EventType.COUPON_REMOVED]: () => 'COUPON_REMOVED',
      [JobEventProto.EventType.REFUND_SUCCESSFUL]: () => 'REFUND_SUCCESSFUL',
      [JobEventProto.EventType.REFUND_FAILED]: () => 'REFUND_FAILED',
      [JobEventProto.EventType.MANUAL_INVOICE_ADDED]: () => 'MANUAL_INVOICE_ADDED',
      [JobEventProto.EventType.ANSWERS_CORRECTED]: () => 'ANSWERS_CORRECTED',
      [JobEventProto.EventType.INFO_GATHERING_COMPLETED]: () => 'INFO_GATHERING_COMPLETED',
      [JobEventProto.EventType.CLIENT_ADDED]: () => 'CLIENT_ADDED',
      [JobEventProto.EventType.EVENT_TYPE_UNSPECIFIED]: () => 'UNSPECIFIED',
      [match.DEFAULT]: () => 'UNSPECIFIED'
    })
  }

  function mapEventVisibility(eventVisibility) {
    return match(eventVisibility, {
      [JobEventProto.EventVisibility.PUBLIC]: () => 'PUBLIC',
      [JobEventProto.EventVisibility.INTERNAL]: () => 'INTERNAL',
    })
  }

  function mapPreviousProvider(previousProvider) {
    return previousProvider
      ? { display_name: previousProvider.displayName }
      : undefined
  }

  function mapNewTeam(newTeam) {
    return newTeam ? { name: newTeam.name } : undefined
  }

  function mapJobResolution(resolution) {
    const mappedResolution = match(resolution, {
      [JobEventProto.JobResolution.DONE]: () => 'DONE',
      [JobEventProto.JobResolution.CANCELLED]: () => 'CANCELLED',
      [JobEventProto.JobResolution.TEST]: () => 'TEST',
      [JobEventProto.JobResolution.JOB_RESOLUTION_UNSPECIFIED]: () => null,
    })

    return mappedResolution ? { resolution: mappedResolution } : undefined
  }

  function mapNewProvider(newProvider) {
    return newProvider ? { display_name: newProvider.displayName } : undefined
  }

  function mapNewClient(newClient) {
    return newClient ? { display_name: newClient.displayName } : undefined
  }

  function mapProvider(provider) {
    return provider ? { display_name: provider.displayName } : undefined
  }

  function mapTriggeredBy(triggeredBy) {
    return {
      user_id: triggeredBy.userId,
      user_public_id: triggeredBy.userPublicId,
      first_name: triggeredBy.givenName,
      last_name: triggeredBy.familyName,
    }
  }
}
