import { Store } from 'libx'
import DataLoader from 'dataloader'

import LegendSettings from '../domain/LegendSettings'

export default class LegendSettingsStore extends Store {
  _cacheKeyFn = (params) => {
    return `${params.id}:${params.version}`
  }

  legendSettings = this.collection({
    model: LegendSettings,
    getDataId: (data) => `${data.id}:${data.version}`,
    getModelId: (model) => `${model.id}:${model.version}`,
  })

  legendSettingsLoader = new DataLoader(this._fetch.bind(this), {
    cacheKeyFn: this._cacheKeyFn,
  })

  get legendsAPI() {
    return this.rootStore.api.legends
  }

  primeLegendSettings(props) {
    if (!props || !props.id || !props.version) {
      return {}
    }

    this.fetch(props.id, props.version)

    const legendSettings = this.legendSettings.set({
      id: props.id,
      version: props.version,
    })

    return legendSettings.set(props, {
      parse: true,
      stripUndefined: true,
    })
  }

  async fetch(id, version) {
    return this.legendSettingsLoader.load({ id, version })
  }

  async _fetch(params) {
    return this.legendsAPI.getSettings(params).then(this.legendSettings.set)
  }
}
