import React from 'react'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'
import FlexCenter from 'components/FlexCenter'
import ProgressSpinner from 'components/ProgressSpinner'
import PasswordResetPage from 'components/PasswordResetPage'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import './styles.sass'

@observer
export default class CompleteRegistration extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  componentDidMount() {
    const { token } = this.props.params
    this.props.rootStore.resetPasswordScreenStore.activate(token)
  }

  render() {
    const store = this.props.rootStore.resetPasswordScreenStore

    return (
      <>
        {store.activate.match({
          pending: () => (
            <FlexCenter className="has-spacing-top">
              <ProgressSpinner blue />
            </FlexCenter>
          ),
          resolved: () => (
            <PasswordResetPage
              store={store}
              flashMessageStore={this.props.rootStore.flashMessageStore}
              showPassword={true}
              title={T(
                'Web.CompleteRegistration.Title',
                'You have been invited!'
              )}
              description={T(
                'Web.CompleteRegistration.Description',
                'Please set a secure password for signing in and accessing your data.'
              )}
              onSubmit={store.resetPassword}
              submitButtonText="Complete Registration"
            />
          ),
        })}
      </>
    )
  }
}
