import { localStorage as storage } from 'misc/storage'

/**
 * Parse an encoded querystring to a URLSearchParams object
 * @param {*} queryString
 * @returns
 */
function decodeQueryString(queryString) {
  return new URLSearchParams(decodeURIComponent(queryString))
}

/**
 * Validates a security token
 */
export function checkSecurityToken(stateString, getStorageKey) {
  const state = decodeQueryString(stateString)

  const storageKey = getStorageKey(state)

  const localState = storage.getItem(storageKey)

  if (localState !== null && localState === state.get('security_token')) {
    storage.removeItem(storageKey)

    return {
      isSuccess: true,
      state,
    }
  }

  return { isSuccess: false }
}
