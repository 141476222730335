import { TwilioCallSource } from './twilio/TwilioCallSource'
import { WherebyCallSource } from './whereby/WhereByCallSource'

/**
 * Creates a Call Source Factory function.
 */
export function CallSourceFactory() {
  return async (callVM, connectionDescriptor, ct) => {
    switch (connectionDescriptor.type) {
      case 'Twilio': {
        const twilio = await import(
          /* webpackChunkName: 'twilio' */
          'twilio-video'
        )
        ct.throwIfCancelled()
        return new TwilioCallSource(callVM, twilio, connectionDescriptor)
      }
      case 'Whereby':
        return new WherebyCallSource(callVM, connectionDescriptor)
      default:
        throw new Error(
          'Unknown call source type: ' + connectionDescriptor.type
        )
    }
  }
}
