import React from 'react'
import { observer } from 'mobx-react'
import Modal from 'components/Modal'
import { FormGroup, Input, Help, getError } from 'components/Form'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'
import range from 'lodash/range'
import ErrorLabel from 'components/ErrorLabel'
import Select from 'react-select'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'
import QuestionGrid from 'jobs/components/QuestionsEditor'
import { HtmlClass } from 'components/ClassName'
import './styles.sass'

const UpdateJobDialog = ({ store }) => {
  const options = getYearOptions()
  const job = store.job
  return (
    <Modal
      isOpened={store.showing}
      onRequestClose={() => store.hide()}
      className="job-update-modal"
      position="top"
    >
      <HtmlClass className="disable-scrolling" />
      <Dialog className="update-job-dialog">
        {store.job &&
          store.save.match({
            pending: () => <ContentLoading small />,
            rejected: (err) => <ContentError error={err} />,
            resolved: () => (
              <div className="job-update-dialog">
                <DialogTitle>Update job</DialogTitle>
                <DialogContent>
                  <Input
                    label="Reason"
                    placeholder="Provide a reason why you are updating the job."
                    model={store}
                    field="reason"
                    help=""
                  />
                  {!job.legend.excludeLegacyYears && (
                    <FormGroup label="Filing years">
                      <Select
                        name="years"
                        isMulti
                        onChange={(opts) =>
                          store.selectYears(opts.map((x) => x.value))
                        }
                        value={store.filingYears
                          .slice()
                          .map((v) => options.find((x) => x.value === v))}
                        options={options}
                        getOptionValue={(s) => s.value}
                        getOptionLabel={(s) => s.label}
                      />
                      <Help error={getError(store, 'filingYears')} />
                    </FormGroup>
                  )}
                  {!job.legend.excludeLegacyStates && (
                    <FormGroup label="States">
                      <Select
                        name="states"
                        isMulti
                        onChange={(opts) =>
                          store.selectStates(opts.map((x) => x.value))
                        }
                        options={store.availableStates}
                        value={store.states
                          .slice()
                          .map((x) =>
                            store.availableStates.find((s) => s.value === x)
                          )}
                        getOptionValue={(s) => s.value}
                        getOptionLabel={(s) => s.label}
                      />
                      <Help error={getError(store, 'states')} />
                    </FormGroup>
                  )}
                  <FormGroup label="Questions">
                    <QuestionGrid
                      questions={store.questions}
                      answers={store.answers}
                      disabled={store.fetchQuestions.pending}
                      loading={
                        store.questions.length === 0 &&
                        store.fetchQuestions.pending
                      }
                      // Changes the answer
                      onChange={(answers) => store.setAnswers(answers)}
                      // Fetches questions based on new answers.
                      onUpdate={store.fetchQuestions}
                    />
                    <Help error={getError(store, 'answers')} />
                  </FormGroup>
                  <ErrorLabel>
                    {store.error ||
                      (store.hasBreaker
                        ? 'Cannot save a job with a breaker.'
                        : null)}
                  </ErrorLabel>
                </DialogContent>
                <DialogFooter>
                  <DialogButton onClick={store.hide}>Cancel</DialogButton>
                  <DialogButton
                    primary
                    onClick={store.save}
                    disabled={store.hasBreaker}
                  >
                    Save
                  </DialogButton>
                </DialogFooter>
              </div>
            ),
          })}
      </Dialog>
    </Modal>
  )
}

export default observer(UpdateJobDialog)

function getYearOptions() {
  return range(0, 6)
    .map((i) => new Date().getFullYear() - i)
    .map((year) => ({
      label: year,
      value: year,
    }))
}
