const logosContext = require.context('assets/partner-logos', false, /.png$/)

/**
 * Gets a partner logo.
 */
export function getLogo(referrer, variant) {
  if (!referrer) {
    return null
  }

  const fileName = variant
    ? `./${referrer}-${variant}.png`
    : `./${referrer}.png`

  const logo = logosContext.keys().some((x) => x === fileName)
    ? logosContext(fileName)
    : null
  return logo
}
