import { Model } from 'libx'
import { observable } from 'mobx'

export default class QuickBooksAccount extends Model {
  @observable realmId = null
  @observable companyName = null
  @observable connectionId = null

  /**
   * Parses the DTO to model properties.
   */
  parse(attrs) {
    return {
      connectionId: attrs.connectionId,
      realmId: attrs.realmId,
      companyName: attrs.companyName,
    }
  }
}

export class XeroAccount extends Model {
  @observable tenantId = null
  @observable tenantName = null
  @observable connectionId = null

  /**
   * Parses the DTO to model properties.
   */
  parse(attrs) {
    return {
      connectionId: attrs.connectionId,
      tenantId: attrs.tenantId,
      tenantName: attrs.tenantName,
    }
  }
}
