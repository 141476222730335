import cx from 'classnames'
import { BodyClass } from 'components/ClassName'
import FlashMessages from 'flash-messages/components/FlashMessages'
import { observer } from 'mobx-react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import styles from './SignupPage.m.sass'
import Button from 'components/Button'
import { Form, Input } from 'components/Form'

export default observer(function SignupPage({ rootStore, workspace }) {
  const signupStore = rootStore.signupScreenStore
  const branded = {
    id: workspace?.id || undefined,
    name: workspace?.name || 'Taxfyle',
    corporate: workspace?.branding?.corporate.enabled || false,
    logo:
      workspace?.branding?.logos.primary ||
      require('assets/img/taxfyle-logo.png'),
    icon: workspace?.branding?.logos.icon || require('./img/logo-icon.png'),
  }

  const authError = JSON.parse(window.localStorage.getItem('authError') || '{}')

  if (authError.description) {
    rootStore.flashMessageStore.create({
      type: 'error',
      message: authError.description,
    })
    window.localStorage.removeItem('authError')
  }

  return (
    <Form onSubmit={() => signupStore.signup()}>
      <div className={cx(styles.root)}>
        <FlashMessages
          store={rootStore.flashMessageStore}
          position="bottomLeft"
        />
        <Helmet title={branded.name} />
        <BodyClass className={styles.body} />
        <CardView>
          <div className={styles.cardContent}>
            <div className={styles.cardItem}>
              <div
                className={styles.logo}
                style={{
                  backgroundImage: `url(${branded.logo})`,
                }}
              />
            </div>

            <div className={cx(styles.cardItem, styles.center)}>
              <div className={styles.title}>Welcome to {branded.name}</div>
              <div className={styles.description}>
                Tell us a bit more about yourself to get started.
              </div>

              <div className={styles.nameContainer}>
                <div className={styles.expanded}>
                  <Input
                    field="firstName"
                    model={signupStore}
                    label="First name"
                  />
                </div>
                <div className={styles.expanded}>
                  <Input
                    field="lastName"
                    model={signupStore}
                    label="Last name"
                  />
                </div>
              </div>
              <Input field="email" model={signupStore} label="Email address" />
              <Input
                field="phone"
                model={signupStore}
                label="Phone number"
                type="tel"
              />
              <Input
                field="password"
                model={signupStore}
                label="Password"
                type="password"
              />

              <Button
                submit
                loading={signupStore.signup.pending}
                className={cx(
                  styles.signup,
                  signupStore.canSubmit && styles.canSubmit
                )}
              >
                Complete Registration
              </Button>
            </div>

            <div className={cx(styles.cardItem, styles.footer, styles.center)}>
              <div className={styles.textLinks}>
                Already have an account?{' '}
                <a onClick={() => rootStore.authStore.login()}>Log in</a>
              </div>
              <div className={styles.textLinks}>
                By signing up, I agree to Taxfyle’s{' '}
                <a href="https://www.taxfyle.com/policy/terms">
                  terms and conditions
                </a>
              </div>
            </div>
          </div>
        </CardView>
        <ImageShapedBorder />
        <ImageView image={branded.image} />
      </div>
    </Form>
  )
})

const CardView = ({ children }) => (
  <div className={styles.cardView}>{children}</div>
)

const ImageView = ({ image }) => (
  <div className={styles.imageContainer}>
    <div
      className={styles.image}
      style={{
        backgroundImage: `url(${require('assets/img/signup-review.png')})`,
      }}
    />
  </div>
)

const ImageShapedBorder = () => (
  <div className={styles.shapes}>
    <div className={styles.triangleAccent} />
    <div className={styles.triangle} />
  </div>
)
