import { action, observable, computed } from 'mobx'
import { task } from 'mobx-task'
import memoize from 'memoizee'

export default class SelectProState {
  stepId = 'SelectPro'
  title = ''
  description = ''

  trackingId = 'Onboarding - Select Pro'

  @observable
  selectedPro = ''

  constructor({ wizard }) {
    this.wizard = wizard
    this.rootStore = wizard.rootStore

    this.activate = this.activate.wrap((fn) =>
      memoize(fn, { promise: true, length: 0 })
    )
    this.rootStore.sessionStore.onWorkspaceSelected(() => this.activate.clear())
  }

  // TODO: Once all wizard steps are migrated fully to new grcp endpoints, this can be
  // removed and refactored in the ProjectWizardStore.js flow.
  get skipSyncJob() {
    return true
  }

  @computed
  get continueButtonText() {
    return 'Continue'
  }

  @computed
  get relevant() {
    const step = this.wizard.getStep('ProjectType')
    if (!step) {
      return false
    }

    const legend = this.wizard.job.legend
    if (!legend || !legend.allowProSelection) {
      return false
    }

    return this.rootStore.projectWizardStore.previousPros.length !== 0
  }

  @action
  async selectPro(value) {
    this.selectedPro = value
    await this._requestChampionProvider()
    this.wizard.next()
  }

  @task
  async activate() {}

  async hydrate(job) {
    this.selectedPro = job.requestedProPublicId
  }

  async _requestChampionProvider() {
    const selectedPro =
      !this.selectedPro || this.selectedPro === 'any' ? null : this.selectedPro

    await this.wizard.rootStore.draftStore.requestChampionProvider(
      this.wizard.job.id,
      selectedPro
    )
  }
}
