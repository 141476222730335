import React from 'react'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import { Form, Input } from 'components/Form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'

const ChangeEmailDialog = observer(function ChangeEmailDialog({ store }) {
  return (
    <Modal isOpened={store.showing} onRequestClose={store.close}>
      <Dialog>
        <Form onSubmit={store.save}>
          <DialogTitle>Change email</DialogTitle>
          <DialogContent>
            <Input
              label="New email address"
              field="email"
              disabled={store.save.pending}
              placeholder="Please enter your new email address"
              model={store}
            />
          </DialogContent>
          <DialogFooter>
            <DialogButton
              disabled={store.save.pending}
              type="button"
              onClick={store.close}
            >
              Cancel
            </DialogButton>
            <DialogButton submit disabled={store.save.pending} primary>
              Save
            </DialogButton>
          </DialogFooter>
        </Form>
      </Dialog>
    </Modal>
  )
})

export default ChangeEmailDialog
