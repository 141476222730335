import { Store } from 'libx'
import env from '../misc/env'
import { localStorage as storage } from 'misc/storage'
import QuickBooksAccount, {
  XeroAccount,
} from '../domain/BookkeepingPlatformConnection'
import { extractMessageFromError } from 'utils/errorUtil'
import { computed } from 'mobx'
import { checkSecurityToken } from '../utils/oauth2Util'
import InfiniteScrollProvider from '@taxfyle/web-commons/lib/data/InfiniteScrollProvider'

export default class BookkeepingPlatformConnectionStore extends Store {
  quickBooksAccounts = this.collection({
    model: QuickBooksAccount,
    idAttribute: 'connectionId',
  })

  xeroAccounts = this.collection({
    model: XeroAccount,
    idAttribute: 'connectionId',
  })

  constructor() {
    super(...arguments)
    this.scrollProviders = new Map()
  }

  /**
   * Creates a manual QuickBooks platform connection.
   *
   * @param {*} realmId
   * @param {*} companyName
   * @param {*} workspaceId
   * @returns {QuickBooksAccount}
   */
  async createQuickBooksPlatformConnection(realmId, companyName, workspaceId) {
    const connection = await this.rootStore.api.quickbooks
      .createQuickBooksPlatformConnection(realmId, companyName, workspaceId)
      .then((res) => res.toObject())
      .catch((e) => {
        this.rootStore.flashMessageStore.create({
          message: extractMessageFromError(e),
          type: 'error',
        })

        throw e
      })

    await this.fetchQuickBooksPlatformConnections({ limit: 100 })

    return this.quickBooksAccounts.get(connection.id)
  }

  /**
   * Provides platform conneciton.
   *
   * @param {string} connectionId
   * @param {{connectionId: string, platform: string }} options
   */
  async providePlatformConnection(jobId, options) {
    await this.rootStore.api.bookkeeping
      .providePlatformConnection(jobId, options)
      .catch((e) => {
        this.rootStore.flashMessageStore.create({
          message: extractMessageFromError(e),
          type: 'error',
        })

        throw e
      })
  }

  /**
   * Marks access as granted for the given bookkeeping tracker ID.
   *
   * @param {string} id  - the bookkeeping tacker ID.
   */
  async markAccessAsGranted(id) {
    await this.rootStore.api.bookkeeping.markAccessAsGranted(id).catch((e) => {
      this.rootStore.flashMessageStore.create({
        message: extractMessageFromError(e),
        type: 'error',
      })

      throw e
    })
  }

  /**
   * Creates a QuickBooks platform connection from an oauth2 code
   * @param {string} code
   * @param {string} realmId
   * @param {string} redirectUri
   * @param {string} workspaceId
   * @returns {QuickBooksAccount}
   */
  async createQuickBooksPlatformConnectionFromOAuth2Code(
    code,
    realmId,
    redirectUri,
    workspaceId
  ) {
    const response = await this.rootStore.api.quickbooks
      .createQuickBooksPlatformConnectionFromOAuth2Code(
        code,
        realmId,
        redirectUri,
        workspaceId
      )
      .catch((e) => {
        this.rootStore.flashMessageStore.create({
          message: extractMessageFromError(e),
          type: 'error',
        })

        throw e
      })

    await this.fetchQuickBooksPlatformConnections({ limit: 100 })

    return this.quickBooksAccounts.get(response.getId())
  }

  /**
   * Creates a Xero platform connection from an oauth2 code
   * @param {string} code
   * @param {string} redirectUri
   * @param {string} workspaceId
   * @returns {QuickBooksAccount}
   */
  async createXeroPlatformConnectionFromOAuth2Code(
    code,
    redirectUri,
    workspaceId
  ) {
    const response = await this.rootStore.api.xero
      .createXeroPlatformConnectionFromOAuth2Code(
        code,
        redirectUri,
        workspaceId
      )
      .catch((e) => {
        this.rootStore.flashMessageStore.create({
          message: extractMessageFromError(e),
          type: 'error',
        })

        throw e
      })

    await this.fetchXeroPlatformConnections({ limit: 100 })

    return this.xeroAccounts.get(response.getId())
  }

  /**
   * Fetches the list of QuickBooks platform connections.
   *
   * @param {*} params
   */
  async fetchQuickBooksPlatformConnections(params) {
    const res = await this.rootStore.api.quickbooks.listQuickBooksConnections(
      params
    )
    this.quickBooksAccounts.set(res.data)
    return res
  }

  /**
   * Fetches the list of Xero platform connections.
   *
   * @param {*} workspaceId
   */
  async fetchXeroPlatformConnections(params) {
    const res = await this.rootStore.api.xero.listXeroConnections(params)
    this.xeroAccounts.set(res.data)
    return res
  }

  @computed
  get quickBooksRedirectUri() {
    return `${window.location.protocol}//${window.location.host}/quick-books-oauth2`
  }

  /**
   * Redirects to QuickBooks OAuth2 Page
   * @param {*} jobId
   */
  redirectToQuickBooksOauth2Page(jobId) {
    const scope = [
      'com.intuit.quickbooks.accounting',
      'openid',
      'profile',
      'email',
      'phone',
      'address',
    ].join('%20')

    const securityToken = window.crypto.randomUUID()

    storage.setItem(`quickBooksOauthState-${jobId}`, securityToken)

    // security_token=the_security_token&id=the_job_id
    const state = `security_token%3D${securityToken}%26id%3D${jobId}`

    window.location = `https://appcenter.intuit.com/connect/oauth2?client_id=${env.QUICK_BOOKS_CLIENT_ID}&response_type=code&scope=${scope}&redirect_uri=${this.quickBooksRedirectUri}&state=${state}`
  }

  /**
   * Checks QuickBooks security token
   * @param {*} state
   * @returns
   */
  checkQuickBooksSecurityToken(state) {
    return checkSecurityToken(
      state,
      (params) => `quickBooksOauthState-${params.get('id')}`
    )
  }

  @computed
  get xeroRedirectUri() {
    return `${window.location.protocol}//${window.location.host}/xero-oauth2`
  }

  /**
   * Redirects to Xero OAuth2 Page
   * @param {*} jobId
   */
  redirectToXeroOauth2Page(jobId) {
    const scope = [
      'openid',
      'profile',
      'email',
      'accounting.transactions',
    ].join('%20')

    const securityToken = window.crypto.randomUUID()

    storage.setItem(`xeroOauthState-${jobId}`, securityToken)

    // security_token=the_security_token&id=the_job_id
    const state = `security_token%3D${securityToken}%26id%3D${jobId}`

    window.location = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${env.XERO_CLIENT_ID}&scope=${scope}&redirect_uri=${this.xeroRedirectUri}&state=${state}`
  }

  /**
   * Checks Xero security token
   * @param {*} state
   * @returns
   */
  checkXeroSecurityToken(state) {
    return checkSecurityToken(
      state,
      (params) => `xeroOauthState-${params.get('id')}`
    )
  }

  /**
   * Gets or inits a scroll provider.
   * A scroll provider is used to manage infinite scrolling state
   * for APIs using cursor-based paging.
   */
  getScrollProvider(uid, fetchItems) {
    const existing = this.scrollProviders.get(uid)
    if (existing) {
      return existing
    }

    const newProvider = new InfiniteScrollProvider({
      memoizeInitial: true,
      limit: 100,
      fetchItems,
    })

    this.scrollProviders.set(uid, newProvider)
    return newProvider
  }
}
