import { action, observable, reaction } from 'mobx'
import { interval } from 'rxjs'
import { takeWhile } from 'rxjs/operators'
import jwtDecode from 'jwt-decode'
import { EmailConfirmationAPI } from '../API'
import { browserHistory } from 'react-router'

export class ConfirmEmailViewModel {
  /**
   * The user's email, based on the token.
   * @type {string | null}
   */
  @observable
  email = null

  /**
   * Indicates whether the user's email has been confirmed.
   * @type {boolean}
   */
  @observable
  emailConfirmed = false

  /**
   * Indicates whether the confirmation attempt has expired based on the token.
   * @type {boolean}
   */
  @observable
  pageExpired = false

  /**
   * Email Confirmation API.
   */
  #api

  /**
   * 5 second ticker.
   * @type {Observable<number>}
   */
  #timer = interval(5000).pipe(
    takeWhile(() => !this.emailConfirmed && !this.pageExpired)
  )

  /**
   * Unsubscribes from the timer.
   */
  #timerSubscription

  /**
   * Toast factory.
   */
  #flashMessageStore

  constructor(flashMessageStore) {
    this.#flashMessageStore = flashMessageStore

    reaction(
      () => this.emailConfirmed,
      (confirmed) => {
        if (confirmed) {
          browserHistory.push('/email-confirmed')
        }
      }
    )
  }

  /**
   * Resends the verification email.
   */
  resendVerificationEmail = async () => {
    if (!this.email) {
      return
    }

    await this.#api?.resendEmail().catch(() =>
      this.#flashMessageStore.create({
        message: 'An error occurred',
        type: 'error',
      })
    )
  }

  /**
   * Activates the view model.
   */
  @action.bound
  activate(token) {
    this.#api = new EmailConfirmationAPI(token)

    const { email, exp } = this.#decodeToken(token)
    if (!email || !exp) {
      return
    }

    this.email = email

    const expiresAt = exp * 1000
    this.#timerSubscription = this.#timer.subscribe(
      action(() => {
        this.checkTokenExpiration(expiresAt)
        this.checkVerificationStatus()
      })
    )

    // Check token expiration immediately upon activation as well.
    this.checkTokenExpiration(expiresAt)
  }

  /**
   * Deactivates the view model.
   */
  deactivate() {
    this.#timerSubscription?.unsubscribe()
  }

  /**
   * Checks the email verification status of the user. Used to automatically update the page.
   */
  checkVerificationStatus = () => {
    this.#api?.getVerificationStatus().then(
      action((status) => {
        this.emailConfirmed = status
      })
    )
  }

  /**
   * Checks whether the token has expired.
   */
  @action.bound
  checkTokenExpiration(expiresAt) {
    if (Date.now() > expiresAt) {
      this.pageExpired = true
    }
  }

  /**
   * Attempts to decode the token.
   * @returns {{ exp: string, email: string }}
   */
  #decodeToken(token) {
    try {
      const { email, exp } = jwtDecode(token)
      if (!email || !exp) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error()
      }

      return { email, exp }
    } catch {
      this.#flashMessageStore.create({
        message: 'Invalid token',
        type: 'error',
      })

      return { email: undefined, exp: undefined }
    }
  }
}
