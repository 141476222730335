import { Store } from 'libx'
import { observable, runInAction, computed } from 'mobx'
import { extractMessageFromError } from 'utils/errorUtil'
import { browserHistory } from 'react-router'
import { task } from 'mobx-task'
import links from 'misc/links'
import QS from 'qs'

export default class DiyStore extends Store {
  @observable
  columnTaxUrl = ''

  @observable
  diyJobId = ''

  @observable
  consultationJob = null

  @observable
  qs

  constructor({ rootStore }) {
    super({ rootStore })
  }

  @computed
  get allowsConsultationUpsell() {
    return !!this.consultationLegendId
  }

  @computed get consultationLegendId() {
    const workspaceConfig = this.rootStore.sessionStore.workspace.config
    return workspaceConfig.diy
      ? workspaceConfig.diy.consultation_legend_id
      : null
  }

  @computed
  get hideNavbar() {
    return this.qs?.hideNavbar === 'true'
  }

  @computed
  get headerProps() {
    const { simplifiedNavBar } =
      this.rootStore.sessionStore.workspace.features.jobDetailsConfig
    return (
      simplifiedNavBar.enabled && {
        simplified: true,
        simplifiedLogo: simplifiedNavBar.image,
        backTargetUrl: simplifiedNavBar.targetUrl,
      }
    )
  }

  @task
  async activate() {
    runInAction(() => {
      this.qs = QS.parse(location.search, { ignoreQueryPrefix: true })
    })

    const workspaceId = this.rootStore.sessionStore.workspace.id
    await this.rootStore.api.diy
      .createDiyUrl({ workspaceId })
      .then((res) => {
        runInAction(async () => {
          this.columnTaxUrl = res.url
          this.diyJobId = res.diyJobId

          if (res.consultationJobId) {
            this.consultationJob =
              await this.rootStore.projectStore.fetchProject(
                res.consultationJobId.value
              )
          }
        })
      })
      .catch((err) => {
        this.rootStore.flashMessageStore
          .create(extractMessageFromError(err))
          .failed()
        runInAction(() => {
          browserHistory.replace(links.projects)
        })
      })
  }

  goToConsultationJob = task.resolved(async () => {
    if (!this.allowsConsultationUpsell) {
      return
    }

    if (this.consultationJob) {
      if (this.consultationJob.status === 'UNDER_CONSTRUCTION') {
        runInAction(() => {
          browserHistory.push(
            links.projectEditor(
              this.consultationJob.id,
              this.qs?.hideNavbar,
              true
            )
          )
        })
      } else {
        runInAction(() => {
          browserHistory.push(
            links.projectDetails(this.consultationJob.id, this.qs?.hideNavbar)
          )
        })
      }
      return
    }

    const latestConsultationLegendVersion =
      await this.rootStore.api.legends.getVersion(this.consultationLegendId)

    const consultationJobId = await this.rootStore.draftStore.createDraftJob(
      this.consultationLegendId,
      latestConsultationLegendVersion.version
    )

    try {
      this.rootStore.api.diy.linkConsultationJobToDiyJob({
        diyJobId: this.diyJobId,
        consultationJobId: consultationJobId,
      })
    } catch (err) {
      this.rootStore.flashMessageStore
        .create(extractMessageFromError(err))
        .failed()
      return
    }

    runInAction(() => {
      browserHistory.push(
        links.projectEditor(consultationJobId, this.qs?.hideNavbar, true)
      )
    })
  })
}
