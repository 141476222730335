export default function makeCompleteQuickBooksOauth2Flow({ store }) {
  return async function completeQuickBooksOauth2Flow(
    nextState,
    replace,
    callback
  ) {
    const { state, realmId, code } = nextState.location.query
    const securityTokenValidation =
      await store.bookkeepingPlatformConnectionStore.checkQuickBooksSecurityToken(
        state
      )
    if (securityTokenValidation.isSuccess) {
      const jobId = securityTokenValidation.state.get('id')

      store.createBookkeepingPlatformConnectionStore.setConnectionParams(
        jobId,
        { realmId, code, platform: 'QuickBooks' }
      )

      replace({
        pathname: `project/${jobId}`,
      })
    }
    callback()
  }
}
