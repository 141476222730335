import { observable } from 'mobx'
import { Model } from 'libx'

export default class LegendSettings extends Model {
  @observable
  id = ''

  @observable
  imageUrl = ''

  @observable
  name = ''

  @observable
  priceUnit = ''

  @observable
  published = true

  @observable
  ratingConfig = null

  @observable
  skipQuote = false

  @observable
  version = 0

  @observable
  workspaceId = ''

  parse(json) {
    return {
      id: json.id,
      imageUrl: json.image_url,
      name: json.name,
      priceUnit: json.price_unit,
      published: json.published,
      ratingConfig: json.rating_config
        ? {
            proRatingEnabled: json.rating_config.proRatingEnabled,
            platformRatingEnabled: json.rating_config.platformRatingEnabled,
            customerRatingMethod: json.rating_config.customerRatingMethod,
          }
        : null,
      skipQuote: json.skip_quote,
      version: json.version,
      workspaceId: json.workspace_id,
    }
  }
}
