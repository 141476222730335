import { generateAvatarFallback } from '@taxfyle/web-commons/lib/utils/dummyImageUtil'
import { Store } from 'libx'
import orderBy from 'lodash/orderBy'
import { action, computed, observable, reaction, runInAction, toJS } from 'mobx'
import { task } from 'mobx-task'
import { browserHistory } from 'react-router'
import FeeBreakdownState from 'screens/ProjectWizard/Steps/FeeBreakdown/FeeBreakdownState'
import ProjectTypeState from 'screens/ProjectWizard/Steps/ProjectType/ProjectTypeState'
import QuestionsState from 'screens/ProjectWizard/Steps/Questions/QuestionsState'
// import UploadDocumentsState from 'screens/ProjectWizard/Steps/UploadDocuments/UploadDocumentsState'
// Step states
// import LinkAccountsState from 'screens/ProjectWizard/Steps/LinkAccounts/LinkAccountsState'
import TeamSelectState from 'screens/ProjectWizard/Steps/TeamSelect/TeamSelectState'
import WizardCompletedState from 'screens/ProjectWizard/Steps/WizardCompleted/WizardCompletedState'
import SelectProState from 'screens/ProjectWizard/Steps/SelectPro/SelectProState'
import { extractMessageFromError } from 'utils/errorUtil'
import { debouncePromise } from 'utils/promiseUtil'
import links from '../../misc/links'
import { getFeatureToggleClient } from 'misc/featureToggles'
import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import { canCreateJobs } from 'utils/permissionUtil'
import { sessionStorage } from 'misc/storage'
import { sessionReturnOptionsKey } from 'misc/MagicLinkClient'

export default class ProjectWizardStore extends Store {
  @observable
  currentStep = null

  @observable
  steps = []

  @observable
  hereForFreeExtensions = false

  @observable
  job

  @observable
  stepDelta = 0 // this is for animation purposes

  @observable
  name = ''

  @observable showSpinner = false

  @observable
  previousPros = []

  @observable
  preselectedLegend = null

  @observable
  fromDiy = false

  @observable legends = []

  @observable newlyCreatedJob = false

  constructor() {
    super(...arguments)
    this._doSyncJob = debouncePromise(this._doSyncJob)
    // This only shows the spinner if we are processing for 100ms or more.
    reaction(
      () => this.isProcessing,
      (v) => {
        if (v) {
          this.showSpinnerTimeout = setTimeout(
            action(() => {
              this.showSpinner = true
            }),
            100
          )
          return
        }
        clearTimeout(this.showSpinnerTimeout)
        this.showSpinner = false
      }
    )
  }

  @computed
  get isProcessing() {
    return (
      this.next.pending || (this.currentStep && !!this.currentStep.isProcessing)
    )
  }

  @computed
  get showBackButton() {
    // Only show back button after the first step,
    // and not on the last step (which is after payment)
    if (this.currentStep instanceof WizardCompletedState) {
      return false
    }

    return !this.currentStep.jobIsSubmitting
  }

  @computed
  get canCreateJobs() {
    const member = this.rootStore.sessionStore.member
    const teamMembers = this.rootStore.sessionStore.teamMembers

    return canCreateJobs(member, teamMembers)
  }

  /**
   * Gets the magic link return URL from the session.
   */
  @computed
  get customReturnUrl() {
    const returnOptionsPayload = sessionStorage.getItem(sessionReturnOptionsKey)
    const payload = returnOptionsPayload
      ? JSON.parse(returnOptionsPayload)
      : null
    const returnOptions = payload ? payload.returnOptions : null

    return returnOptions?.url
  }

  canEditJob(job) {
    // get the correct member depending if team job or not
    let member = this.rootStore.sessionStore.member

    if (job) {
      if (job.ownerTeamId) {
        const teamMember = this.rootStore.teamMemberStore.forTeamAndUser(
          job.ownerTeamId,
          member.userId
        )
        // if you are a team member of that job use that permission
        if (teamMember) {
          member = teamMember
        }
      }
      // if we are on this job as a member check JOB_CREATE perm
      if (job.members.find((x) => x.user.userId === member.userId)) {
        if (job.ownerTeamId) {
          return member.hasPermission('CREATE_TEAM_JOBS')
        }
        return member.hasSomePermissions(['JOB_CREATE', 'JOB_SUBMIT'])
      }
      // if we are not on the job check if we have UPDATE_TEAM_JOB permission
      return member.hasPermission('UPDATE_TEAM_JOBS')
    }

    return false
  }

  /**
   * Used by steps to derive data from other steps.
   */
  getStep(stepId) {
    const step = this.steps.find((x) => x.stepId === stepId)
    if (!step) {
      throw new Error(`Step "${stepId}" not found.`)
    }

    return step
  }

  /**
   * Lists previous providers for the current member.
   */
  @task
  async getPreviousPros() {
    const useV3ListPreviousProviders = getFeatureToggleClient().variation(
      'Portals.UseV3ListPreviousProviders',
      false
    )

    if (useV3ListPreviousProviders) {
      const providers = await this.rootStore.draftStore.listPreviousProviders(
        this.rootStore.sessionStore.workspace.id,
        this.rootStore.sessionStore.member.userPublicId
      )

      this.previousPros = providers || []
      this.rootStore.memberStore.members.set(providers)

      return
    }

    this._fetchPreviousProvidersLegacy()
  }

  /**
   * Called by the view when activated.
   */
  @task
  async activate(id, preselectedLegendId, fromDiy) {
    const legends = await this.fetchLegends()
    this.setLegends(legends)

    this.preselectedLegend = legends.find((x) => x.id === preselectedLegendId)
    this.fromDiy = fromDiy

    // We need to fetch previous pros early so we can determine whether the pro select step is needed.
    await this.getPreviousPros()

    // We need to fetch team members early so we can determine whether the team select step is needed.
    await this.rootStore.teamMemberStore.fetchUserTeamMembersByPublicId()
    if (id) {
      const job = await this.rootStore.projectStore.fetchProject(id)

      if (job.status !== 'UNDER_CONSTRUCTION') {
        browserHistory.replace(links.projectDetails(job.id))
        return
      }
      // else if its under construction but you dont have perms show error message instead
      else if (!this.canEditJob(job)) {
        this.rootStore.flashMessageStore
          .create(
            `You do not have permission to view or edit this ${T(
              'Web.Common.Job',
              'Job'
            )}. Please wait until the ${T(
              'Web.Common.Job',
              'Job'
            )} has been submitted. If you think this is in error, please contact ${T(
              'Web.Common.Support',
              'Support'
            )}.`
          )
          .failed()
        browserHistory.replace(links.projects())
        return
      }
      await this.maybeUpgradeLegend(job)
      this.setJob(job)
      this.setJobName(job.name || job.description)
      this.setNewlyCreatedJob(false)
      await this.hydrateJob()
      this._restoreStep()
    } else {
      this.setJob(null)
      this.setJobName(null)
      this.setNewlyCreatedJob(true)
      await this.resetSteps()
    }
  }

  async fetchLegends() {
    return this.rootStore.api.legends
      .find({
        workspace_id: this.rootStore.sessionStore.workspace.id,
        limit: 99999,
      })
      .then((d) => d.data)
  }

  async maybeUpgradeLegend(job) {
    if (job.status !== 'UNDER_CONSTRUCTION') {
      return
    }

    const latestPublishedLegend = await this.rootStore.api.legends.getVersion(
      job.legendId
    )

    if (latestPublishedLegend.version <= job.legendVersion) {
      // It's up to date, nothing to do here.
      return
    }

    const msg = this.rootStore.flashMessageStore.create({
      inProgress: true,
      message:
        "There's been slight changes to this questionnaire. Upgrading it for you...",
    })
    try {
      await this.rootStore.draftStore.upgradeLegend(job.id)
      await this.rootStore.projectStore.fetchProject(job.id)
      msg.done('The questionnaire was upgraded!').autoDismiss(10000)
    } catch (err) {
      msg.failed(extractMessageFromError(err))
    }
  }

  @action
  setLegends(legends) {
    const mapped = orderBy(
      legends.map((l) => ({
        additionalFees: [], // default
        imageUrl: l.image_url || generateAvatarFallback(l.name),
        ...l,
      })),
      ['viewOrder']
    )
    this.legends.replace(mapped)
  }

  /**
   * Sets the current job being edited.
   */
  @action
  setJob(job) {
    this.job = job
  }

  /**
   * Sets the current job's name.
   */
  @action.bound
  setJobName(name = '') {
    this.name = name
  }

  @task.resolved
  async saveJobName() {
    if (this.name) {
      try {
        await this.rootStore.projectStore.rename(this.job.id, this.name.trim())
      } finally {
        this.setJobName(this.job.name)
      }
    }
  }

  /**
   * Trashes current state.
   */
  @action
  async resetSteps() {
    this.steps.forEach((step) => {
      step.deactivate && step.deactivate()
      step.destroy && step.destroy()
    })
    const steps = this._createSteps([
      TeamSelectState,
      ProjectTypeState,
      QuestionsState,
      SelectProState,
      FeeBreakdownState,
      WizardCompletedState,
    ])
    this.steps.replace(steps)
    await Promise.all(
      this.steps.map((step) => step.initialize && step.initialize())
    )

    this.handlePreselection()

    this.setStep(this.steps[0])
  }

  @action
  setStep(step) {
    if (!step) {
      return
    }
    const idxBefore = this.steps.indexOf(this.currentStep)
    const idxAfter = this.steps.indexOf(step)
    if (step.relevant === false) {
      const direction = idxBefore - idxAfter < 0 ? 1 : -1
      return this.setStep(this.steps[idxAfter + direction])
    }

    // Give the step a hook to clean up
    if (this.currentStep && this.currentStep.deactivate) {
      this.currentStep.deactivate()
    }
    this.currentStep = step
    this.stepDelta = idxAfter - idxBefore < 0 ? -1 : 1
    // Give the step a hook to load data when relevant.
    if (this.currentStep.activate) {
      this.currentStep.activate()
    }

    this.trackStep()
  }

  @action
  setProcessing(processing) {
    this.isProcessing = processing
  }

  @action
  setNewlyCreatedJob(newlyCreatedJob) {
    this.newlyCreatedJob = newlyCreatedJob
  }

  @action
  back() {
    const idx = this.steps.indexOf(this.currentStep)
    if (idx === 0) return
    const prevStep = this.steps[idx - 1]
    const back = () => this.setStep(prevStep)
    if (this.currentStep.back) {
      // Step can hijack the back function
      // Inject the actual back call so it
      // can call it when ready.
      this.currentStep.back(back)
      return
    }

    back()
  }

  /**
   * Triggered when wanting to exit the wizard. Only syncs if we already have a job.
   */
  @action.bound
  async exit() {
    this.preselectedLegend = null
    if (this.job) {
      await this.syncJob()
    }
  }

  @task.resolved
  async next() {
    // Make sure we don't run this more than one at a time
    if (this._isNexting) {
      return
    }
    try {
      this._isNexting = true
      if (this.currentStep.next) {
        // This step wants to handle "next"
        const canContinue = await this.currentStep.next()
        if (!canContinue) {
          return
        }
      }

      if (this.currentStep === this.getStep('TeamSelect')) {
        this.handlePreselection()
      }

      try {
        await this.syncJob()
      } catch (err) {
        runInAction(() => {
          this.rootStore.flashMessageStore
            .create(extractMessageFromError(err))
            .failed()
        })
        throw err
      }

      const idx = this.steps.indexOf(this.currentStep)
      if (idx === this.steps.length - 1) {
        return
      }

      const nextStep = this.steps[idx + 1]
      this.setStep(nextStep)
    } finally {
      this._isNexting = false
    }
  }

  handlePreselection() {
    if (this.preselectedLegend && !this.preselectedLegend.inactive) {
      this.getStep('ProjectType').preselectLegend(this.preselectedLegend)
    } else if (this.legends.length === 1) {
      this.getStep('ProjectType').preselectLegend(this.legends[0])
    }
  }

  @task.resolved
  async syncJob(force) {
    if (!force) {
      if (this.currentStep === this.getStep('FeeBreakdown')) {
        return
      }

      if (this.currentStep === this.getStep('TeamSelect')) {
        return
      }
    }

    if (this.currentStep?.skipSyncJob) {
      return
    }

    // This is debounced.
    return this._doSyncJob()
  }

  async _doSyncJob() {
    const data = this.steps
      .map((x) => x.serializeToJob && x.serializeToJob())
      .reduce((x, y) => ({ ...x, ...toJS(y) }), {})
    if (!this.job) {
      const newJob = await this.rootStore.projectStore.createJob(data)
      this.setJob(newJob)
      this.setJobName(newJob.name)
      browserHistory.replace(links.projectEditor(this.job.id))
      this.rootStore.trackingStore.setDataLayer('jobCreated', {
        jobId: this.job.id,
      })
      this.rootStore.trackingStore.track('Job Created', {
        job_id: this.job.id,
        workspace_id: this.job.workspaceId,
        legend_id: this.job.legend.id,
      })
    }
  }

  async hydrateJob() {
    await this.resetSteps()
    await Promise.all(
      this.steps.map(action((x) => x.hydrate && x.hydrate(this.job)))
    )
  }

  @action
  _restoreStep() {
    if (
      this.currentStep === this.getStep('ProjectType') ||
      this.currentStep === this.getStep('TeamSelect')
    ) {
      // Check to see if we can guess what step to put them on.
      let step = this.getStep('Questions')
      if (step.hasAnsweredAllQuestions) {
        step = this.getStep('FeeBreakdown')
      }

      this.setStep(step)
    }
  }

  /**
   * Shortcut to instantiate the steps, passing in this wizard instance.
   */
  _createSteps(stepStates) {
    return stepStates
      .filter((x) => x)
      .map((StepState) => new StepState({ wizard: this }))
  }

  /**
   * Sends data to TrackingStore.
   */
  trackStep() {
    if (this.currentStep.trackingId) {
      this.rootStore.trackingStore.page(this.currentStep.trackingId)
    }
  }

  /**
   * Goes to the next question.
   */
  nextQuestion() {
    // Capture the current question and start time before moving to the next question.
    const currentQuestion = this.currentStep?.currentQuestion
    const startTime = performance.now()
    this.next().then(() => {
      const latency = performance.now() - startTime
      this.rootStore.trackingStore.trackRudderAnalytics(
        'respond to question - continue',
        {
          question_id: currentQuestion?.id,
          answer: currentQuestion?.answer,
          job_id: currentQuestion?.jobId,
          latency_ms: latency,
        }
      )
    })
  }

  /**
   * Skips the current question.
   */
  skipQuestion() {
    // Capture the current question and start time before moving to the next question.
    const currentQuestion = this.currentStep?.currentQuestion
    const startTime = performance.now()
    this.next().then(() => {
      const latency = performance.now() - startTime
      this.rootStore.trackingStore.trackRudderAnalytics(
        'respond to question - skip',
        {
          question_id: currentQuestion?.id,
          answer: currentQuestion?.answer,
          job_id: currentQuestion?.jobId,
          latency_ms: latency,
        }
      )
    })
  }

  /**
   * Fetches previous providers using Work API.
   */
  async _fetchPreviousProvidersLegacy() {
    const providers = await this.rootStore.api.providers.find({
      workspace_id: this.rootStore.sessionStore.workspace.id,
    })

    this.previousPros = providers || []
    this.rootStore.memberStore.members.set(providers)
  }
}
