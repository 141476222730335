import { Store } from 'libx'
import { observable, action, computed } from 'mobx'
import { validationContext, required, pattern, func } from 'validx'
import { task } from 'mobx-task'
import { extractMessageFromError } from 'utils/errorUtil'

export default class ProfileEditorStore extends Store {
  @observable
  firstName = ''

  @observable
  lastName = ''

  @observable
  email = ''

  @observable
  phone = ''

  @observable
  isTermsModalOpen = false

  @observable
  selectedTerms = null

  validation = validationContext(this, {
    firstName: [
      required('First name is required'),
      func(({ value }) => {
        return (
          value.length <= 40 || "First name can't be more than 40 characters"
        )
      }),
    ],
    lastName: [
      required('Last name is required'),
      func(({ value }) => {
        return (
          value.length <= 40 || "Last name can't be more than 40 characters"
        )
      }),
    ],
    email: [
      required('Email is required'),
      pattern({ pattern: 'email', msg: 'Not a valid email' }),
      func(({ value }) => {
        return value.length <= 320 || "Email can't be more than 320 characters"
      }),
    ],
    phone: [
      func(({ value }) => {
        return value.length <= 20 || "Phone can't be more than 20 characters"
      }),
    ],
  })

  @computed
  get canSetEmail() {
    // Can only set the email if we don't already have it.
    // return !this.rootStore.authStore.user.email
    return true
  }

  @computed
  get canEdit() {
    return (
      !this.save.pending &&
      (!this.rootStore.sessionStore.workspace.config.signup
        ?.external_profiles ||
        false)
    )
  }

  @computed
  get picture() {
    return this.rootStore.sessionStore.member.avatar
  }

  @computed
  get canChangeEmail() {
    const workspaceConfig = this.rootStore.sessionStore.workspace.config || {}

    return !(
      workspaceConfig.public_api_config?.signed_url_enabled &&
      workspaceConfig.external_customers_config?.enabled
    )
  }

  @action
  activate() {
    const member = this.rootStore.sessionStore.member
    this.validation.reset()
    Object.assign(this, {
      firstName: member.givenName,
      lastName: member.familyName,
      email: member.email,
      phone: member.phone,
    })
    this.fetchAgreements()
  }

  getParams() {
    return {
      workspace_id: this.rootStore.sessionStore.workspace.id,
      user_id: this.rootStore.sessionStore.member.userPublicId,
    }
  }

  @task.resolved
  async save() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    const data = {
      given_name: this.firstName,
      family_name: this.lastName,
      email: this.email,
      phone: this.phone,
    }
    const params = this.getParams()
    await this.rootStore.memberStore
      .updateByPublicId(params, data)
      .then(
        action(() => {
          this.rootStore.flashMessageStore
            .create({
              type: 'success',
              message: 'Your profile has been updated!',
            })
            .autoDismiss()
        })
      )
      .catch((e) =>
        this.rootStore.flashMessageStore.create({
          type: 'error',
          message: extractMessageFromError(e),
        })
      )
  }

  @task.resolved
  async uploadPicture(file) {
    return this.rootStore.memberStore.changeAvatarByPublicId(
      this.getParams(),
      file
    )
  }

  @task.resolved
  async fetchAgreements() {
    const workspaceId = this.rootStore.sessionStore.workspace.id
    await this.rootStore.termsOfUseStore.getAgreements(workspaceId)
  }

  @action.bound
  onShowTerms(termsId) {
    const terms = this.agreementTerms.find((t) => t.id === termsId)
    this.selectedTerms = terms
    this.isTermsModalOpen = true
  }

  @action.bound
  onCloseTermsModal() {
    this.isTermsModalOpen = false
  }

  @computed
  get userAgreements() {
    const workspaceId = this.rootStore.sessionStore.workspace.id
    const userId = this.rootStore.sessionStore.member.userId

    return this.rootStore.termsOfUseStore.agreementsForWorkspaceMember(
      workspaceId,
      userId
    )
  }

  @computed
  get agreementTerms() {
    return this.userAgreements.map((a) => a.terms)
  }

  @computed
  get workspaceMemberData() {
    return Object.keys(this.rootStore.sessionStore.member.memberMetadata)
      .map((key) => {
        const value = this.rootStore.sessionStore.member.memberMetadata[key]
        const memberMetadataFields =
          this.rootStore.sessionStore.workspace.customMetadataFields
            ?.member_metadata_fields || [] // member_metadata_fields can be nullish
        const field = memberMetadataFields.find((x) => x.id === key)
        if (!field || field.inactive) {
          return null
        }
        return {
          id: key,
          title: field.title,
          value: value,
        }
      })
      .filter(Boolean)
  }
}
