import { Store } from 'libx'
import { observable, action, computed, runInAction } from 'mobx'
import { validationContext, required, pattern } from 'validx'
import { task } from 'mobx-task'
import { browserHistory } from 'react-router'
import { extractMessageFromError } from 'utils/errorUtil'

// error code thrown when you cant reset password
const SOCIAL_ERROR_CODE = 417

export default class ResetPasswordScreenStore extends Store {
  @observable
  email = ''

  @observable
  password = ''

  @observable
  passwordResetRequestId = ''

  @observable
  errorText = ''

  constructor(opts) {
    super(opts)
    this.sendEmail = this.sendEmail.bind(this)
    this.resetPassword = this.resetPassword.bind(this)
  }

  validation = validationContext(this, {
    email: [
      required('Email is required'),
      pattern({ pattern: 'email', msg: 'Not a valid email' }),
    ],
  })

  @task
  async activate(token) {
    this.validation.reset()

    const rpr = await this.rootStore.userStore.getResetPasswordRequestById(
      token
    )

    if (rpr) {
      this.email = rpr.email
      this.passwordResetRequestId = rpr.id
    } else {
      throw Error('Token not found.')
    }
  }

  @task.resolved
  async sendEmail() {
    if (!this.validation.reset().validate().isValid) {
      return
    }

    const workspace =
      (window.__workspaceDomain && window.__workspaceDomain.workspace) || {}

    const id = workspace.id || '1'

    const msg = this.rootStore.flashMessageStore.create({
      message: 'Sending Email...',
      inProgress: true,
    })

    await this.rootStore.userStore
      .requestPasswordReset(this.email, id)
      .then(
        action(() => {
          msg.done('Email sent!').autoDismiss()
        })
      )
      .catch((e) => {
        // Special error for
        if (e.response.status === SOCIAL_ERROR_CODE) {
          runInAction(() => {
            this.errorText = e.response.data.message
          })
          msg.dismiss()
          return
        }
        msg.failed(extractMessageFromError(e)).autoDismiss()
      })
  }

  @computed
  get passwordValid() {
    return (
      this.minimumLength &&
      this.maximumLength &&
      this.lowerCaseChar &&
      this.upperCaseChar &&
      this.specialChar &&
      this.numberChar
    )
  }

  @computed
  get minimumLength() {
    return this.password.length >= 8
  }

  @computed
  get maximumLength() {
    return this.password.length <= 50
  }

  get lowerCaseChar() {
    return this.password.toUpperCase() !== this.password
  }

  get upperCaseChar() {
    return this.password.toLowerCase() !== this.password
  }

  get specialChar() {
    return /[~`!@#$%^&*+=\\(\\)\-_\\.[\]\\';,/{}|\\":<>\\?]/g.test(
      this.password
    )
  }

  get numberChar() {
    return /\d/g.test(this.password)
  }

  @task.resolved
  async resetPassword() {
    this.validation.reset()
    if (!this.passwordResetRequestId) {
      return
    }

    if (!this.passwordValid) {
      this.validation.addErrors({
        password: ['Password is invalid'],
      })
    }

    const msg = this.rootStore.flashMessageStore.create({
      message: 'Resetting password...',
      inProgress: true,
    })

    await this.rootStore.userStore
      .resetPassword(this.passwordResetRequestId, this.password)
      .then(() => {
        msg.done('Your password has been updated!').autoDismiss()
        browserHistory.push('/login')
      })
      .catch((e) => {
        msg.failed(extractMessageFromError(e)).autoDismiss()
      })
  }
}
