import React from 'react'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import { Form, DatePicker } from 'components/Form'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from 'components/Dialog'

const UpdateDeadlineDialog = observer(function UpdateDeadlineDialog({ store }) {
  return (
    <Modal isOpened={store.showing} onRequestClose={store.close}>
      <Dialog>
        <Form onSubmit={store.save}>
          <DialogTitle>Update Deadline</DialogTitle>
          <DialogContent>
            <DatePicker
              type="datetime-local"
              model={store}
              field="deadline"
              label="Deadline"
              help="New deadline must be later than the current deadline."
            />
          </DialogContent>
          <DialogFooter>
            <DialogButton
              disabled={store.save.pending}
              type="button"
              onClick={store.close}
            >
              Cancel
            </DialogButton>
            <DialogButton
              submit
              disabled={store.save.pending}
              loading={store.save.pending}
              primary
            >
              Save
            </DialogButton>
          </DialogFooter>
        </Form>
      </Dialog>
    </Modal>
  )
})

export default UpdateDeadlineDialog
