import React, { useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import Modal from 'components/Modal'
import {
  Dialog,
  DialogFooter,
  DialogButton,
  DialogContent,
} from 'components/Dialog'
import Icon from 'components/Icon'
import Button from 'components/Button'
import ContentLoading from 'components/ContentLoading'
import ContentError from 'components/ContentError'
import { Dropdown, DropdownItem, DropdownItemIcon } from 'components/Dropdown'
import cx from 'classnames'
import styles from './MediaSettingsDialog.m.sass'

export default observer(function MediaSettingsDialog({ vm }) {
  return (
    <Modal isOpened={vm.isOpened} onRequestClose={vm.close}>
      <Dialog>
        <div className={styles.header}>
          <h2 className={styles.headerTitle}>Settings</h2>
          <p className={styles.headerSubtitle}>
            Configure your audio and video for calls
          </p>
        </div>
        <DialogContent>
          {vm.activate.match({
            pending: () => <ContentLoading />,
            error: (err) => <ContentError error={err} />,
            resolved: () => (
              <div>
                <div className={styles.audioSettings}>
                  <h4 className={styles.sectionTitle}>Audio Settings</h4>

                  <div className={styles.mics}>
                    <Dropdown
                      className={styles.deviceSelectDropdown}
                      button={
                        <DeviceSelectButton
                          icon={<Icon size={14} name="microphone" />}
                        >
                          {vm.selectedMicrophone?.label}
                        </DeviceSelectButton>
                      }
                    >
                      {vm.microphones.map((d) => {
                        const selected =
                          d.deviceId === vm.selectedMicrophone?.deviceId

                        return (
                          <DropdownItem
                            active={selected}
                            key={d.deviceId}
                            onClick={() =>
                              vm.setSelectedMicrophoneDeviceId(d.deviceId)
                            }
                          >
                            {selected && <DropdownItemIcon name="check" />}
                            {d.label}
                          </DropdownItem>
                        )
                      })}
                    </Dropdown>
                    <AudioLevelViz
                      key={vm.selectedMicrophoneMediaStream?.id}
                      level={vm.selectedMicLevels}
                    />
                  </div>

                  {/* Speaker selection scaffold. TODO */}
                  {/* <div className={styles.speakers}>
                    <div className={styles.speakersSelect}>
                      <Dropdown
                        className={styles.deviceSelectDropdown}
                        button={
                          <DeviceSelectButton
                            icon={<Icon size={14} name="volume-up" />}
                          >
                            {vm.selectedSpeaker?.label}
                          </DeviceSelectButton>
                        }
                      >
                        {vm.speakers.map((d) => {
                          const selected =
                            d.deviceId === vm.selectedSpeaker?.deviceId

                          return (
                            <DropdownItem
                              active={selected}
                              key={d.deviceId}
                              onClick={() =>
                                vm.setSelectedSpeakerDeviceId(d.deviceId)
                              }
                            >
                              {selected && <DropdownItemIcon name="check" />}
                              {d.label}
                            </DropdownItem>
                          )
                        })}
                      </Dropdown>
                    </div>
                    <Button
                      loading={vm.playingTestAudio}
                      onClick={vm.testSpeaker}
                      type="primary"
                    >
                      {vm.playingTestAudio ? 'Playing' : 'Test'}
                    </Button>
                  </div> */}
                </div>
                {vm.canHaveOutgoingVideo && (
                  <div className={styles.videoSettings}>
                    <div className={styles.videoSettingsInputs}>
                      <h4 className={styles.sectionTitle}>Video Settings</h4>
                      <Dropdown
                        className={styles.deviceSelectDropdown}
                        button={
                          <DeviceSelectButton
                            icon={<Icon size={14} material name="videocam" />}
                          >
                            {vm.selectedCamera?.label}
                          </DeviceSelectButton>
                        }
                      >
                        {vm.cameras.map((d) => {
                          const selected =
                            d.deviceId === vm.selectedCamera?.deviceId

                          return (
                            <DropdownItem
                              active={selected}
                              key={d.deviceId}
                              onClick={() =>
                                vm.setSelectedCameraDeviceId(d.deviceId)
                              }
                            >
                              {selected && <DropdownItemIcon name="check" />}
                              {d.label}
                            </DropdownItem>
                          )
                        })}
                      </Dropdown>
                    </div>
                    <div className={styles.cameraPreviewHost}>
                      {vm.selectedCameraMediaStream && (
                        <CameraPreview
                          mediaStream={vm.selectedCameraMediaStream}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            ),
          })}
        </DialogContent>
        <hr className={styles.separator} />
        <DialogFooter>
          <DialogButton onClick={vm.close}>Close</DialogButton>
        </DialogFooter>
      </Dialog>
    </Modal>
  )
})

const CameraPreview = observer(function CameraPreview({ mediaStream }) {
  const ref = useRef(null)

  useEffect(() => {
    if (mediaStream && ref.current) {
      const current = ref.current
      current.srcObject = mediaStream
      return () => {
        mediaStream.getTracks()?.forEach((t) => t.stop())
        current.srcObject = null
      }
    }
  }, [mediaStream])

  if (!mediaStream) {
    return null
  }

  return (
    <div className={styles.cameraPreviewContainer}>
      <video autoPlay playsInline className={cx(styles.video)} ref={ref} />
    </div>
  )
})

/**
 * Linear interpolation.
 *
 * @param {number} start
 * @param {number} end
 * @param {number} amt
 */
export function lerp(start, end, amt) {
  return (1 - amt) * start + amt * end
}

const numOvals = 36
const AudioLevelViz = observer(function AudioLevelViz({ level }) {
  const [ls] = React.useState(new Array(numOvals).fill(0))

  const normalizedLevel = lerp(0, numOvals, level - 32) / 256

  return (
    <div className={styles.audioLevelVizRoot}>
      <div className={styles.dotsContainer}>
        {ls.map((_, index) => {
          return (
            <svg width="100%" viewBox="0 0 100 100" key={index}>
              <circle
                cx="50"
                cy="50"
                r="50"
                fill={index < normalizedLevel ? '#2AB27B' : '#DFE2E5'}
              />
            </svg>
          )
        })}
      </div>
    </div>
  )
})

const DeviceSelectButton = observer(function DeviceSelectButton({
  children,
  icon,
}) {
  return (
    <Button iconLeft={icon} className={styles.deviceSelectButton}>
      {children}
      <div className={styles.deviceSelectButtonCaret}>
        <Icon size={12} name="chevron-down" />
      </div>
    </Button>
  )
})
