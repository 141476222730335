import { Store } from 'libx'
import { action, observable } from 'mobx'
import { task } from 'mobx-task'
import { extractMessageFromError } from 'utils/errorUtil'
import { required, validationContext } from 'validx'
import moment from 'moment'
export default class UpdateDeadlineDialogStore extends Store {
  @observable
  showing = false

  @observable
  deadline = moment()

  @observable
  jobId = ''

  @observable
  previousDeadline = moment()

  validation = validationContext()

  rules = {
    deadline: [
      required('New deadline is required'),
      () =>
        this.previousDeadline.isBefore(this.deadline) ||
        'New deadline must be further out.',
    ],
  }

  constructor() {
    super(...arguments)
    this.save = this.save.bind(this)
  }

  @action.bound
  show(job) {
    this.jobId = job.id
    this.deadline = job.dateDeadline
    this.previousDeadline = job.dateDeadline
    this.showing = true
  }

  @action.bound
  close() {
    this.showing = false
  }

  @task.resolved
  async save() {
    if (!this.validation.reset().validate(this, this.rules).isValid) {
      return
    }

    const msg = this.rootStore.flashMessageStore.create({
      message: 'Updating Deadline...',
      inProgress: true,
    })
    try {
      await this.rootStore.projectStore.updateDeadline(
        this.jobId,
        this.deadline
      )
      this.close()
      msg.done('Job updated.').autoDismiss()
    } catch (e) {
      msg.failed(extractMessageFromError(e)).autoDismiss()
    }
  }
}
