import axios from 'axios'
import decodeJwt from 'jwt-decode'
import Cookies from 'js-cookie'

export default class SignedUrlClient {
  constructor() {
    this.token = null
  }

  /** Returns the token upon successful retrieval. */
  async getToken() {
    if (this.token) {
      const currentTimestamp = Math.floor(Date.now() / 1000)
      const decoded = decodeJwt(this.token)

      if (decoded.exp - 30 > currentTimestamp) {
        return this.token
      }
    }

    try {
      const res = await axios.get('/__session/token/refresh')

      // let's keep the token in memory
      this.token = res.data.access_token

      return this.token
    } catch (err) {
      console.error('Error fetching signed URL access token', err)
    }
  }

  /**
   * Returns the session from the successful retrieval of the token.
   *
   * @returns {Object}
   */
  async fetchSessionInfo() {
    const token = await this.getToken()
    const decoded = decodeJwt(token)

    return { token, authId: decoded.sub, claims: [] }
  }

  /**
   * Removes the __session_provider cookie and redirects to the expiration page.
   *
   * @returns {Object}
   */
  logout() {
    return window.location.replace('/__session/expired')
  }

  /**
   * Noop for Signed URL.
   *
   * @returns {Object}
   */
  async handleRedirect() {}
}

/**
 * Creates a SignedUrlClient object when the value of __session_provider cookie is
 * signed_url otherwise it returns null.
 *
 * @returns {SignedUrlClient | null}
 */
export function createSignedUrlClient() {
  const provider = Cookies.get('__session_provider')
  return provider === 'signed_url' ? new SignedUrlClient() : null
}
