import { Store } from 'libx'
import { GetJobRatingStatusResponse } from '@taxfyle/api-internal/internal/job_rating_pb'

/**
 * Job Rating store.
 */
export class JobRatingStore extends Store {
  constructor({ rootStore }) {
    super({ rootStore })
    this.api = rootStore.api
  }

  /**
   * Get the job rating status for a job.
   */
  async getJobRatingStatus(jobId) {
    return await this.api.jobRating
      .getJobRatingStatus({ jobId })
      .then(mapToJobRatingStatus)
  }

  /**
   * Submit pro rating for a job.
   */
  async submitProRating(jobId, rating, comment, proUserPublicId) {
    return await this.api.jobRating.submitProRating({
      jobId,
      rating,
      comment,
      proUserPublicId,
    })
  }

  /**
   * Submit work rating for a job.
   */
  async submitPlatformRating(jobId, rating, comment, proUserPublicId) {
    return await this.api.jobRating.submitPlatformRating({
      jobId,
      rating,
      comment,
      proUserPublicId,
    })
  }
}

function mapJobRatingStatus(status) {
  const protoStatus = GetJobRatingStatusResponse.Status
  switch (status) {
    case protoStatus.PENDING:
      return 'PENDING'
    case protoStatus.NOT_READY:
      return 'NOT_READY'
    case protoStatus.RATED:
      return 'RATED'
    case protoStatus.DISABLED:
      return 'DISABLED'
    case protoStatus.NOT_APPLICABLE:
      return 'NOT_APPLICABLE'
    default:
      return 'UNKNOWN'
  }
}

function mapToJobRatingStatus(jobRatingStatusDto) {
  return {
    proRating: mapJobRatingStatus(jobRatingStatusDto.proRating),
    platformRating: mapJobRatingStatus(jobRatingStatusDto.platformRating),
  }
}
