import { Model, referenceMany } from 'libx'
import { computed, observable, action } from 'mobx'
import { markdownToHtml } from 'utils/markdown'
import { maybeParseDate } from 'utils/dateUtil'
import { SupportingItemInputTypeDiscriminatorDTO } from '../../store/InfoGatheringStore'

/**
 * A requested supporting item.
 */
export class RequestedSupportingItem extends Model {
  @observable id = ''
  @observable name = ''
  @observable heading = ''
  @observable shortDescription = ''
  @observable longDescription = ''
  @observable imageUrl = ''
  @observable previewUrl = ''
  @observable required = false
  @observable adHoc = false

  @observable input

  constructor(attrs, opts) {
    super(attrs, opts)
    this.section = opts.section
  }

  @computed
  get completed() {
    return Boolean(this.input?.completed)
  }

  @computed
  get longDescriptionHtml() {
    if (this.longDescription) {
      return markdownToHtml(this.longDescription)
    }

    return ''
  }

  parse(json) {
    return {
      id: json.id,
      name: json.name,
      heading: json.heading,
      shortDescription: json.short_description,
      longDescription: json.long_description,
      imageUrl: json.image_url?.value,
      previewUrl: json.preview_url?.value,
      required: json.required,
      dateModified: maybeParseDate(json.update_time),
      input: parseSupportingItemInput(json.input, {
        rootStore: this.rootStore,
      }),
      adHoc: json.ad_hoc,
    }
  }

  static fromJSON(json, opts) {
    return new RequestedSupportingItem(json, {
      ...opts,
      parse: true,
      stripUndefined: true,
    })
  }
}
/**
 * Parses a supporting item input.
 */
function parseSupportingItemInput(json, opts) {
  if (!json) {
    return undefined
  }

  switch (json.type) {
    case SupportingItemInputTypeDiscriminatorDTO.DOCUMENT:
      return new DocumentSupportingItemInput(json, {
        ...opts,
        parse: true,
        stripUndefined: true,
      })
    case SupportingItemInputTypeDiscriminatorDTO.PERSONAL_INFO:
      return new PersonalInfoSupportingItemInput(json, {
        parse: true,
        stripUndefined: true,
      })
    case SupportingItemInputTypeDiscriminatorDTO.SPOUSAL_INFO:
      return new SpousalInfoSupportingItemInput(json, {
        parse: true,
        stripUndefined: true,
      })
    case SupportingItemInputTypeDiscriminatorDTO.DEPENDENTS_INFO:
      return new DependentsInfoSupportingItemInput(json, {
        parse: true,
        stripUndefined: true,
      })
    case SupportingItemInputTypeDiscriminatorDTO.PERSONAL_REFUND_METHOD_INFO:
      return new PersonalRefundMethodSupportingItemInput(json, {
        parse: true,
        stripUndefined: true,
      })
    case SupportingItemInputTypeDiscriminatorDTO.BUSINESS_INFO:
      return new BusinessInfoSupportingItemInput(json, {
        parse: true,
        stripUndefined: true,
      })
    case SupportingItemInputTypeDiscriminatorDTO.BUSINESS_REFUND_METHOD_INFO:
      return new BusinessRefundMethodSupportingItemInput(json, {
        parse: true,
        stripUndefined: true,
      })
    case SupportingItemInputTypeDiscriminatorDTO.NOTES:
      return new NotesSupportingItemInput(json, {
        parse: true,
        stripUndefined: true,
      })
  }

  console.error('Unknown supporting item input', json)
  return undefined
}

/**
 * Supporting item input for a document type.
 */
export class DocumentSupportingItemInput extends Model {
  @observable documentTypeShortId = ''
  @observable documentIds = []

  @computed
  get documents() {
    return referenceMany(
      this.rootStore.documentStore.documents,
      this.documentIds,
      'id'
    ).filter((x) => !x.archived)
  }

  @computed
  get completed() {
    return this.documentIds.length > 0
  }

  parse(json) {
    return {
      documentTypeShortId: json.document_type_short_id,
      documentIds: json.document_ids,
    }
  }

  @action.bound
  addDocument(docId) {
    if (this.documentIds.includes(docId)) {
      return
    }
    this.documentIds.push(docId)
  }

  @action.bound
  removeDocument(docId) {
    this.documentIds.remove(docId)
  }
}

export class PersonalInfoSupportingItemInput extends Model {
  @observable
  profileId = ''

  @observable
  profileVersion = 0

  parse(json) {
    return {
      profileId: json.profile_id,
      profileVersion: json.profile_version,
    }
  }
}

export class SpousalInfoSupportingItemInput extends Model {
  @observable
  profileId = ''

  @observable
  profileVersion = 0

  parse(json) {
    return {
      profileId: json.profile_id,
      profileVersion: json.profile_version,
    }
  }
}

export class DependentsInfoSupportingItemInput extends Model {
  @observable
  dependents = []

  parse(json) {
    return {
      dependents: json.dependents.map((dependent) => ({
        profileId: dependent.profile_id,
        profileVersion: dependent.profile_version,
      })),
    }
  }
}

export class PersonalRefundMethodSupportingItemInput extends Model {
  @observable
  refundMethodId = ''

  @observable
  refundMethodVersion = 0

  parse(json) {
    return {
      refundMethodId: json.refund_method_id,
      refundMethodVersion: json.refund_method_version,
    }
  }
}

export class BusinessInfoSupportingItemInput extends Model {
  @observable
  profileId = ''

  @observable
  profileVersion = 0

  parse(json) {
    return {
      profileId: json.profile_id,
      profileVersion: json.profile_version,
    }
  }
}

export class BusinessRefundMethodSupportingItemInput extends Model {
  @observable
  refundMethodId = ''

  @observable
  refundMethodVersion = 0

  parse(json) {
    return {
      refundMethodId: json.refund_method_id,
      refundMethodVersion: json.refund_method_version,
    }
  }
}

export class NotesSupportingItemInput extends Model {
  @observable
  text = ''

  parse(json) {
    return {
      text: json.text,
    }
  }
}
