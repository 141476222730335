/**
 * Adapter for document-related stuff needed for info gathering.
 */
export class DocumentsAdapter {
  constructor({
    getCurrentJob,
    documentStore,
    documentTypeStore,
    documentVersionStore,
    documentAccessStore,
    infoGatheringStore,
  }) {
    this.getCurrentJob = getCurrentJob
    this.documentStore = documentStore
    this.documentTypeStore = documentTypeStore
    this.documentVersionStore = documentVersionStore
    this.documentAccessStore = documentAccessStore
    this.infoGatheringStore = infoGatheringStore
  }

  /**
   * Fetches the specified documents.
   *
   * @param documentIds
   * @returns {Promise<void>}
   */
  async fetchDocuments(documentIds) {
    await Promise.all(
      documentIds.map((id) => this.documentStore.fetchDocument(id))
    )
  }

  /**
   * Uploads the file as a document to the associated job.
   * Returns an object containing 2 promises:
   *  - `created`: resolves when the document has been created and linked to the job but before it starts uploading.
   *      Throws an error if the file size is exceeded.
   *  - `provided`: resolves when the document has been uploaded and provided
   *      as an item to the checklist.
   *
   * @param {RequestedSupportingItem} supportingItem
   * @param {File} file
   * @returns {{ created: Promise<Document>; provided: Promise<Document> }}
   */
  provideDocument(supportingItem, file) {
    const job = this.getCurrentJob()
    const created = Promise.resolve().then(async () => {
      const fileSizeLimit = 314_572_800 // 300MB
      if (file.size > fileSizeLimit) {
        throw new Error('File must not be larger than 300mb.')
      }

      // Get the document type by short ID so that we can
      // retrieve it's long ID. Ideally we'd get rid of the long ID
      // as the short IDs need to be unique anyway.
      const documentType = await this.documentTypeStore.fetchByShortId(
        supportingItem.input.documentTypeShortId
      )

      return this.documentStore.createDocument({
        title: file.name,
        workspace_id: job.workspaceId,
        document_type_id: documentType.id,
        access: {
          type: 'Job',
          job_id: job.id,
        },
      })
    })

    const provided = created.then(async (doc) => {
      try {
        // Upload the document file.
        await this.documentVersionStore.createVersion({
          message: 'Initial Version',
          document_id: doc.id,
          action: {
            type: 'ReplaceContents',
            files: [file],
          },
        })

        // Provide it to the supporting item.
        // This is also done automatically by the backend but we'll do it
        // here as well since once this resolves we know it has been done.
        await this.infoGatheringStore.provideDocument(
          job.id,
          doc.id,
          supportingItem
        )

        return doc
      } catch (err) {
        // When doing anything with the created document fails, we archive it
        // so it does not appear in the user's documents section.
        console.error('Uploading/providing document failed; archiving it.', err)
        await this.documentStore.archiveDocument(doc.id).catch((err) => {
          console.error('Archiving document failed. Today is not our day.', err)
        })
        throw err
      }
    })

    return {
      created,
      provided,
    }
  }

  /**
   * Links the specified document to the job (if not already linked) and
   * provides it as document for the given supporting item.
   *
   * @param {RequestedSupportingItem} supportingItem
   * @param {Document} document
   * @returns {Promise<Document>}
   */
  async linkDocument(supportingItem, document) {
    const job = this.getCurrentJob()

    // If the document does not have a type, assign it based on the
    // supporting item it is being linked to.
    if (!document.documentType) {
      await this.documentStore.assignType(
        document.id,
        supportingItem.input.documentTypeShortId
      )
    }

    let createdAccess
    try {
      const alreadyLinkedToJob = document.accesses.some(
        (a) => a.jobId === job.id
      )

      if (!alreadyLinkedToJob) {
        createdAccess = await this.documentAccessStore.createAccess(
          document.id,
          {
            access: {
              type: 'Job',
              job_id: job.id,
            },
          }
        )
      }

      // Provide it to the supporting item.
      await this.infoGatheringStore.provideDocument(
        job.id,
        document.id,
        supportingItem
      )

      return document
    } catch (err) {
      // If providing the document failed, if we created the access, we'll
      // delete it now.
      console.error('Linking/providing document failed.', err)
      if (createdAccess) {
        await this.documentAccessStore.deleteAccess(createdAccess.id)
      }
      throw err
    }
  }

  /**
   * Removes the provided document from the supporting item.
   *
   * @param supportingItem
   * @param document
   */
  async removeProvidedDocument(supportingItem, document) {
    // If there is more than 1 access on the document, then it's linked
    // to more jobs, so we simply remove the access. Otherwise we archive the
    // document.
    const job = this.getCurrentJob()
    if (document.accesses.length > 1) {
      const access = document.accesses.find((a) => a.jobId === job.id)
      if (access) {
        await this.documentAccessStore.deleteAccess(access.id)
      }
    } else {
      await this.documentStore.archiveDocument(document.id)
    }

    // Remove the document from the checklist.
    // This is also done automatically by the backend but we'll do it
    // here as well since once this resolves we know it has been done.
    await this.infoGatheringStore.removeDocument(document.id, supportingItem)
  }
}
