import { computed, action, observable, runInAction } from 'mobx'
import { task } from 'mobx-task'
import { browserHistory } from 'react-router'
import link from '../../../misc/links'
import QS from 'qs'

export default class RoomViewModel {
  @observable
  room = undefined

  constructor({ projectDetailsStore, roomStore }) {
    this.projectDetailsStore = projectDetailsStore
    this.roomStore = roomStore
    this.setRoom(undefined)
  }

  @computed
  get participants() {
    return this.room?.activeParticipants.items
  }

  /**
   * Sets the Room.
   */
  @action.bound
  setRoom(room) {
    this.room = room
  }

  join = task.resolved(async () => {
    if (!this.projectDetailsStore.project || !this.room) {
      return
    }

    const qs = QS.parse(location.search, { ignoreQueryPrefix: true })
    runInAction(() => {
      browserHistory.push(
        link.projectRoom(
          this.projectDetailsStore.project.id,
          this.room.id,
          qs?.hideNavbar
        )
      )
    })
  })
}
