import once from 'lodash/once'

export default function makeTrackReferrerMeta({ authStore }) {
  return once(function onEnter(p) {
    const query = p.location.query

    // need to remove code and state for auth0 callback
    const { ref, show, redirect_url, code, state, ...rest } = query
    if (ref) {
      rest.referrer = ref
    }

    if (redirect_url) {
      window.localStorage.setItem('redirectUrl', redirect_url)
    }

    authStore.setReferrerMeta(rest)
  })
}
