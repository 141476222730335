import { computed, action, observable } from 'mobx'
import { task } from 'mobx-task'
import couponValidation from '../../../../../../utils/couponValidationUtil'

/**
 * State for the coupon picker.
 */
export default class CouponPickerState {
  @observable
  selectedCoupon = null

  @observable
  isOpen = false

  @observable
  newCode = ''

  constructor(userCouponStore) {
    this.userCouponStore = userCouponStore
  }

  @computed
  get availableCoupons() {
    return this.userCouponStore
      .forUserByPublicId()
      .filter((x) => x.redeemed === false)
      .map((x) => x.coupon)
  }

  async initialize(couponId) {
    this.closePicker()
    this.applyNewCode.reset()
    this.setCode('')

    await this.userCouponStore.fetchUserCouponsByPublicId().then(
      action(() => {
        if (couponId) {
          this.selectedCoupon = this.availableCoupons.find(
            (x) => x.id === couponId
          )
        }
      })
    )
  }

  @action.bound
  openPicker() {
    this.isOpen = true
  }

  @action.bound
  closePicker() {
    this.isOpen = false
    this.setCode('')
    this.applyNewCode.reset()
  }

  @action.bound
  setCode(value) {
    this.newCode = value
  }

  @action.bound
  selectCoupon(coupon) {
    this.selectedCoupon = coupon
    this.closePicker()
  }

  @action.bound
  removeCoupon() {
    this.selectedCoupon = null
    this.closePicker()
  }

  @task.resolved
  async applyNewCode() {
    couponValidation(this.newCode)

    const result = await this.userCouponStore.addCouponToUser(this.newCode)
    if (!result) {
      throw new Error('That coupon does not exist.')
    }

    this.selectCoupon(result.coupon)
    this.setCode('')
  }
}
