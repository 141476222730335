import { APIClient } from '@taxfyle/web-commons/lib/misc/APIClient'

export default class FreshbooksAPI extends APIClient {
  /** Returns the token upon successful retrieval. */
  getToken() {
    return this.client
      .get('/hubspot-tracking')
      .then((res) => res.data)
      .catch((err) => console.error('Error fetching hubspot token', err))
  }
}
