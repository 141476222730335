import { observable, action, computed, runInAction } from 'mobx'
import { extractMessageFromError } from 'utils/errorUtil'

export default class TermsDialogViewModel {
  @observable
  showing = false

  @observable
  termsContent = ''

  asyncModalResolve = null

  @observable
  optional = true

  constructor({ rootStore }) {
    this.rootStore = rootStore
  }

  async activate(optional = true) {
    const workspaceId = this.customTermsOfUseEnabled
      ? this.rootStore.sessionStore.workspace.id
      : null
    const termsContent = await this.rootStore.termsOfUseStore.getLatestTerms(
      workspaceId
    )
    runInAction(() => {
      this.optional = optional
      this.termsContent = termsContent.markup
    })
  }

  @computed
  get customTermsOfUseEnabled() {
    return this.rootStore.sessionStore.workspace.features.tos.customTermsEnabled
  }

  @action
  decline() {
    this.showing = false
    this.asyncModalResolve(false)
  }

  @action
  ensureAccepted() {
    this.showing = true
    return new Promise((resolve) => {
      if (this.asyncModalResolve) {
        // Precaution for potentially never ending promise
        this.asyncModalResolve(false)
      }
      this.asyncModalResolve = resolve
    })
  }

  @action
  accept() {
    // If custom terms is not enabled for the workspace accept global terms.
    const workspaceId = this.customTermsOfUseEnabled
      ? this.rootStore.sessionStore.workspace.id
      : null

    this.rootStore.memberStore
      .acceptLatestTermsOfUse(workspaceId)
      .then(() => {
        runInAction(() => {
          this.showing = false
        })
        this.asyncModalResolve(true)
      })
      .catch((err) => {
        this.rootStore.flashMessageStore.create({
          message: `An error occurred while attempting to accept the terms: ${extractMessageFromError(
            err
          )}`,
          type: 'error',
        })

        this.asyncModalResolve(false)
      })
  }
}
