import { action, observable } from 'mobx'

export default class SelectScheduleDialogViewModel {
  @observable
  showing = false

  constructor({ scheduleStore, confirmScheduleDialog }) {
    this.scheduleStore = scheduleStore
    this.confirmScheduleDialog = confirmScheduleDialog
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  close() {
    this.showing = false
  }

  @action.bound
  submit() {
    this.close()

    this.confirmScheduleDialog.show(true)
  }
}
