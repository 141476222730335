import { Store } from 'libx'
import { observable, action, computed } from 'mobx'
import { task } from 'mobx-task'
import memoize from 'memoizee'
import { validationContext, required } from 'validx'

export default class UpdateJobDialogStore extends Store {
  @observable showing = false
  @observable error = ''
  @observable reason = ''
  @observable filingYears = []
  @observable states = []
  @observable availableStates = []
  @observable job

  validation = validationContext()

  @action.bound
  init(job) {
    this.validation.reset()
    this.reason = ''
    this.job = job
    this.filingYears = this.job.years.slice()
    this.states = this.job.states.slice()
    this.save = this.save.bind(this)
    this.fetchStates = this.fetchStates.wrap((fn) =>
      memoize(fn.bind(this), { promise: true, length: 0 })
    )
    this.fetchQuestions = this.fetchQuestions.bind(this)
    this.fetchStates()
    this.fetchQuestions()
  }

  @computed
  get questions() {
    return this.rootStore.questionStore.questionsForJob(this.job.id)
  }

  @computed
  get answers() {
    return this.questions.filter((q) => q.isAnswered)
  }

  @computed
  get hasBreaker() {
    return this.questions.some((q) => q.isBreaker)
  }

  @task
  fetchStates() {
    return this.rootStore.api.legends.getStates().then(
      action((states) => {
        this.availableStates = states.map((x) => ({
          label: x.name,
          value: x.name,
        }))
      })
    )
  }

  @task
  async fetchQuestions() {
    await this.rootStore.questionStore.fetchQuestions(
      this.job.id,
      this.questions
    )
  }

  @action.bound
  show() {
    this.showing = true
  }

  @action.bound
  hide() {
    this.showing = false
  }

  @action.bound
  selectYears(years) {
    this.filingYears = years
  }

  @action.bound
  selectStates(states) {
    this.states = states
  }

  @action.bound
  setAnswers(answers) {
    for (const answer of answers) {
      const question = this.questions.find((q) => q.id === answer.id)
      question.setAnswer(answer.answer)
    }
  }

  @task.resolved
  async save() {
    const unanswered = this.questions.filter((q) => !q.isAnswered)

    const rules = {
      answers: [
        () =>
          unanswered.length === 0 ||
          unanswered.every((q) => q.isDocument) ||
          'Please answer all questions.',
      ],
      reason: [required('Please specify a reason for this update.')],
    }
    if (!this.validation.reset().validate(this, rules).isValid) {
      return
    }

    await this.rootStore.jobStore.makeCorrections(
      this.job.id,
      this.answers,
      this.reason
    )

    this.hide()
  }
}
