import { translate as T } from '@taxfyle/web-commons/lib/utils/translate'
import memoize from 'memoizee'
import { action, computed, observable, runInAction, when } from 'mobx'
import { task } from 'mobx-task'

export default class ProjectTypeState {
  stepId = 'ProjectType'
  title = T('Web.Onboarding.LegendSelect.Header', 'Hi, how can we help you?')
  trackingId = 'Onboarding - Project Selection'

  @observable legendId = ''
  @observable legendVersion = 0
  @observable.ref legend = null

  @observable relevant = true

  constructor({ wizard }) {
    this.wizard = wizard
    this.rootStore = wizard.rootStore
    this.activate = this.activate.wrap((fn) =>
      memoize(fn, { promise: true, length: 0 })
    )
    this.rootStore.sessionStore.onWorkspaceSelected(() => this.activate.clear())
  }

  @computed
  get hideBackButton() {
    const teamSelectStep = this.wizard.getStep('TeamSelect')
    return !teamSelectStep || !teamSelectStep.relevant
  }

  @computed
  get filteredLegends() {
    const workspaceId = this.rootStore.sessionStore.workspace.id
    const teamStep = this.wizard.getStep('TeamSelect')
    if (!teamStep || !teamStep.relevant || !teamStep.team) {
      return this.legends.filter(
        (x) => !x.team_id && x.workspace_id === workspaceId
      )
    }

    return this.legends.filter((l) => {
      return (
        (!l.team_id ||
          l.team_id === teamStep.team.id ||
          l.team_id === teamStep.team.slug) &&
        l.workspace_id === workspaceId
      )
    })
  }

  @computed
  get legends() {
    return this.wizard.legends
  }

  /**
   * This is used only when we are preselecting a legend
   * @param {legend} preselectedLegend
   */
  @action
  async preselectLegend(preselectedLegend) {
    this.legend = preselectedLegend
    this.relevant = false

    // When the user has gone past this step, then the preselection period is over
    await when(
      () =>
        this.wizard.steps.indexOf(this.wizard.currentStep) >
        this.wizard.steps.indexOf(this.wizard.getStep('ProjectType')),
      () => (this.relevant = true)
    )
  }

  @task
  async activate() {
    await when(() => this.rootStore.sessionStore.workspace)
  }

  @task.resolved
  selectType(type) {
    const legend =
      typeof type === 'string' ? this.legends.find((x) => x.id === type) : type

    if (legend) {
      if (!this.wizard.nameUpdatedManually) {
        this.wizard.setJobName(null)
      }
      runInAction(() => {
        this.legend = legend
        this.wizard.next()
      })
    }
  }

  serializeToJob() {
    if (this.legend) {
      return {
        legend_id: this.legend.id,
        legend_version: this.legend.version,
      }
    }
    return {}
  }

  async hydrate(job) {
    if (job.legendId) {
      this.legendId = job.legendId
      this.legendVersion = job.legendVersion
    }
  }
}
