import React from 'react'
import { FlexContainer, FlexItem } from 'components/Flex'

export default function FlexCenter({ children, ...props }) {
  return (
    <FlexContainer direction="column" {...props}>
      <FlexItem>{children}</FlexItem>
    </FlexContainer>
  )
}
